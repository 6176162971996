import { t } from "i18next";
import { Spinner } from "react-bootstrap";
type LoaderProps = {
    fullPage?:boolean;
  }

const ButtonLoader: React.FC<LoaderProps> = ({ fullPage }) => {
    return (
      <div className={`d-flex justify-content-center align-items-center ${fullPage ? "vh-100" : "py-2"}`}>
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
          variant="primary"
        />
       
        {t("loadTemplate.loading")}
      </div>
    );
  }
  export default ButtonLoader;