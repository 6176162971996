import React, { useImperativeHandle, forwardRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { TextField, Box, FormControl, Chip } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { Step1FormData } from '../../entities/models/catalogue.model';

interface Step1FormProps {
  initialData?: Step1FormData;
  systemTiers: string[]; // Add this prop
  setSystemTiers: (tiers: string[]) => void; 
}

export interface Step1FormRef {
  getFormData: () => Step1FormData;
  submitForm: () => void;
}

const systemTiersOptions = ['PRD', 'QAS', 'DEV', 'Non-Prod'];

const Step1Form = forwardRef<Step1FormRef, Step1FormProps>(({ initialData, systemTiers, setSystemTiers }, ref) => {
  const { register, handleSubmit, formState: { errors }, reset, getValues, setValue } = useForm<Step1FormData>({
    defaultValues: initialData
  });

  useEffect(() => {
    if (initialData) {
      reset(initialData);
    }
  }, [initialData, reset]);

  useImperativeHandle(ref, () => ({
    getFormData: () => getValues(),
    submitForm: () => handleSubmit(() => {})() 
  }));

  return (
    <form>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField
          label="Service Name"
          {...register('serviceName', { required: 'Service Name is required' })}
          error={!!errors.serviceName}
          helperText={errors.serviceName?.message}
        />
        <TextField
          label="SLA Eligibility"
          {...register('slaEligibility', { required: 'SLA Eligibility is required' })}
          error={!!errors.slaEligibility}
          helperText={errors.slaEligibility?.message}
        />
        <FormControl fullWidth error={!!errors.systemTiers}>
          <Autocomplete
            multiple
            options={systemTiersOptions}
            getOptionLabel={(option) => option}
            onChange={(event, value) => {
              console.log("getting value of the selected Tiers: ", value);
              // setSelectedSystemTiers((prev) => Array.from(new Set([...prev, ...value])));
              setSystemTiers(value);
              setValue('systemTiers', value); // Update form state
            }}
            renderTags={(value: string[], getTagProps) =>
              value.map((option, index) => (
                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="System Tiers"
                error={!!errors.systemTiers}
                helperText={errors.systemTiers?.message}
              />
            )}
            value={systemTiers} // Use lifted state for value
          />
        </FormControl>
      </Box>
    </form>
  );
});

export default Step1Form;