import { toast } from 'react-toastify';


export class ToasterHelper {
    public static success(message: string) {
        toast.success(message)
    }

  public static error(message: React.ReactNode) {
        toast.error(message)
    }

  public static info(message: React.ReactNode) {
    toast.info(message)
  }
  public static warn(message: React.ReactNode) {
    toast.warn(message, {
      className: 'custom-toast-warn'
    })
  
  }
    
}
