// TableHeader.js
import React from "react";
import { t } from "i18next";

const TableHeader = () => {
  return (
    <thead
      className="dashboard-font-Size"
      style={{
        position: "sticky",
        top: "0",
        zIndex: "100",
      }}
    >
      <tr>
        <th
          className="dashboard-font-Size"
          style={{
            backgroundColor: "#E3E3E3",
            borderRadius: "10px 0 0 0",
            width: "50px",
          }}
        ></th>
        {/* Define each column header here */}
        {[
          "instance",
          "cpu",
          "ram",
          "storageType",
          "storage",
          // "storageCode",
          "os",
          "count",
        ].map((key) => (
          <th
            key={key}
            className="dashboard-font-Size"
            style={{ backgroundColor: "#E3E3E3", width: "50px" }}
          >
            {t(`pages.reports.dbServerSelection.${key}`)}
          </th>
        ))}
        <th
          className="dashboard-font-Size"
          style={{
            backgroundColor: "#E3E3E3",
            borderRadius: "0 10px 0 0",
            width: "20px",
          }}
        ></th>
      </tr>
    </thead>
  );
};

export default TableHeader;
