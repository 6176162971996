import { gql } from "@apollo/client";



// export const GETSERVERCATALOGUE = gql`
//   query GetServerCatalogueList {
//     getServerCatalogueList {
//     id
//     serviceName
//     slaEligibility
//     serverSizes {
//       id
//       size
//       database
//       slaEligibility
//       systemTiers {
//         name
//         dbSize
//         dbServer
//         appServer
//       }
//     }
//   }
//   }
// `;


export const GETSERVERCATALOGUE = gql`
  query GetServerCatalogueList($searchString: String, $page: Int, $pageSize: Int) {
  getServerCatalogueList(searchString: $searchString, page: $page, pageSize: $pageSize) {
    data {
      id
      serviceName
      slaEligibility
      serverSizes {
        id
        size
        database
        published
        systemTiers {
          name
          dbSize
          dbServer
          appServer
        }
      }
      createdAt
      updatedAt
    }
    page
    pageSize
    pagesCount
    totalRecords
    nextPage
  }
}
`;


export const CATALOGUE_DETAIL = gql`
query GetCatalogueDetail($catalogueId: String!) {
  getCatalogueDetail(catalogueId: $catalogueId) {
    id
    serviceName
    size
    database
    dbOsLicenseModel
    appOsLicenseModel
    tiers {
      id
      name
      dbSize
      dbServerSize
      dbServerCount
      appServer
      appServerCount
      storage
      AWS {
        databaseServer {
          appServerInstance
          dbServerInstance
          dbOS
          appOS
        }
        appServer {
          appServerInstance
          dbServerInstance
          dbOS
          appOS
        }
      }
      AZURE {
        databaseServer {
          appServerInstance
          dbServerInstance
          dbOS
          appOS
        }
        appServer {
          appServerInstance
          dbServerInstance
          dbOS
          appOS
        }
      }
      GCP {
        databaseServer {
          appServerInstance
          dbServerInstance
          dbOS
          appOS
        }
        appServer {
          appServerInstance
          dbServerInstance
          dbOS
          appOS
        }
      }
    }
    createdAt
    updatedAt
  }
}
`;


export const GETCATALOGUESIZES = gql`
  query GetCatalogueSizes($catalogueId: String) {
  getCatalogueSizes(catalogueId: $catalogueId) {
    systemTiers
    serverSizes {
      id
      usageTier
      size
      databaseType
    }
  }
}
`;
