import { Modal, Button } from "react-bootstrap";
import { useMutation } from '@apollo/client';
import { useQuery } from '@apollo/client';
// import { GET_SID } from "graphql/queries";
import { Col, Container, Form, Row } from "react-bootstrap";
import { MANUAL_INPUT } from 'graphql/mutations';
import { SizingReport } from "entities/models";
import {
  CustomButton,
  CustomInput,
  CustomSelect,
} from "components/ui-elements";
import { gql } from '@apollo/client';
import { useFormWithYup, useNavigation, useTranslate, Yup } from "hooks";
import { APP_ROUTES } from "router/routes";
import { UserHelper } from "utils";
import { toast } from "react-toastify";
import { SAPSCategory } from "entities/enums";
import { useState } from "react";
interface DeleteModalProps {
}
export default function DefaultConfig(

) {
  const t = useTranslate();
  const goto = useNavigation();
  const [uniqueSid, setUniqueSid] = useState('');
  const isDecimal = (fieldName: string) => {
    return Yup.number()
      .transform((value, originalValue) =>
        originalValue ? (Number.isNaN(value) ? -1 : value) : null
      )
      .positive(
        `${t(
          `pages.manualInput.message.validation.${fieldName}NumberOrDecimal`
        )}`
      );
  };
  const schema = Yup.object().shape({
    sid: Yup.string().required(
      `${t("pages.manualInput.message.validation.sid")}`
    ).min(3, `${t("pages.manualInput.message.validation.minimumLength")}`)
    .max(3, `${t("pages.manualInput.message.validation.maximumLength")}`),
    sapsCategory: Yup.string().required(
      `${t("pages.manualInput.message.validation.sapsCategory")}`
    ),
    memoryRequirement: isDecimal("memory").required(
      `${t(`pages.manualInput.message.validation.memoryRequired`)}`
    ),
    memoryRequirementAfterOptimization:
      isDecimal("withOptimization").nullable(),
    memoryRequirementForShadowInstance: isDecimal("shadowInstance").nullable(),
  });
  return (
    <>
    </>
  );
}