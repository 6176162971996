export enum PurchaseOptionAzure {
  PAY_AS_YOU_GO = "PAY_AS_YOU_GO",
  ONE_YEARS_SAVINGS_PLAN = "ONE_YEAR_SAVINGS_PLAN",
  THREE_YEARS_SAVINGS_PLAN = "THREE_YEAR_SAVINGS_PLAN",
  ONE_YEAR_RESERVED = "ONE_YEAR_RESERVED",
  THREE_YEARS_RESERVED = "THREE_YEAR_RESERVED",
  // PAY_AS_GO = "Pay as you go",
  // ONE_YEARS_SAVINGS_PLAN = "1 Years Savings Plan",
  // THREE_YEARS_SAVINGS_PLAN = "3 Years Savings Plan",
  // ONE_YEAR_RESERVED = "1 Year Reserved",
  // THREE_YEARS_RESERVED = "3 Years Reserved",
}

// Pay as you go (paymentOption = 'On Demand')
// 1 Years Savings Plan (paymentOption = 'Reservation' && Reservation Term = 1)
// 3 Years Savings Plan (paymentOption = 'Reservation' && Reservation Term = 3)
// 1 Year Reserved (paymentOption = 'Reservation' && Reservation Term = 1)
// 3 Years Reserved (paymentOption = 'Reservation' && Reservation Term = 3)