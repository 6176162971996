import React, { useEffect, useState } from "react";
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from "cdbreact";
import { NavLink } from "react-router-dom";
import pricingReportIcon from "../../../assets/images/Pricing.png";
import compare from "../../../assets/images/carbon_compare.png";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileSignature } from "@fortawesome/free-solid-svg-icons";
import { useTranslate } from "hooks";
import { SiSap } from "react-icons/si";
import { StorageHelper, StorageKeys, UserHelper } from "utils";
import { MdAdminPanelSettings } from "react-icons/md";


const Sidebar = () => {
  const t= useTranslate()
  const location = useLocation();
  const { id } = useParams();
  const IsHome = location.pathname === "/dashboard";
  const IsCompare = location.pathname === "/compare";
  const IsSapRise = location.pathname === "/sap_rise"
  // const IsLucid = location.pathname === "/lucid"
  const IsLoadTemplate = location.pathname === "/loadTemplate";
  const IsEdit = location.pathname === `/report/${id}`;
  const [expanded, setExpanded] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(()=> {
    const user = StorageHelper.get(StorageKeys.User);
    setIsAdmin(!!(user?.isAdmin) || false);
  }, [])

  // const user = StorageHelper.get(StorageKeys.User)
  // let adminStatus = !!(user?.isAdmin)
  // console.log(adminStatus, "adminStatus");
   
  return (
    <div
      className="sidebar-wrapper"
      style={{ position: "absolute" }}
      onMouseEnter={() => setExpanded(true)}
      onMouseLeave={() => setExpanded(false)}
    >
      <CDBSidebar
        textColor="#fff"
        backgroundColor="#333"
        className={""}
        breakpoint={0}
        toggled={false}
        minWidth={""}
        maxWidth={""}
      >
        <CDBSidebarContent
          className={`sidebar-content ${expanded ? "expanded" : ""}`}
        >
          <CDBSidebarMenu>
            <NavLink to="/dashboard">
              <CDBSidebarMenuItem 
                className={IsHome || IsEdit ? "activesidebar" : "sidebar-link"}
              >
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip-disabled" className="tooltip-allow">
                      {t("sidebar.pricing-report")}
                    </Tooltip>
                  }
                >
                  <img
                    src={pricingReportIcon}
                    alt="Pricing Report"
                    className="price-img"
                  />
                </OverlayTrigger>
                <span className="sidebar-size"> {t("sidebar.SAP")}</span>
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/compare">
              <CDBSidebarMenuItem
                className={IsCompare ? "activesidebar" : "sidebar-link"}
              >
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip-disabled" className="tooltip-allow">
                   {t("sidebar.compare")}
                    </Tooltip>
                  }
                >
                  <img
                    src={compare}
                    alt="Pricing Report"
                    className="compare-img"
                    
                  />
                </OverlayTrigger>
                <span className="sidebar-size"> {t("sidebar.compare")}</span>
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/sap_rise">
              <CDBSidebarMenuItem
                className={IsSapRise ? "activesidebar" : "sidebar-link"}
              >
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip-disabled" className="tooltip-allow">
                   {t("sidebar.SAP_RISE")}
                    </Tooltip>
                  }
                >
                  < SiSap className="sap-img" />
                </OverlayTrigger>
                <span className="sidebar-size"> {t("sidebar.SAP_RISE")}</span>
              </CDBSidebarMenuItem>
            </NavLink>
            {isAdmin ? (
              <NavLink to="/admin">
              <CDBSidebarMenuItem
                className={IsSapRise ? "activesidebar" : "sidebar-link"}
              >
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip-disabled" className="tooltip-allow">
                   Admin panel
                    </Tooltip>
                  }
                >
                  < MdAdminPanelSettings className="admin-icon" />
                </OverlayTrigger>
                <span className="sidebar-size">Admin panel</span>
              </CDBSidebarMenuItem>
            </NavLink>
            ):<></>}
            
            {/* <NavLink to="/lucid">
              <CDBSidebarMenuItem
                className={IsLucid ? "activesidebar" : "sidebar-link"}
              >
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip-disabled" className="tooltip-allow">
                      Lucid
                    </Tooltip>
                  }
                >
                  < SiSap className="sap-img" />
                </OverlayTrigger>
                <span className="sidebar-size">Lucid</span>
              </CDBSidebarMenuItem>
            </NavLink> */}
          </CDBSidebarMenu>
        </CDBSidebarContent>
      </CDBSidebar>
    </div>
  );
};

export default Sidebar;
