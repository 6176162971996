import { gql } from '@apollo/client';



export const CREATESERVERCATALOGUE = gql`
	mutation CreateServerCatalogue($input: ServerCatalogueInput!) {
    createServerCatalogue(input: $input) {
        id
        message
      }
    }
`;


export const DELETESERVERCATALOGUE = gql`
  mutation DeleteServerFromCatalogue($id: String!) {
    deleteServerFromCatalogue(id: $id)
  }
`


export const CREATESERVERINCATALOGUE = gql`
  mutation CreateServerCatalogue($input: ServerCatalogueInput!) {
  createServerCatalogue(input: $input) {
    id
    message
  }
}
`

export const CREATESERVERCATALOGUESIZE_STEP2 = gql`
  mutation CreateServerCatalogueSize($input: ServerCatalogueSizeInput!) {
    createServerCatalogueSize(input: $input) {
      id
      message
    }
}
`


export const ADD_SYSTEM_TIERS_TO_SIZE = gql`
  mutation AttachTiersToServerSize($input: SystemTierInput!) {
    attachTiersToServerSize(input: $input) {
      id
      message
    }
  }
`