export enum APP_ROUTES {
  // Public Routes
  HOME = '/',
  ABOUT = '/about',
  CONTACT = '/contact',
  LOGIN = '/login',
  REGISTER = '/register',
  MANUAL_INPUT = '/manual-input',
  TERM_CONDITION = '/term-condition',
  UI_ROUTE ="/ui-route",
  COMPARE_REPORT = "/compare-report/:id",
  COMPARE='/compare',

  // Private Routes
  DASHBOARD = '/dashboard',
  EDITREPORT = '/report/:id',


  LOADTEMPLATE='/loadTemplate',
  ADMINPANEL = '/admin',
  SERVER_CATALOGUE='/admin/server-catalogue',
  CATALOGUE_CREATE='/admin/catalogue-create',
  SAP_RISE='/sap_rise',
  // Lucid='/lucid',
  SAP_RISE_DETAIL = '/sap_rise/:id',
}
