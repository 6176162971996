import { useTranslate } from "hooks";
import { t } from "i18next";
import React from "react";
import { Button, ButtonProps, Spinner } from "react-bootstrap";

type CustomButtonProps = ButtonProps & {
    isLoading?: boolean;
};

const CustomButton: React.FC<CustomButtonProps> = ({ children, isLoading, ...props }) => {
    const t = useTranslate();
    return (
        <Button disabled={isLoading} {...props}>
            {isLoading ?
                <>
                    <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                     {t("loadTemplate.loading")}
                </>
                :
                children
            }
        </Button>
    );
};

export default CustomButton;