// import React from 'react';

// function Stepper({ steps, activeStep }: any) {
//   function getStepClass(step: number) {
//     if (activeStep === step) {
//       return 'step step-active';
//     } else if (activeStep > step) {
//       return 'step step-done';
//     } else {
//       return 'step step-inactive';
//     }
//   }

//   return (
//     <div className="steps-container">
//       {steps.map((label: string, index: number) => (
//         <div className="step-wrapper" key={index}>
//           <div className={getStepClass(index)} key={index}>
//             <div>
//               <div
//                 className="circle rounded-circle"
//                 style={{
//                   backgroundColor: activeStep === index ? '#4FC123' : '#B8B8B8',
//                   boxShadow: activeStep === index ? '0 0 0 10px #BCECA9' : '0 0 0 10px rgba(184, 184, 184, 0.2)',

//                 }}
//               >
//                 {index + 1}
//               </div>
//             </div>
//             <div className="label">{label}</div>
//             {index < steps.length - 1 && <div className="line"></div>}
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// }

// export default Stepper;

import React from 'react';
function Stepper({ steps, activeStep }: any) {
  function getStepClass(step: number) {
    if (activeStep === step) {
      return 'step step-active';
    } else if (activeStep > step) {
      return 'step step-done';
    } else {
      return 'step step-inactive';
    }
  }
  return (
    <div className="steps-container">
      {steps.map((label: string, index: number) => (
        <div className="step-wrapper" key={index}>
          <div className={getStepClass(index)} key={index}>
            <div>
              <div className="circle rounded-circle">
                {index < activeStep ? (
                  <span>&#10003;</span>
                ) : (
                  index + 1
                )}
              </div>
            </div>
            <div className="label">{label}</div>
            {index < steps.length - 1 && <div className="line"></div>}
          </div>
        </div>
      ))}
    </div>
  );
}
export default Stepper;
