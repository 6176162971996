
import React from "react";
import { CloudVendor } from "entities/enums";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";

type PropTypes = {
  value?: CloudVendor | null;
  onChange?: (value: CloudVendor) => void;
  label?: string | null;
  toolTipText?: string;
};

const vendorImages = {
  [CloudVendor.AWS]: "https://res.cloudinary.com/dstnwi5iq/image/upload/v1714718196/image_24_zoqvnt.png",
  [CloudVendor.GCP]: "https://res.cloudinary.com/dstnwi5iq/image/upload/v1714718035/image_23_360_xdnupi.png",
  [CloudVendor.AZURE]: "https://res.cloudinary.com/dstnwi5iq/image/upload/v1714718207/image_25_zvubzo.png",
};

const VendorSelect = ({ value, onChange, label, toolTipText }: PropTypes) => {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    onChange && onChange(e.target.value as CloudVendor);
  };

  return (
    <>
      <Form.Label className="fw-semibold">{label}</Form.Label>
      <div className="d-flex flex-row justify-content-start align-items-center">
        {[CloudVendor.AWS, CloudVendor.GCP, CloudVendor.AZURE].map((vendor) => (
          <div key={vendor} className=" me-3">
            <Form.Check
              inline
              type="radio"
              value={vendor}
              id={`vendor-${vendor}`}
              checked={value === vendor}
              onChange={handleChange}
              className={`${value === vendor ? "selected-vendor" : ""} visually-hidden`}
            />
            <label htmlFor={`vendor-${vendor}`} className="d-flex align-items-center " style={{fontWeight:"600",marginRight:"50px"}}>
              <Image src={vendorImages[vendor]} width="30" height="30" className="me-2 " />
              {vendor}
            </label>
          </div>
        ))}
      </div>
    </>
  );
};

export default VendorSelect;
