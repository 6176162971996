export enum GcpOperatingSystem {
  WINDOWS_SERVER_2008 = "Windows Server 2008",
  WINDOWS_SERVER_2012 = "Windows Server 2012",
  WINDOWS_SERVER_2016 = "Windows Server 2016",
  WINDOWS_SERVER_2019 = "Windows Server 2019",
  WINDOWS_SERVER_2022 = "Windows Server 2022",
  // LINUX_OS= 'CentOS, CoreOS, Debian, Ubuntu',
  // UBUNTU_PRO= 'Ubuntu Pro',
  RHEL = "RHEL",
  // RHEL_SAP_WITH_HA_UPDATE_SERVICES = 'RHEL for SAP With HA & Updates Services ',
  SLES = "SLES",
  SLES_12_SAP = "SLES 12 for SAP",
  SLES_15_SAP = "SLES 15 for SAP",
}
