// import React from "react";
// import { Form } from "react-bootstrap";

// type CustomCheckboxProps = {
//     controlId?: string;
//     formGroupClassName?: string;
//     label?: any;
//     errorMessage?: string | undefined;
//     hookForm?: any;
//     id: string;
//     isInvalid?: boolean;
//     defaultChecked?:boolean;
// };

// const CustomCheckbox: React.FC<CustomCheckboxProps> = ({ label, controlId, formGroupClassName, errorMessage, hookForm, id, isInvalid, defaultChecked }) => {
//     return (
//         <Form.Group className={formGroupClassName} controlId={controlId}>
//             <Form.Check type="checkbox">
//                 <Form.Check.Input id={id}  {...hookForm} defaultChecked={defaultChecked} />
//                 <Form.Check.Label>{label}</Form.Check.Label>
//                 <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
//             </Form.Check>
//         </Form.Group>
//     );
// };

// export default CustomCheckbox;

import React from "react";
import { Form } from "react-bootstrap";
type CustomCheckboxProps = {
  controlId?: string;
  formGroupClassName?: string;
  label?: any;
  errorMessage?: string | undefined;
  hookForm?: any;
  id: string;
  isInvalid?: boolean;
  defaultChecked?: boolean;
};

const CustomCheckbox = ({
  controlId,
  label,
  errorMessage,
  isInvalid,
  hookForm,
  formGroupClassName,
  id,
  defaultChecked,
}: CustomCheckboxProps) => {
  return (
    <Form.Group controlId={controlId} className={formGroupClassName}>
      <Form.Check type="checkbox">
        <Form.Check.Input
          id={id}
          {...hookForm}
          defaultChecked={defaultChecked}
        />
        <Form.Check.Label>{label}</Form.Check.Label>
        <Form.Control.Feedback type="invalid">
          {errorMessage}
        </Form.Control.Feedback>
      </Form.Check>
      {isInvalid && (
        <Form.Control.Feedback type="invalid" className="d-block">
          {errorMessage}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

export default CustomCheckbox;
