import { useState, useEffect } from "react";

export function useTimer(initialMinutes = 1, initialSeconds = 30) {
  const [minutes, setMinutes] = useState(initialMinutes);
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds, minutes]);

  function reset() {
    setMinutes(initialMinutes);
    setSeconds(initialSeconds);
  }

  return { minutes, seconds, reset };
}

export default useTimer;