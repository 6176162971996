import NoSSR from "react-no-ssr";
import { Typeahead } from "react-bootstrap-typeahead";
import Form from "react-bootstrap/Form";
// import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
// import { useListRegionsQuery } from '../features/regions/regionsSlice'
import { type Region } from "entities/models/report";
import { CloudVendor } from "entities/enums";
import { faAngleDown, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover } from "react-bootstrap";
import { t } from "i18next";
import ButtonLoader from "components/ui-elements/loader/buttonLoader";
import { useEffect, useState } from "react";
import { UserHelper } from "utils";
import * as React from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

type PropTypes = {
  id: string;
  vendor?: CloudVendor | null;
  disabled?: boolean;
  isInvalid?: boolean;
  value?: Region | null;
  onChange?: (value?: Region | null) => void;
  errorMessage?: any;
  option?: Region[] | null;
  toolTipText?: string;
  label?: string | null;
  className?: string;
  style?: React.CSSProperties;
  text?: string;
  placeholder?: string;
  onBlur?: any;
  setRegion?: React.Dispatch<React.SetStateAction<any>>;
};

/**
 * Cloud region input component for a specific vendor.
 */
const RegionSelect = ({
  id,
  vendor,
  disabled,
  isInvalid,
  value,
  onChange,
  errorMessage,
  option,
  toolTipText,
  label,
  className,
  placeholder,
  style,
  onBlur,
  setRegion,
  ...props
}: PropTypes) => {
  const [options, setOptions] = useState<Region[]>([]);
  const [loading, setLoading] = useState(false);
  
  setInterval(() => {
      const reportsData = UserHelper.getRegions();
      if (reportsData) {
         setLoading(true);
      }
  }, 1000);

  useEffect(() => {
    setOptions(option ?? []);
    if (option?.length) {
      setLoading(true);
    }
  }, [option]);


  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    // Handle onBlur event here
    if (onBlur) {
      onBlur(e);
    }
  };
  const handleSearch = (text: any) => {
    let sortedOptions = [...(option || [])];
    sortedOptions.sort((a, b) => {
      const aLocation = (a.location || "").toLowerCase();
      const bLocation = (b.location || "").toLowerCase();
      const searchText = text.toLowerCase();

      if (
        aLocation.startsWith(searchText) &&
        !bLocation.startsWith(searchText)
      ) {
        return -1;
      }
      if (
        bLocation.startsWith(searchText) &&
        !aLocation.startsWith(searchText)
      ) {
        return 1;
      }
      return 0;
    });
    setOptions(sortedOptions);
  };
  <br />;
  const typeahead = (
    <div className="region-select-container" style={style}>
      <Form.Label htmlFor={id} className="mx-2">
        <span>{label}</span>
      </Form.Label>
      <NoSSR>
        {loading ? (
          <div className="typeahead">
            <Typeahead
              {...props}
              id={id}
              className="region-select"
              selected={value ? [value] : []}
              onChange={onChange as any}
              onBlur={handleBlur}
              onInputChange={handleSearch}
              placeholder={t("loadTemplate.placeHolder") as string}
              labelKey="location"
              minLength={0}
              isInvalid={isInvalid}
              options={options ? options : option || []}
              filterBy={() => true}
              renderMenuItemChildren={(optionItems: any) => (
                <div className="region-options">
                  <div>{optionItems?.location}&nbsp;&nbsp;</div>
                  <div className="text-muted">{optionItems?.code}</div>
                </div>
              )}
            />
            <FontAwesomeIcon
              icon={faAngleDown}
              className="dropdown-indicator"
            />
          </div>
        ) : (
          <ButtonLoader />
        )}

        <Form.Control.Feedback type="invalid">
          {errorMessage}
        </Form.Control.Feedback>
      </NoSSR>
    </div>
  );

  return !vendor ? (
    // <OverlayTrigger
    //   placement="bottom"
    //   overlay={
    //     <Tooltip id="tooltip-disabled" className="custom-tooltip-rigion">
    //       {t("loadTemplate.tooltip")}
    //     </Tooltip>
    //   }
    // >
    //   <span>{typeahead}</span>
    // </OverlayTrigger>
    <Tooltip title={t("loadTemplate.tooltip")} followCursor>
      <span>{typeahead}</span>
    </Tooltip>
  ) : (
    typeahead
  );
};

export default RegionSelect;
