//https://medium.com/how-to-react/setup-multilingual-in-react-js-using-i18n-module-33b1bfbb57cd
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Importing translation files
import translationEN from "assets/translations/en.json";
import translationJA from "assets/translations/ja.json";
import translationDE from "assets/translations/de.json";
import { StorageHelper, StorageKeys } from "utils";

export const defaultLanguage = 'en';

//Creating object with the variables of imported translation files
const resources = {
  en: {
    translation: translationEN,
  },
  ja: {
    translation: translationJA,
  },
  de: {
    translation: translationDE,
  },
};

//i18N Initialization
i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: StorageHelper.get(StorageKeys.Language) || defaultLanguage,
    fallbackLng: defaultLanguage,
    // keySeparator: false, // this was the line that I've had to remove to make it work
    // keySeparator: '.', // if you want to re-enable it (not "true", but actual separator value)
    interpolation: {
      escapeValue: false,
    },

  });

export default i18n;