import { useNavigate } from "react-router-dom";

export function useNavigation() {
  const navigate = useNavigate();
  
  function goTo(path: string, options?: any) {
    if (options) {
      navigate(path, options);
    } else {
      navigate(path);
    }
  }

  return goTo;
}