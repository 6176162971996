import { Modal, Button } from 'react-bootstrap';
import CustomButton from '../../ui-elements/custom-button';
import { useTranslate } from 'hooks';

interface DeleteModalProps {
  title: string;
  handleDelete: (d:any) => void;
  showModal: boolean;
  closeModal: () => void;
  loading: boolean;
  
}

export default function DeleteModal({ title, handleDelete, showModal, closeModal, loading }: DeleteModalProps) {
const t = useTranslate()
  if (!showModal) {
    return <></>
  }

  return (
    <>
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t("delete-modals.delete")} {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t("delete-modals.para")} {title}? {t("delete-modals.deleted")}
            {title} {t("delete-modals.cant-recover")}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={closeModal}>
          {t("delete-modals.no")}
          </Button>
          <CustomButton
            isLoading={loading}
            variant="outline-danger"
            onClick={handleDelete}
          >
            {t("delete-modals.delete")}
          </CustomButton>
        </Modal.Footer>
      </Modal>
    </>
  );
}
