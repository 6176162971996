import { useTranslate } from 'hooks';
const HowItWork = () => {
const t=useTranslate()
  return (
    <>
                    <div style={{overflow:'hidden'}}>
                        <div className='how-it-work'>
                            <div className='justify-Cont directionn'>

                                <h3 className='cont-heading'>
                                {t('pages.home.howitwork')}
                                </h3>
                                <p className='content-paraa FontFamily'>
                               {t('pages.home.howitworkdescrition')}
                                </p>
                            </div>
                             
                        </div>
                    </div>
                    
    </>
  );
};

export default HowItWork;
