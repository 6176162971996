
  
  export enum OperatingSystem {
    LINUX = 'Linux',
    WINDOWS = 'Windows',
    RHEL = 'RHEL',
    RHELHA = 'Red Hat Enterprise Linux with HA',
    SUSE = 'SUSE Linux Enterprise Server',
    // Ubuntu_Pro = 'Ubuntu Pro'
 }
