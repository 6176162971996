import { useTranslate } from "hooks";
import { t } from "i18next";
import { Spinner } from "react-bootstrap";

type LoaderProps = {
  fullPage?:boolean;
}


const Loader: React.FC<LoaderProps> = ({ fullPage }) => {
  const t = useTranslate();
  return (
    <div className={`d-flex justify-content-center align-items-center ${fullPage ? "vh-100" : "py-5"}`}>
      <Spinner
        as="span"
        animation="grow"
        size="sm"
        role="status"
        aria-hidden="true"
        variant="primary"
      />
      {t("loadTemplate.loading")}
    </div>
  );
}

export default Loader;