import React, { useState, useCallback, useEffect } from "react";
import { Container, Row, Col, Form, Table, Modal, Pagination } from "react-bootstrap";
import { CustomInput, CustomButton } from "components";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { GETSERVERCATALOGUE, CATALOGUE_DETAIL } from "../../graphql/queries/server-catalogue";
import {CREATESERVERCATALOGUE, DELETESERVERCATALOGUE} from "../../graphql/mutations/server-catalogue.mutations"
import { FaEdit, FaTrash, FaUpload, FaCheck } from "react-icons/fa"; // Add FaUpload and FaCheck
import { useFormWithYup, Yup } from "hooks"; // Assuming you have these hooks
import { ToasterHelper } from "utils/toaster.helper"; // Assuming you have this utility
import { debounce } from 'lodash'; // Make sure to install lodash if not already present
import { useNavigate } from 'react-router-dom';

type ServerCatalogue = {
  serviceName: string;
  size: string;
  database: string
}


export default function ServerCataloguePage() {
  const [searchString, setSearchString] = useState("");
  const [debouncedSearchString, setDebouncedSearchString] = useState("");
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const [catalogueList, setCatalogueList] = useState([]);

  const { loading, error, data, refetch } = useQuery(GETSERVERCATALOGUE, {
    variables: { searchString: debouncedSearchString, page, pageSize },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchString(searchString);
    }, 300);

    return () => clearTimeout(timer);
  }, [searchString]);

  useEffect(() => {
    refetch({ searchString: debouncedSearchString, page, pageSize });
  }, [debouncedSearchString, page, refetch]);

  useEffect(() => {
    if (data?.getServerCatalogueList?.data) {
      setCatalogueList(data.getServerCatalogueList.data);
    }
  }, [data]);

  const [getCatalogueDetail] = useLazyQuery(CATALOGUE_DETAIL);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState<number | null>(null);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [publishItemId, setPublishItemId] = useState<number | null>(null);
  const [publishedItems, setPublishedItems] = useState<number[]>([]);

  const [deleteServerCatalogue] = useMutation(DELETESERVERCATALOGUE, {
    onCompleted: async () => {
      ToasterHelper.success("Server deleted successfully");
      refetch(); // Just call refetch without awaiting
    },
    onError: async (error) => {
      console.log(error);
      ToasterHelper.error("error while deleteing server");
    },
  });

  // Debounce the search query
  const debouncedSearch = useCallback(
    debounce((value: string) => {
      setDebouncedSearchString(value);
      setPage(1);
    }, 300),
    []
  );

  const schema = Yup.object().shape({
    serviceName: Yup.string().required("Service Name is required"),
    size: Yup.string().required("Size is required"),
    database: Yup.string().required("Database is required"),
  });


  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
    setPage(1); // Reset to first page on new search
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleEdit = (id: number) => {
    // Implement edit logic here
    console.log(`Editing catalogue with ID: ${id}`);
  };

  const handleDelete = (id: number) => {
    setDeleteItemId(id);
    setShowDeleteModal(true);
  };

  const confirmDelete = async() => {
    if (deleteItemId) {
      // Implement actual delete logic here
      const response = await deleteServerCatalogue({
        variables: { id: deleteItemId },
      });
      await refetch();
      console.log(`Deleting catalogue with ID: ${deleteItemId}`);
    }
    setShowDeleteModal(false);
    setDeleteItemId(null);
  };

  const handlePublishUnpublish = (id: number) => {
    setPublishItemId(id);
    setShowPublishModal(true);
  };

  const confirmPublishUnpublish = () => {
    if (publishItemId) {
      if (publishedItems.includes(publishItemId)) {
        // Unpublish logic
        console.log(`Unpublishing catalogue with ID: ${publishItemId}`);
        setPublishedItems(publishedItems.filter(id => id !== publishItemId));
      } else {
        // Publish logic
        console.log(`Publishing catalogue with ID: ${publishItemId}`);
        setPublishedItems([...publishedItems, publishItemId]);
      }
    }
    setShowPublishModal(false);
    setPublishItemId(null);
  };

  const handleServiceNameClick = async (id: string) => {
    try {
      const { data } = await getCatalogueDetail({ variables: { catalogueId: id } });
      // Handle the catalogue detail data here
      console.log("Catalogue detail:", data.getCatalogueDetail);
      // You can open a modal or navigate to a detail page here
    } catch (error) {
      console.error("Error fetching catalogue detail:", error);
    }
  };

  const navigate = useNavigate();

  const handleCreateNew = () => {
    navigate('/admin/catalogue-create'); // Adjust the path as needed
  };

  return (
    <Container className="py-5">
      <h2>Server Catalogue</h2>
      <Row className="mb-3">
        <Col md={8}>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Search by service name"
              value={searchString}
              onChange={handleSearch}
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <CustomButton variant="primary" onClick={handleCreateNew}>
            Create New Servers
          </CustomButton>
        </Col>
      </Row>

      <Row>
        <Col md={8}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Service Name</th>
                 <th>Sizes available</th>
                {/* <th>Database</th> */}
                <th>Actions</th> {/* Add this new header */}
              </tr>
            </thead>
            <tbody>
              {catalogueList.map((item: any) => (
                <tr key={item.id}>
                  <td>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleServiceNameClick(item.id);
                      }}
                      style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    >
                      {item.serviceName}
                    </a>
                  </td>
                  <td>{item.serverSizes.length}</td>
                  {/* <td>{item.database}</td>  */}
                  <td>
                    <CustomButton variant="link" onClick={() => handleEdit(item.id)}>
                      <FaEdit />
                    </CustomButton>
                    <CustomButton variant="link" onClick={() => handleDelete(item.id)}>
                      <FaTrash />
                    </CustomButton>
                    <CustomButton variant="link" onClick={() => handlePublishUnpublish(item.id)}>
                      {publishedItems.includes(item.id) ? <FaCheck /> : <FaUpload />}
                    </CustomButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      
      <Row className="mt-3">
        <Col md={8}>
          <Pagination>
            <Pagination.Prev
              onClick={() => handlePageChange(page - 1)}
              disabled={page === 1}
            />
            <Pagination.Item active>{page}</Pagination.Item>
            <Pagination.Next
              onClick={() => handlePageChange(page + 1)}
              disabled={!data?.getServerCatalogueList.nextPage}
            />
          </Pagination>
        </Col>
      </Row>
      
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
        <Modal.Footer>
          <CustomButton variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </CustomButton>
          <CustomButton variant="danger" onClick={confirmDelete}>
            Delete
          </CustomButton>
        </Modal.Footer>
      </Modal>
      
      <Modal show={showPublishModal} onHide={() => setShowPublishModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {publishItemId && publishedItems.includes(publishItemId) ? 'Confirm Unpublish' : 'Confirm Publish'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {publishItemId && publishedItems.includes(publishItemId)
            ? 'Are you sure you want to unpublish this item?'
            : 'Are you sure you want to publish this item?'}
        </Modal.Body>
        <Modal.Footer>
          <CustomButton variant="secondary" onClick={() => setShowPublishModal(false)}>
            Cancel
          </CustomButton>
          <CustomButton variant="primary" onClick={confirmPublishUnpublish}>
            {publishItemId && publishedItems.includes(publishItemId) ? 'Unpublish' : 'Publish'}
          </CustomButton>
        </Modal.Footer>
      </Modal>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error.message}</p>}
    </Container>
  );
}