import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  FormSelectProps,
  Form,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";

export interface SelectOption {
  label: any;
  value: string | number;
}

type CustomSelectProps = FormSelectProps & {
  controlId?: string;
  formGroupClassName?: string;
  label?: any;
  options: SelectOption[];
  errorMessage?: string | undefined;
  hookForm?: any;
  hideLabel?: boolean;
  toolTipText?: string;
  applystyle?: boolean;
};

const CustomSelect: React.FC<CustomSelectProps> = ({
  controlId,
  formGroupClassName,
  label,
  options,
  errorMessage,
  hookForm,
  hideLabel,
  toolTipText,
  applystyle,
  ...props
}) => {
    // const placeholderStyle = {
    //     color: options[0].value === "" ? "#909090" : "#909090",
    //     // fontSize: options[0].value === "" ? "inherit" : "12px",
    //   };
  return (
    <Form.Group className={formGroupClassName} controlId={controlId}>
      {!hideLabel && (
        <div className="options-container">
          <Form.Label as={applystyle ? "strong" : undefined}>
            {label}
          </Form.Label>
          {/* <OverlayTrigger
             trigger={['hover', 'focus']}
             overlay={<Popover>
                <Popover.Header as='h3'></Popover.Header>
                <Popover.Body>
                   {toolTipText}
                </Popover.Body>
             </Popover>}
          >
             <span>
                <FontAwesomeIcon
                   icon={faInfoCircle}
                   style={{
                      cursor: "pointer",
                      marginLeft: "4px",
                      color: "#236ee8"
                   }} />
             </span>
          </OverlayTrigger> */}
        </div>
      )}
      <Form.Select {...props} {...hookForm}   >
                {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                        
                    </option>
                ))}
            </Form.Select>
             {/* <Form.Select {...props} {...hookForm}
               defaultValue="" 
               style={placeholderStyle}
               onChange={(e) => {
              
                 e.target.style.color = e.target.value === "" ? "gray" : "black";
               }}>
        
        <option value="" disabled >
          Please select...
        </option>
       
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Select> */}
     
      <Form.Control.Feedback type="invalid">
        {errorMessage}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default CustomSelect;
