import { gql } from '@apollo/client';

export const USER_SEND_OTP = gql`
	mutation UserSendOTP($input: SendOTPInput) {
		userSendOTP(input: $input) {
			success
		}
	}
`;

export const USER_LOGIN = gql`
	mutation UserLogin($user: UserLoginInput) {
		userLogin(user: $user) {
			token
			user {
				id
				companyName
				email
				jobTitle
				name
				receiveOffers
				isAdmin
			}
			expirationTime
			refreshToken
    		refreshExpiration	
		}
	}
`;



export const USERCREATE = gql`
	mutation userCreate($user: UserInput) {
		userCreate(user: $user) {
			token
			expirationTime
			refreshToken
			refreshExpiration
			user {
				id
				name
				email
				companyName
				jobTitle
				receiveOffers
				isAdmin
			}
		}
	}
`;


export const USERSAVE = gql`
mutation AddEmailToNotificationList($input: LiveNotifyInput) {
	addEmailToNotificationList(input: $input) {
	  success
	}
  }
`;

export const SHARE_REPORT_EMAIL = gql`
mutation ShareSizingReport($input: ShareSizingReportInput!) {
   shareSizingReport(input: $input)
}
`;

export const REFRESH_TOKEN = gql`
mutation GetRefreshToken($refreshToken: String!) {
  getRefreshToken(refreshToken: $refreshToken) {
    accessToken
    accessExpiration
  }
}
`
