import Router from 'router';
import { BrowserRouter, useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Global import
import "assets/scss/app.scss";
import "./i18n";
import { Suspense, useEffect } from 'react';
import SessionExpiry from 'pages/login/SessionExpiry';
import ReactGA from "react-ga4";

const TRACKING_ID = 'G-29LR8LS4BP'
ReactGA.initialize(TRACKING_ID);

function usePageTracking() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
      title: document.title,
    });
  }, [location]);
}

function PageTracker() {
  usePageTracking();
  return null; // This component doesn't render anything
}


function App() {
  return (
    <div className="App" style={{backgroundColor:"#F6F7F8"}}>
      <BrowserRouter>
        <PageTracker />
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={true} theme="colored" newestOnTop={false} closeOnClick
          rtl={false} />
          <Suspense fallback={<div>Loading...</div>}>
       
          <Router />
          </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
