import {
  CloudVendor,
  UsageType,
  OperatingSystem,
  GcpOperatingSystem,
} from "entities/enums";
import { useMutation, useQuery } from "@apollo/client";
import { CloudOptionsModel, InstanceModel } from "entities/models";
import { CustomButton, DeleteModal } from "components";
import { Region } from "entities/models/report";
import { StorageHelper, allowOnlyNumbers } from "utils";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

import {
  Accordion,
  Button,
  Form,
  Modal,
  OverlayTrigger,
  Table,
  Tooltip,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { UserHelper } from "utils";
import InstanceSelect from "./instance-select";
import {
  faDeleteLeft,
  faCopy,
  faTrashCan,
  faCheckSquare,
  faWindowClose,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Spinner } from "react-bootstrap";
import { DB_INSTANCE_RECOMMENDATION, GET_FULL_CONFIG } from "graphql/queries";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  storageType_AWS,
  storageType_AZURE,
  storageType_GCP,
} from "entities/enums/storage-type";
import { INSTANCE, STORAGE_CODE } from "graphql/queries/report.query";
import ButtonLoader from "components/ui-elements/loader/buttonLoader";
import EditIcon from "../../assets/icons/EditIcon";
import {
  IDBCopy,
  IDBCopyApp,
  IDBCopySid,
  IDBDeleteApp,
  IDBDeleteCopy,
  IDBServerChang,
  IDBServerDelete,
} from "entities/models/edit-report.model";
import TableHeader from "./table-header";
import { UPDATE_SID } from "graphql/mutations/report.mutations";
import { useParams } from "react-router-dom";
import { Yup } from "hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

interface IDbServerProps {
  instanceData?: any;
  id: number;
  handleChangeDBServer: (data: IDBServerChang) => void;
  handleCopyAPPServer: (data: IDBCopyApp) => void;
  handleDelete: (data: IDBServerDelete) => void;
  onDBServerCopy: () => void;
  server?: any;
  gcpserver?: any;
  gcpserverInstance?: any;
  dbserverInstance?: any;
  sizingReportId: string | undefined;
  primaryRegion: Region | null;
  backupRegion: Region | null;
  cloudVendor: CloudVendor | null;
  primaryDBServerInstance: InstanceModel | null;
  setPrimaryDBServerInstance: React.Dispatch<React.SetStateAction<any>>;
  setBackupDBServerInstance: React.Dispatch<React.SetStateAction<any>>;
  setDBServerInstance: React.Dispatch<React.SetStateAction<any>>;
  setgcpServerInstance: React.Dispatch<React.SetStateAction<any>>;
  setReportDetails: React.Dispatch<React.SetStateAction<any>>;
  dbServer: any;
  landscapeId: any;
  dbHaCount: any;
  setdbHaCount: React.Dispatch<React.SetStateAction<any>>;
  appServerInstanceData?: any;
  detailConfigs: any;
  showManualInputModal: any;

  setShowReportNamemodal: any;
  handleCopySID: (data: IDBCopySid) => void;
  handleSaveStep: () => void;
  handleAddSID: (data: IDBCopy) => void;
  handleDeleteSID: (data: IDBDeleteCopy) => void;
  handleDeleteAPPServer: (data: IDBDeleteApp) => void;
  delLoading: any;
  delLoadings: any;
  containerId: any;
  tierId: any;
  handleAddSIDStep: (id: any, sid: any) => void;
  counterror: any;
  recommendationId: any;
  setDetailConfig: React.Dispatch<React.SetStateAction<any>>;
  optionss: CloudOptionsModel | null;
  activeInnerTab: any;
  fetchedRecommendations: any;
  setFetchedRecommendations: React.Dispatch<React.SetStateAction<any>>;
  handlePreviousStep: () => void;
  Loadingapp: any;
  onUpdateSid: any;
  selectedInstance: any;
  isInstanceValid: any;
  appServerErrors: any;
  reportErrors: any;
  dbHaCountErrors: any;
  appCountErrors: any;
  appReportErrors: any;
  setSelectedInstance: (d: any) => void;

  isAppServerInstanceValid: any;
  isAppCountDRValid: boolean;
}
export interface Disk {
  __typename: string;
  code: DiskCode;
  type: string;
  max_throughput: string;
  vendor: string;
}
export type DiskCode = string;

type StorageOptions = {
  [key: string]: string;
};

export const formatDiskLabel = (code: string, vendor: string) => {
  let prefix = "";
  if (vendor === "AWS") {
    const diskLabelMapping: { [key: string]: string } = {
      gp3: "General Purpose SSD",
      gp2: "General Purpose SSD",
      io1: "Provisioned IOPS SSD",
      io2: "Provisioned IOPS SSD",
      sc1: "Cold HDD",
      st1: "Throughput Optimized HDD",
      standard: "Magnetic",
    };
    prefix = diskLabelMapping[code] || "Unknown Type";
  } else if (vendor === "AZURE") {
    const azureDiskLabelMapping: { [key: string]: string } = {
      P: "Premium SSD",
      E: "Standard SSD",
      S: "Standard HDD",
    };

    if (code === "Azure Premium SSD v2") {
      return (prefix = "Premium SSD v2");
    } else {
      if (code) {
        const firstLetter = code.charAt(0);
        const labelPrefix =
          azureDiskLabelMapping[
            firstLetter as keyof typeof azureDiskLabelMapping
          ] || "Unknown Azure Disk";
        prefix = labelPrefix;
      } else {
        prefix = "Unknown Azure Disk";
      }
    }
  } else if (vendor === "GCP") {
    if (code) {
      if (code.includes("SSD")) {
        return code;
      } else if (code.includes("Balanced")) {
        return `SSD ${code}`;
      } else {
        return `HDD ${code}`;
      }
    } else {
      return `HDD ${code}`;
    }
  }

  return `${prefix} | ${code}`;
};

const DbServerSelection = ({
  isAppCountDRValid,

  appReportErrors,
  reportErrors,
  dbHaCountErrors,
  setSelectedInstance,
  appServerInstanceData,
  onUpdateSid,
  setDetailConfig,
  activeInnerTab,
  optionss,
  handleCopyAPPServer,
  Loadingapp,
  handleCopySID,
  handleAddSID,
  handleDeleteSID,
  handleDeleteAPPServer,
  delLoading,
  delLoadings,
  detailConfigs,
  fetchedRecommendations,
  setFetchedRecommendations,
  dbHaCount,
  landscapeId,
  handleChangeDBServer,
  isInstanceValid,
  server,
  gcpserver,
  instanceData,
  cloudVendor,
  primaryRegion,
  backupRegion,
}: IDbServerProps) => {
  // DB Server selection states
  const { t } = useTranslation();
  const { id } = useParams();
  const reportsData = UserHelper.getRegions();
  const { data: StorageData } = useQuery(STORAGE_CODE);
  const [options, setOptions] = useState<
    { label: string; type: string; code: string }[]
  >([]);
  const [storageWarning, setStorageWarning] = useState("");
  const [appStorageWarning, setAppStorageWarning] = useState("");
  const [selectedStorageCode, setSelectedStorageCode] = useState("");
  const [maxThroughput, setMaxThroughput] = useState<string | undefined>("");
  const [dbSelectedStorageCode, setDbSelectedStorageCode] = useState("");
  const [databaseMaxThroughput, setDatabaseMaxThroughput] = useState<
    string | undefined
  >("");
  const [isEditing, setIsEditing] = useState(false);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<IDBDeleteApp | null>(null);

  const [showDeleteSID, setShowDeleteSID] = useState(false);
  const [showDeleteSIDModal, setShowDeleteSIDModal] = useState(false);
  const [SIDToDelete, setSIDToDelete] = useState<IDBDeleteCopy>();
  const requestDeleteSID = (item: any) => {
    setSIDToDelete(item);
    setShowDeleteSIDModal(true);
  };

  const requestDeleteItem = (item: any) => {
    setItemToDelete(item);
    setShowDeleteConfirmation(true);
  };
  const [vendor, setVendor] = useState("");
  // const getStorageTypeOptions = (vendor: string): string => {
  //   switch (cloudVendor) {
  //     case "AWS":
  //       return "gp2";
  //     case "GCP":
  //       return "Balanced provisioned";
  //     case "AZURE":
  //       return "P4 LRS";
  //     default:
  //       return "";
  //   }
  // };
  // const storageTypeOptions: string = getStorageTypeOptions(vendor);
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  useEffect(() => {
    // Set the initial value when the component

    const selectedDbDisk = StorageData?.getDisksCode.find(
      (disk: any) => disk.code === gcpserver?.DbandAppsConfig?.storageCode
    );
    const dbMaxThroughputValue = selectedDbDisk
      ? selectedDbDisk.max_throughput
      : "";
    setDatabaseMaxThroughput(dbMaxThroughputValue);
  }, []);

  useEffect(() => {
    // Set the initial value when the component mounts
    const selectedDbDisk = StorageData?.getDisksCode.find(
      (disk: any) => disk.code === "gp2"
    );
    const dbMaxThroughputValue = selectedDbDisk
      ? selectedDbDisk.max_throughput
      : "";
    setDatabaseMaxThroughput(dbMaxThroughputValue); // or any other value you desire
    setMaxThroughput(dbMaxThroughputValue);
  }, []);
  useEffect(() => {
    // Set the initial value when the component mounts for App Server
    gcpserver?.DbandAppsConfig?.AppServerConfigurations?.forEach(
      (appData: any, index: any) => {
        const selectedAppDisk = StorageData?.getDisksCode.find(
          (disk: any) => disk.code === appData?.storageCode
        );
        const appMaxThroughputValue = selectedAppDisk
          ? selectedAppDisk.max_throughput
          : "";
        // Assuming you have a state variable for maxThroughput for App Server
        setMaxThroughput(appMaxThroughputValue);
      }
    );
  }, [gcpserver?.DbandAppsConfig?.AppServerConfigurations]);

  useEffect(() => {
    const seenCodes = new Set<string>();

    const options = (
      StorageData?.getDisksCode?.filter(
        (disk: Disk) => disk.vendor === cloudVendor
      ) || []
    )
      .filter((disk: Disk) => {
        // Check if the code has been seen before
        if (seenCodes.has(disk.code)) {
          return false;
        } else {
          seenCodes.add(disk.code);
          return true;
        }
      })
      .map((disk: Disk) => ({
        label: `${formatDiskLabel(disk.code, disk.vendor)}`,
        type: disk.type,
        code: disk.code,
      }))
      .sort(
        (
          a: { code: string; type: string },
          b: { code: string; type: string }
        ) => a.code.localeCompare(b.code)
      );

    setOptions(options);
  }, [StorageData, cloudVendor]);

  useEffect(() => {
    if (gcpserver?.DbandAppsConfig?.storageCode) {
      setDbSelectedStorageCode(gcpserver?.DbandAppsConfig?.storageCode);
      const dbSelectedValue = gcpserver?.DbandAppsConfig?.storageCode;
      const selectedDbDisk = StorageData?.getDisksCode.find(
        (disk: any) => disk.code === dbSelectedValue
      );
      const dbMaxThroughputValue = selectedDbDisk
        ? selectedDbDisk.max_throughput
        : "";
      setDatabaseMaxThroughput(dbMaxThroughputValue);
    }
  }, [gcpserver?.DbandAppsConfig?.storageCode]);

  const storedData = StorageHelper.get("PrimaryDBServerInstances") || [];
  const matchingData = storedData.find(
    (data: any) => data?.id === activeInnerTab?.id
  );
  const matchingInstance = instanceData?.instances?.find(
    (item: { code: any }) =>
      item?.code === matchingData?.primaryDBServerInstance
  );

  const storeRecommandation = StorageHelper.get("fetchedRecommendations") || [];

  const uniqueIds = Array.from(new Set(storeRecommandation));

  // DB INSTANCE API CALL
  const { data } = useQuery(INSTANCE, {
    variables: {
      regionId: primaryRegion?.id ? primaryRegion?.id : primaryRegion,
      vendor: cloudVendor,
    },
  });

  const { data: dbinstancedata, loading: dbinstanceda } = useQuery(
    DB_INSTANCE_RECOMMENDATION,
    {
      variables: {
        input: {
          primaryRegionId: primaryRegion?.id
            ? primaryRegion?.id
            : primaryRegion,
          backupRegionId: backupRegion?.id ? backupRegion?.id : backupRegion,
          sizingReportId: activeInnerTab?.id ? activeInnerTab?.id : null,
          vendor: cloudVendor,
        },
      },
      fetchPolicy: "no-cache",

      skip:
        fetchedRecommendations?.includes(activeInnerTab?.id) ||
        (matchingData && matchingInstance) ||
        gcpserver?.DbandAppsConfig?.primaryDBServerInstance ||
        !activeInnerTab,
    }
  );

  useEffect(() => {
    loadDataAndUpdateConfig();
  }, [dbinstancedata, activeInnerTab, setFetchedRecommendations]);

  // Function to update the detail configuration based on DB server recommendations
  function updateConfigWithDbServerRecommendation() {
    setDetailConfig((prevLandscapes: any) => {
      const newLandscapes = prevLandscapes.map((landscape: any) => {
        // Clone the landscape to avoid direct mutations
        const updatedLandscape = { ...landscape };

        const primaryMemory =
          dbinstancedata?.dbServerRecommendation?.primary?.memory;
        const totalStorage = primaryMemory != null ? primaryMemory + 10 : 0;

        if (updatedLandscape.tierName !== "Other Infrastructure") {
          if (
            updatedLandscape.SizingReports &&
            updatedLandscape.SizingReports.length > 0
          ) {
            updatedLandscape.SizingReports = updatedLandscape.SizingReports.map(
              (report: any) => {
                if (report.id === activeInnerTab?.id) {
                  const defaultStorageCode = getDefaultStorageCode();
                  report.DbandAppsConfig = {
                    ...report.DbandAppsConfig,
                    primaryDBServerInstance:
                      dbinstancedata?.dbServerRecommendation?.primary?.code,
                    backupDBServerInstance:
                      dbinstancedata?.dbServerRecommendation?.backup?.code,
                    cpu: dbinstancedata?.dbServerRecommendation?.primary?.vcpu?.toString(),
                    ram: dbinstancedata?.dbServerRecommendation?.primary?.memory?.toString(),
                    dbHaCount: dbHaCount, // This assumes dbHaCount is defined elsewhere
                    storageSpace: 50,
                    dbOperatingSystem: "SUSE",
                    storageCode: "gp2",
                    storageType: "SSD",
                    enableDR: optionss?.includeDR,
                    dbHaCountDR: 1,
                    AppServerConfigurations: generateAppServerConfigurations(),
                  };

                  setFetchedRecommendations((prevList: any) => [
                    ...prevList,
                    activeInnerTab?.id,
                  ]);
                  setSelectedInstance(
                    dbinstancedata?.dbServerRecommendation?.primary?.code
                  );
                }

                return report;
              }
            );
          }
        }
        return updatedLandscape;
      });
      return newLandscapes;
    });
  }

  function loadDataAndUpdateConfig() {
    const storedData = StorageHelper.get("PrimaryDBServerInstances");

    if (storedData?.length > 0) {
      processStoredData(storedData);
    } else if (dbinstancedata?.dbServerRecommendation) {
      updateConfigForNewData();
    }
  }

  // Function to process existing stored data
  function processStoredData(storedData: any) {
    const matchingData = findMatchingData(storedData);
    const matchingInstance = findMatchingInstance(matchingData);

    if (!matchingData || !matchingInstance) {
      checkDbInstanceRecommendationAndUpdate();
    }
  }
  function findMatchingData(storedData: any) {
    return storedData.find((data: any) => data?.id === activeInnerTab?.id);
  }
  function findMatchingInstance(matchingData: any) {
    return instanceData?.instances?.find(
      (item: any) => item.code === matchingData?.primaryDBServerInstance
    );
  }

  // Function to check DB instance recommendation and update configuration accordingly
  function checkDbInstanceRecommendationAndUpdate() {
    if (dbinstancedata?.dbServerRecommendation) {
      updateConfigWithDbServerRecommendation();
    }
  }

  // Function to update configuration for new data
  function updateConfigForNewData() {
    setDetailConfig((prevLandscapes: any) =>
      updateLandscapesConfig(prevLandscapes)
    );
  }

  // Function to update the landscapes configuration
  function updateLandscapesConfig(prevLandscapes: any) {
    return prevLandscapes?.map(updateLandscapeConfig);
  }

  // Function to update an individual landscape configuration
  function updateLandscapeConfig(landscape: any) {
    const updatedLandscape = { ...landscape };
    if (updatedLandscape?.tierName === "Other Infrastructure") {
      return updatedLandscape;
    } else if (updatedLandscape?.SizingReports) {
      updatedLandscape.SizingReports =
        updatedLandscape.SizingReports.map(updateReportConfig);
      return updatedLandscape;
    }
  }

  // Function to update the report configuration
  function updateReportConfig(report: any) {
    if (report.id === activeInnerTab?.id) {
      setFetchedRecommendations((prevList: any) => [
        ...prevList,
        activeInnerTab?.id,
      ]);
      report.DbandAppsConfig = buildDbAndAppsConfig(report.DbandAppsConfig);
      setSelectedInstance(report.DbandAppsConfig.primaryDBServerInstance);
    }

    return { ...report };
  }

  const getDefaultStorageCode = useCallback(() => {
    switch (cloudVendor) {
      case "AWS":
        return "gp2";
      case "GCP":
        return "Balanced provisioned";
      case "AZURE":
        return "P6 LRS";
      default:
        return "";
    }
  }, [cloudVendor]);

  function buildDbAndAppsConfig(existingConfig: any) {
    const defaultStorageCode = getDefaultStorageCode();
    const primaryMemory =
      dbinstancedata?.dbServerRecommendation?.primary?.memory;
    const totalStorage = primaryMemory != null ? primaryMemory + 10 : 0;
    return {
      ...existingConfig,
      primaryDBServerInstance:
        dbinstancedata?.dbServerRecommendation?.primary?.code,
      backupDBServerInstance:
        dbinstancedata?.dbServerRecommendation?.backup?.code,
      cpu: dbinstancedata?.dbServerRecommendation?.primary?.vcpu?.toString(),
      ram: dbinstancedata?.dbServerRecommendation?.primary?.memory?.toString(),
      dbHaCount: dbHaCount, // Assuming dbHaCount is defined elsewhere
      storageSpace: 50,

      dbOperatingSystem: "SUSE",
      storageCode: getDefaultStorageCode(),
      storageType: "SSD",
      enableDR: optionss?.includeDR, // Assuming optionss is defined elsewhere
      dbHaCountDR: 1,
      AppServerConfigurations: generateAppServerConfigurations(),
    };
  }

  function generateAppServerConfigurations() {
    const defaultStorageCode = getDefaultStorageCode();
    return [
      {
        primaryAppServerInstance: null,
        backupAppServerInstance: null,
        appOperatingSystem: getAppOperatingSystemBasedOnCloudVendor(
          cloudVendor,
          detailConfigs?.appOperatingSystem
        ),
        appDROperatingSystem: getAppOperatingSystemBasedOnCloudVendor(
          cloudVendor,
          detailConfigs?.appOperatingSystem
        ),
        appServerCount: 1,
        appDRServerCount: 1,
        storageSpace: 50,
        storageCode: getDefaultStorageCode(),
        storageType: "SSD",
        enableDR: optionss?.includeDR,
        cpu: "",
        ram: "",
        storageCodeDR: getDefaultStorageCode(),
        storageTypeDR: "SSD",
        cpuDR: "",
        storageSpaceDR: 50,
        ramDR: "",
      },
    ];
  }

  function getAppOperatingSystemBasedOnCloudVendor(
    cloudVendor: any,
    appOperatingSystem: any
  ) {
    if (appOperatingSystem === "SUSE") {
      appOperatingSystem = "SUSE Linux Enterprise Server";
    }

    if (cloudVendor === "GCP") {
      switch (appOperatingSystem) {
        case "WINDOWS_SERVER_2008":
          return "Windows Server 2008";
        case "WINDOWS_SERVER_2012":
          return "Windows Server 2012";
        case "WINDOWS_SERVER_2016":
          return "Windows Server 2016";
        case "WINDOWS_SERVER_2019":
          return "Windows Server 2019";
        case "WINDOWS_SERVER_2022":
          return "Windows Server 2022";
        case "LINUX":
          return "CentOS, CoreOS, Debian, Ubuntu";
        case "UBUNTU_PRO":
          return "Ubuntu Pro";
        case "RHEL_SAP_WITH_HA_UPDATE_SERVICES":
          return "RHEL for SAP With HA & Updates Services";
        case "SLES_12_SAP":
          return "SLES 12 for SAP";
        case "SLES_15_SAP":
          return "SLES 15 for SAP";
        default:
          return "SUSE Linux Enterprise Server";
      }
    }

    return appOperatingSystem;
  }

  document.querySelectorAll(".table-responsive").forEach((element) => {
    // Assert that element is of type HTMLElement
    const htmlElement = element as HTMLElement;

    if (htmlElement.scrollWidth <= 1300) {
      htmlElement.style.overflowY = "auto";
      //htmlElement.style.overflowX = 'hidden';
    } else {
      htmlElement.style.overflowY = "hidden";
      htmlElement.style.overflowX = "hidden";
    }
  });

  document.querySelectorAll(".accordion-body").forEach((element) => {
    // Assert that element is of type HTMLElement
    const htmlElement = element as HTMLElement;

    if (htmlElement.scrollHeight > 240) {
      htmlElement.style.overflowY = "auto";
    } else {
      htmlElement.style.overflowY = "hidden";
    }
  });

  /*
   * rename sid implimentation
   */

  const sidSchema = Yup.object({
    sid: Yup.string()
      .required("SID is required")
      .matches(
        /^[a-zA-Z0-9]+$/,
        `${t("pages.manualInput.message.validation.sidAlphanumeric")}`
      )
      .min(3, `${t("pages.manualInput.message.validation.minimumLength")}`)
      .max(3, `${t("pages.manualInput.message.validation.maximumLength")}`),
  });
  const editContainerRef = useRef(null);
  // Dependencies include isEditing and localSid to handle dynamic updates

  type FormData = {
    sid: string;
    // other fields...
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    setValue,
    setError,
  } = useForm({
    resolver: yupResolver(sidSchema),
  });

  const [localSid, setLocalSid] = useState(gcpserver.sid);

  const [updateSid, { loading }] = useMutation(UPDATE_SID, {
    onCompleted: (data) => {
      if (data.updateSid) {
        onUpdateSid(gcpserver.id, getValues("sid"));
        setIsEditing(false);
        setLocalSid(getValues("sid"));
        toast.success(t("SizingReportUploadModal.updateMessage"));
      } else {
        setError("sid", {
          type: "manual",
          message: t("SizingReportUploadModal.duplicatemsg")!,
        });
      }
    },
    onError: (error) => {
      console.error("Error updating SID:", error);
      toast.error("An error occurred while updating SID. Please try again.");
    },
  });

  const handleSave = async () => {
    const sid = getValues("sid");

    await updateSid({
      variables: {
        input: {
          containerId: id,
          reportId: gcpserver.id,
          sid: sid,
        },
      },
    });

    if (errors) {
      return;
    }
    // Log the update response
    onUpdateSid(gcpserver.id, sid);
    setIsEditing(false);
  };

  const toggleEditMode = () => {
    setIsEditing(!isEditing);
    if (!isEditing) {
      reset({ sid: gcpserver.sid }); // Reset the form to the current SID when entering edit mode
    }
  };
  const handleClose = () => {
    setIsEditing(false);
    reset(); // Optional: Reset form to initial state when closing without saving
  };
  useEffect(() => {
    function handleClickOutside(event: any) {
      //@ts-ignore
      // prettier-ignore
      if (editContainerRef.current && !editContainerRef?.current?.contains(event.target)) {
        if (isEditing) {
          setIsEditing(false); // Close the input field
          reset({ sid: localSid }); // Optionally reset the form values to the last saved state
        }
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isEditing, localSid, reset]);
  /**
   *
   * rename sid implimentation complete
   */

  const inputRef = useRef(null);
  return (
    <>
      <div className="containerdb" ref={editContainerRef}>
        <>
          <div>
            <div className="float-child">
              <div>
                <div className="containerdb1">
                  <div className="cloud-sid">
                    {isEditing ? (
                      <form onSubmit={handleSubmit(handleSave)}>
                        <div className="containerdb1">
                          <input
                            type="text"
                            className="form-control"
                            {...register("sid")}
                            onChange={(e) => {
                              const uppercaseValue = e.target.value
                                .toUpperCase()
                                .slice(0, 3);
                              setValue("sid", uppercaseValue, {
                                shouldValidate: true,
                              });
                            }}
                            style={{ height: "25px" }}
                          />

                          <FontAwesomeIcon
                            icon={faWindowClose}
                            color="red"
                            size="lg"
                            className="mx-1"
                            onClick={handleClose}
                          />
                          <FontAwesomeIcon
                            icon={faCheckSquare}
                            color="green"
                            size="lg"
                            onClick={handleSubmit(handleSave)}
                          />
                        </div>
                        {errors.sid && (
                          <p className="error">
                            {errors && errors.sid.message}
                          </p>
                        )}
                      </form>
                    ) : (
                      <>
                        {localSid}

                        <span onClick={toggleEditMode} className="editSid">
                          <EditIcon />
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="float-child">
              <div className="customBtn">
                <CustomButton
                  variant="outline-danger"
                  type="button"
                  className="button-spacing m-1"
                  onClick={() =>
                    requestDeleteSID({
                      tiername: landscapeId,
                      sid: gcpserver?.sid,
                      sizingReportId: gcpserver?.id,
                    })
                  }
                >
                  {delLoading ? (
                    <>
                      <div
                        className={`d-flex justify-content-center align-items-center`}
                      >
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          variant="white"
                        />
                        {t("loadTemplate.loading")}
                      </div>
                    </>
                  ) : (
                    <>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            {" "}
                            {t("pages.reports.dbServerSelection.delete")}
                          </Tooltip>
                        }
                      >
                        <FontAwesomeIcon
                          icon={faDeleteLeft}
                          className="icon-text"
                          onClick={() => {
                            setShowDeleteSID(true);
                          }}
                        />
                      </OverlayTrigger>
                      <span className="button-text hide-text-small Btn">
                        {t("pages.reports.dbServerSelection.delete")}
                      </span>
                    </>
                  )}
                </CustomButton>

                <CustomButton
                  variant="outline-primary"
                  className="button-spacing m-1"
                  style={{ border: "1px solid #0D61BB", color: "#0D61BB" }}
                  type="button"
                  onClick={() =>
                    handleCopySID({
                      tiername: landscapeId,
                      sid: gcpserver?.sid,
                      id: gcpserver?.id,
                    })
                  }
                >
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="button-tooltip" className="custom-tooltip">
                        {t("pages.reports.dbServerSelection.copy")}
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon icon={faCopy} className="icon-text" />
                  </OverlayTrigger>
                  <span className="button-text hide-text-small Btn">
                    {t("pages.reports.dbServerSelection.copy")}
                  </span>
                </CustomButton>

                <CustomButton
                  variant="primary"
                  type="button"
                  className="button-spacing m-1"
                  style={{ backgroundColor: "#0d61bb" }}
                  onClick={() =>
                    handleAddSID({
                      tiername: landscapeId,
                      sid: gcpserver?.sid,
                    })
                  }
                >
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {" "}
                        {t("pages.reports.dbServerSelection.addSid")}
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon icon={faPlus} className="icon-text" />
                  </OverlayTrigger>
                  <span className="button-text hide-text-small Btn">
                    {t("pages.reports.dbServerSelection.addSid")}
                  </span>
                </CustomButton>
              </div>
            </div>
          </div>
        </>
        <div className="wrapper custom-scroll wrap ">
          <Accordion defaultActiveKey={["0"]} alwaysOpen>
            {detailConfigs?.backupRegionId &&
            optionss?.includeDR &&
            landscapeId === "Production" ? (
              <>
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="acordionHeader">
                    <div style={{ flex: 1 }} className="accordian-heads">
                      {(() => {
                        const region = reportsData?.regions?.find(
                          (region: any) =>
                            region.id === Number(detailConfigs.primaryRegionId)
                        );

                        if (region) {
                          return region.code;
                        } else {
                          return "";
                        }
                      })()}

                      <span className="dashboard-font-Size dashboard">
                        ({t("pages.reports.dbServerSelection.primary")})
                      </span>
                    </div>
                    <div className="app-server">
                      <CustomButton
                        variant="outline-primary"
                        type="button"
                        className="button-spacing dashboard-font-Size "
                        style={{ marginLeft: "auto", color: "white" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCopyAPPServer({
                            landscapeId: landscapeId,
                            sid: gcpserver?.sid,
                          });
                        }}
                      >
                        {t("pages.reports.dbServerSelection.addAppServer")}
                      </CustomButton>
                    </div>
                  </Accordion.Header>

                  <Accordion.Body className="accordion-body">
                    <Table className="table-responsive dbtable ">
                      <TableHeader />
                      <tbody className="tbody tableBody">
                        <tr style={{ verticalAlign: "middle" }}>
                          <td style={{ width: "75px" }}>
                            {t("pages.reports.instanceSelection.hanaDb")}
                          </td>

                          <td className="dashboard-font-Size">
                            {dbinstanceda ? (
                              <div className="loader">
                                <ButtonLoader />
                              </div>
                            ) : (
                              <InstanceSelect
                                ref={inputRef}
                                isInvalid={
                                  !isInstanceValid &&
                                  reportErrors[gcpserver.sid] &&
                                  !dbinstanceda
                                }
                                id="databaseInstanceSelect"
                                usage={UsageType.HANA_INSTANCE}
                                region={primaryRegion}
                                placeholder={
                                  t(
                                    "pages.reports.dbServerSelection.instance"
                                  ) as string
                                }
                                disabled={!cloudVendor || !primaryRegion}
                                context="dbInstance"
                                isloading={dbinstanceda}
                                value={
                                  cloudVendor === "GCP"
                                    ? gcpserver?.DbandAppsConfig
                                        ?.primaryDBServerInstance
                                    : gcpserver?.DbandAppsConfig
                                        ?.primaryDBServerInstance
                                }
                                onChange={(data) => {
                                  handleChangeDBServer({
                                    id: 1,
                                    landscapeId: landscapeId,
                                    sid: gcpserver?.sid,
                                    key: "primaryDBServerInstance",
                                    value: data,
                                  });
                                }}
                                options={instanceData}
                              />
                            )}
                          </td>
                          <td style={{ width: "100px" }}>
                            {dbinstanceda ? (
                              <div className="loader">
                                <ButtonLoader />
                              </div>
                            ) : (
                              <InstanceSelect
                                ref={inputRef}
                                isInvalid={
                                  !isInstanceValid &&
                                  reportErrors[gcpserver.sid] &&
                                  !dbinstanceda
                                }
                                context="cpu"
                                id="vcpuInstanceSelect"
                                placeholder={
                                  t("pages.reports.quotation.cpu") as string
                                }
                                usage={UsageType.HANA_INSTANCE}
                                region={primaryRegion}
                                isloading={dbinstanceda}
                                disabled={!cloudVendor || !primaryRegion}
                                value={gcpserver?.DbandAppsConfig?.cpu}
                                onChange={(data) => {
                                  handleChangeDBServer({
                                    id: 1,
                                    landscapeId: landscapeId,
                                    sid: gcpserver?.sid,
                                    key: "primaryDBServerInstance",
                                    value: data,
                                  });
                                }}
                                options={instanceData}
                              />
                            )}
                          </td>
                          <td>
                            {dbinstanceda ? (
                              <div className="loader">
                                <ButtonLoader />
                              </div>
                            ) : (
                              <InstanceSelect
                                ref={inputRef}
                                isInvalid={
                                  !isInstanceValid &&
                                  reportErrors[gcpserver.sid] &&
                                  !dbinstanceda
                                }
                                context="ram"
                                placeholder={
                                  t(
                                    "pages.reports.dbServerSelection.ram"
                                  ) as string
                                }
                                id="ramInstanceSelect"
                                toolTipText="Tooltip Info"
                                usage={UsageType.HANA_INSTANCE}
                                region={primaryRegion}
                                isloading={dbinstanceda}
                                disabled={!cloudVendor || !primaryRegion}
                                value={gcpserver?.DbandAppsConfig?.ram}
                                onChange={(data: any) => {
                                  handleChangeDBServer({
                                    id: 1,
                                    landscapeId: landscapeId,
                                    sid: gcpserver?.sid,
                                    key: "primaryDBServerInstance",
                                    value: data,
                                  });
                                }}
                                options={instanceData}
                              />
                            )}
                          </td>
                          {/* ////////////////////////////STORAGESIZE PRIMARY */}
                          <td>
                            <Form.Select
                              name="Storage Type"
                              value={`${
                                gcpserver?.DbandAppsConfig?.storageType
                              },${
                                gcpserver?.DbandAppsConfig?.storageCode || ""
                              }`}
                              className=" storage storageType"
                              onChange={(data) => {
                                handleChangeDBServer({
                                  id: 1,
                                  landscapeId: landscapeId,
                                  sid: gcpserver?.sid,
                                  key: "dbstorageType",
                                  value: data?.target?.value,
                                });
                              }}
                            >
                              {" "}
                              <option value="">Select</option>
                              {options.map((option) => (
                                <option
                                  key={option.label}
                                  value={`${option.type},${option.code}`}
                                >
                                  {option.label}
                                </option>
                              ))}
                            </Form.Select>
                          </td>
                          <td>
                            {dbinstanceda ? (
                              <div className="loader">
                                <ButtonLoader />
                              </div>
                            ) : (
                              <div>
                                <input
                                  className="form-control storage storageSpace"
                                  type="text"
                                  style={{
                                    border: storageWarning
                                      ? " 1px solid #F7C600"
                                      : "1px solid  #CBD5E0",
                                    // border: "1px solid  #CBD5E0",
                                  }}
                                  value={
                                    gcpserver?.DbandAppsConfig?.storageSpace
                                  }
                                  onChange={(data) => {
                                    const validNumber =
                                      data.target.value.replace(/[^0-9]/g, "");
                                    handleChangeDBServer({
                                      id: 1,
                                      landscapeId: landscapeId,
                                      sid: gcpserver?.sid,
                                      key: "dbstorageSpace",
                                      value: validNumber,
                                    });
                                  }}
                                  onBlur={(event: any) => {
                                    const enteredStorageSpace = Number(
                                      event.target.value
                                    );

                                    const minimumStorageSpace = 10;
                                    if (
                                      enteredStorageSpace < minimumStorageSpace
                                    ) {
                                      setStorageWarning(
                                        t("errorMessages.warning") as string
                                      );
                                    } else {
                                      setStorageWarning("");
                                    }
                                  }}
                                />
                              </div>
                            )}
                          </td>
                          {/* ////////////////////////////////////////////////////////////////////////// */}

                          {/*
                          <td>
                            <Form.Select
                              name="Storage Code"
                              value={gcpserver?.DbandAppsConfig?.storageCode}
                              className=" storage storageCode"
                              onChange={(data) => {
                                const dbSelectedValue = data?.target?.value;
                                setDbSelectedStorageCode(dbSelectedValue);
                                const selectedDbDisk =
                                  StorageData?.getDisksCode.find(
                                    (disk: any) => disk.code === dbSelectedValue
                                  );
                                const dbMaxThroughputValue = selectedDbDisk
                                  ? selectedDbDisk.max_throughput
                                  : "";
                                setDatabaseMaxThroughput(dbMaxThroughputValue);
                                handleChangeDBServer({
                                  id: 1,
                                  landscapeId: landscapeId,
                                  sid: gcpserver?.sid,
                                  key: "dbstorageCode",
                                  value: dbSelectedValue,
                                });
                              }}
                            >
                              {options.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </Form.Select>
                          </td> */}
                          <td>
                            <input
                              className="form-control  storage dbOS"
                              type="text"
                              readOnly
                              value="SUSE"
                            />
                          </td>

                          <td>
                            <div>
                              <input
                                className="form-control storage dbCount"
                                type="text"
                                style={{
                                  // border: "1px solid  #CBD5E0",
                                  border: dbHaCountErrors[gcpserver?.sid]
                                    ? "1px solid red"
                                    : "1px solid #CBD5E0",
                                }}
                                value={gcpserver?.DbandAppsConfig?.dbHaCount}
                                onChange={(data) => {
                                  handleChangeDBServer({
                                    id: 1,
                                    landscapeId: landscapeId,
                                    sid: gcpserver?.sid,
                                    key: "dbHaCount",
                                    value: data?.target?.value,
                                  });
                                }}
                                onKeyDown={(e) => allowOnlyNumbers(e)}
                                inputMode="numeric"
                              />
                            </div>
                          </td>
                          <td>
                            <div
                              onClick={() =>
                                requestDeleteSID({
                                  tiername: landscapeId,
                                  sid: gcpserver?.sid,
                                  sizingReportId: gcpserver?.id,
                                })
                              }
                            >
                              {delLoading ? (
                                <>
                                  <div
                                    className={`d-flex justify-content-center align-items-center`}
                                  >
                                    <Spinner
                                      as="span"
                                      animation="grow"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                      variant="primary"
                                    />
                                  </div>
                                </>
                              ) : (
                                // <FontAwesomeIcon
                                //   icon={faTrashCan}
                                //   color="#dc3545"
                                // />
                                <Button
                                  title="Delete"
                                  variant="outline-danger"
                                  type="button"
                                  size="sm"
                                  onClick={() => {
                                    setShowDeleteSID(true);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faTrashCan} />
                                </Button>
                              )}
                            </div>
                          </td>
                        </tr>
                        {gcpserver?.DbandAppsConfig?.AppServerConfigurations?.map(
                          (appData: any, index: any) => (
                            <tr style={{ verticalAlign: "middle" }}>
                              <td style={{ width: "75px" }}>
                                {t("pages.reports.dbServerSelection.app")}
                              </td>
                              <td>
                                <InstanceSelect
                                  ref={inputRef}
                                  isInvalid={
                                    appReportErrors[
                                      `${gcpserver.sid}-AppServer-${index}`
                                    ]?.instanceError ?? false
                                  }
                                  context="dbInstance"
                                  placeholder={
                                    t(
                                      "pages.reports.dbServerSelection.instance"
                                    ) as string
                                  }
                                  id="applicationInstanceSelect"
                                  toolTipText="Tooltip Info"
                                  usage={UsageType.ANY}
                                  vendor={cloudVendor}
                                  region={primaryRegion}
                                  disabled={
                                    !cloudVendor ||
                                    !primaryRegion ||
                                    server?.showLoading ||
                                    gcpserver?.showLoading
                                  }
                                  value={
                                    cloudVendor === "GCP"
                                      ? appData?.primaryAppServerInstance
                                      : appData?.primaryAppServerInstance
                                  }
                                  onChange={(data) => {
                                    handleChangeDBServer({
                                      id: index,
                                      landscapeId: landscapeId,
                                      sid: gcpserver?.sid,
                                      key: "primaryAppServerInstance",
                                      value: data,
                                    });
                                  }}
                                  options={appServerInstanceData}
                                />
                              </td>
                              <td>
                                <InstanceSelect
                                  ref={inputRef}
                                  isInvalid={
                                    appReportErrors[
                                      `${gcpserver.sid}-AppServer-${index}`
                                    ]?.instanceError ?? false
                                  }
                                  context="cpu"
                                  id="applicationInstanceSelect"
                                  placeholder={
                                    t("pages.reports.quotation.cpu") as string
                                  }
                                  toolTipText="Tooltip Info"
                                  usage={UsageType.ANY}
                                  vendor={cloudVendor}
                                  region={primaryRegion}
                                  disabled={
                                    !cloudVendor ||
                                    !primaryRegion ||
                                    server?.showLoading ||
                                    gcpserver?.showLoading
                                  }
                                  //@ts-ignore
                                  value={appData?.cpu}
                                  onChange={(data) => {
                                    handleChangeDBServer({
                                      id: index,
                                      landscapeId: landscapeId,
                                      sid: gcpserver?.sid,
                                      key: "primaryAppServerInstance",
                                      value: data,
                                    });
                                  }}
                                  options={appServerInstanceData}
                                />
                              </td>
                              <td>
                                <InstanceSelect
                                  ref={inputRef}
                                  isInvalid={
                                    appReportErrors[
                                      `${gcpserver.sid}-AppServer-${index}`
                                    ]?.instanceError ?? false
                                  }
                                  context="ram"
                                  id="applicationInstanceSelect"
                                  toolTipText="Tooltip Info"
                                  placeholder={
                                    t(
                                      "pages.reports.dbServerSelection.ram"
                                    ) as string
                                  }
                                  usage={UsageType.ANY}
                                  vendor={cloudVendor}
                                  region={primaryRegion}
                                  disabled={
                                    !cloudVendor ||
                                    !primaryRegion ||
                                    server?.showLoading ||
                                    gcpserver?.showLoading
                                  }
                                  //@ts-ignore
                                  value={appData?.ram}
                                  onChange={(data) => {
                                    handleChangeDBServer({
                                      id: index,
                                      landscapeId: landscapeId,
                                      sid: gcpserver?.sid,
                                      key: "primaryAppServerInstance",
                                      value: data,
                                    });
                                  }}
                                  options={appServerInstanceData}
                                />
                              </td>
                              {/* //////////////////////////////////////////////////////////////////////////////////////// */}
                              <td>
                                <Form.Select
                                  name="Storage Type"
                                  value={`${
                                    gcpserver?.DbandAppsConfig
                                      ?.AppServerConfigurations[index]
                                      ?.storageType
                                  },${
                                    gcpserver?.DbandAppsConfig
                                      ?.AppServerConfigurations[index]
                                      ?.storageCode || ""
                                  }`}
                                  className=" storage storageType"
                                  onChange={(data) => {
                                    handleChangeDBServer({
                                      id: index,
                                      landscapeId: landscapeId,
                                      sid: gcpserver?.sid,
                                      key: "storageType",
                                      value: data?.target?.value,
                                    });
                                  }}
                                >
                                  <option value="">Select</option>
                                  {options.map((option) => (
                                    <option
                                      key={option.label}
                                      value={`${option.type},${option.code}`}
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                                </Form.Select>
                              </td>
                              <td>
                                {dbinstanceda ? (
                                  <div className="loader">
                                    <ButtonLoader />
                                  </div>
                                ) : (
                                  <div>
                                    <input
                                      className="form-control storage storageSpace"
                                      type="text"
                                      style={{
                                        border: storageWarning
                                          ? " 1px solid #F7C600"
                                          : "1px solid  #CBD5E0",
                                        // border: "1px solid  #CBD5E0",
                                      }}
                                      value={appData?.storageSpace}
                                      onChange={(data) => {
                                        const validNumber =
                                          data.target.value.replace(
                                            /[^0-9]/g,
                                            ""
                                          );
                                        handleChangeDBServer({
                                          id: index,
                                          landscapeId: landscapeId,
                                          sid: gcpserver?.sid,
                                          key: "storageSpace",
                                          value: validNumber,
                                        });
                                      }}
                                      onBlur={(event: any) => {
                                        const enteredStorageSpace = Number(
                                          event.target.value
                                        );
                                        const minimumStorageSpace = 10;
                                        if (
                                          enteredStorageSpace <
                                          minimumStorageSpace
                                        ) {
                                          setAppStorageWarning(
                                            t(
                                              "errorMessages.warning1"
                                            ) as string
                                          );
                                        } else {
                                          setAppStorageWarning("");
                                        }
                                      }}
                                    />
                                  </div>
                                )}
                              </td>
                              {/* /////////////////////////////////////////////////////////////////////////////////// */}
                              {/*<td>
                                <div>
                                  <input
                                    className="form-control storage storageSpace"
                                    type="text"
                                    style={{
                                      border: appStorageWarning
                                        ? " 1px solid #F7C600"
                                        : "1px solid  #CBD5E0",
                                      // border: "1px solid  #CBD5E0",
                                    }}
                                    value={appData?.storageSpace}
                                    onChange={(data) => {
                                      const validNumber =
                                        data.target.value.replace(
                                          /[^0-9]/g,
                                          ""
                                        );
                                      handleChangeDBServer({
                                        id: index,
                                        landscapeId: landscapeId,
                                        sid: gcpserver?.sid,
                                        key: "storageSpace",
                                        value: validNumber,
                                      });
                                    }}
                                    onBlur={(event: any) => {
                                      const enteredStorageSpace = Number(
                                        event.target.value
                                      );
                                      const minimumStorageSpace = 10;
                                      if (
                                        enteredStorageSpace <
                                        minimumStorageSpace
                                      ) {
                                        setAppStorageWarning(
                                          t("errorMessages.warning1") as string
                                        );
                                      } else {
                                        setAppStorageWarning("");
                                      }
                                    }}
                                  />
                                </div>
                              </td>
                               <td>
                                <Form.Select
                                  name="Storage Type"
                                  value={appData?.storageType}
                                  className=" storage storageType"
                                  onChange={(data) => {
                                    handleChangeDBServer({
                                      id: index,
                                      landscapeId: landscapeId,
                                      sid: gcpserver?.sid,
                                      key: "storageType",
                                      value: data?.target?.value,
                                    });
                                  }}
                                >
                                  {Object.entries(storageTypeOptions).map(
                                    ([key, val]) => (
                                      <option
                                        key={String(key)}
                                        value={String(val)}
                                      >
                                        {val}
                                      </option>
                                    )
                                  )}
                                </Form.Select>
                              </td>
                              <td>
                                <Form.Select
                                  name="Storage Code"
                                  value={appData?.storageCode}
                                  className="storage storageCode"
                                  onChange={(data) => {
                                    const SelectedValue = data?.target?.value;
                                    setSelectedStorageCode(SelectedValue);
                                    const selectedDisk =
                                      StorageData?.getDisksCode.find(
                                        (disk: any) =>
                                          disk.code === SelectedValue
                                      );
                                    const maxThroughputValue = selectedDisk
                                      ? selectedDisk.max_throughput
                                      : "";
                                    setMaxThroughput(maxThroughputValue);
                                    handleChangeDBServer({
                                      id: index,
                                      landscapeId: landscapeId,
                                      sid: gcpserver?.sid,
                                      key: "storageCode",
                                      value: SelectedValue,
                                    });
                                  }}
                                >
                                  {options.map((option) => (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                                </Form.Select>
                              </td> */}
                              <td>
                                <Form.Select
                                  name="operatingSystem"
                                  value={appData?.appOperatingSystem}
                                  className="storage operatingSystem"
                                  onChange={(data) => {
                                    handleChangeDBServer({
                                      id: index,
                                      landscapeId: landscapeId,
                                      sid: gcpserver?.sid,
                                      key: "appOperatingSystem",
                                      value: data?.target?.value,
                                    });
                                  }}
                                >
                                  {cloudVendor === "GCP"
                                    ? Object.entries(GcpOperatingSystem).map(
                                        ([key, val]) => (
                                          <option key={val} value={val}>
                                            {val}
                                          </option>
                                        )
                                      )
                                    : Object.entries(OperatingSystem).map(
                                        ([key, val]) => (
                                          <option key={val} value={val}>
                                            {val}
                                          </option>
                                        )
                                      )}
                                </Form.Select>
                              </td>
                              <td>
                                <div>
                                  <input
                                    className="form-control storage appServerCount"
                                    type="text"
                                    style={{
                                      border: appReportErrors[
                                        `${gcpserver.sid}-AppServer-${index}`
                                      ]?.countError
                                        ? "1px solid red"
                                        : "1px solid #CBD5E0",
                                    }}
                                    value={appData?.appServerCount}
                                    onChange={(data) =>
                                      handleChangeDBServer({
                                        id: index,
                                        landscapeId: landscapeId,
                                        sid: gcpserver?.sid,
                                        key: "appServerCount",
                                        value: data?.target?.value,
                                      })
                                    }
                                    onKeyDown={(e) => allowOnlyNumbers(e)}
                                    inputMode="numeric"
                                  />
                                </div>
                              </td>
                              <td>
                                <div
                                  onClick={() =>
                                    requestDeleteItem({
                                      tiername: landscapeId,
                                      sid: gcpserver?.sid,
                                      sizingReportId: appData?.id,
                                      appindex: index,
                                    })
                                  }
                                >
                                  {delLoadings && Loadingapp === appData?.id ? (
                                    <>
                                      <div
                                        className={`d-flex justify-content-center align-items-center`}
                                      >
                                        <Spinner
                                          as="span"
                                          animation="grow"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                          variant="primary"
                                        />
                                      </div>
                                    </>
                                  ) : (
                                    // <FontAwesomeIcon
                                    //   icon={faTrashCan}
                                    //   color="#dc3545"
                                    // />
                                    <Button
                                      title="Delete"
                                      variant="outline-danger"
                                      type="button"
                                      size="sm"
                                      onClick={() =>
                                        setShowDeleteConfirmation(true)
                                      }
                                    >
                                      <FontAwesomeIcon icon={faTrashCan} />
                                    </Button>
                                  )}
                                </div>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  </Accordion.Body>

                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {storageWarning && (
                      <div
                        className="error-warning"
                        style={{ flex: 1, marginRight: "10px" }}
                      >
                        <strong>
                          <FontAwesomeIcon
                            icon={faTriangleExclamation}
                            className="error-icon-warning"
                          />
                          {t("Qutation.warning")}
                        </strong>
                        {t("errorMessages.warning")}
                      </div>
                    )}

                    {appStorageWarning && (
                      <div className="error-warning" style={{ flex: 1 }}>
                        <strong>
                          <FontAwesomeIcon
                            icon={faTriangleExclamation}
                            className="error-icon-warning"
                          />{" "}
                          {t("Qutation.warning")}
                        </strong>
                        {t("errorMessages.warning1")}
                      </div>
                    )}
                  </div>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    {" "}
                    <div className="cloud-title dashboard-font-Size containerdb1">
                      <div>
                        {(() => {
                          const region = reportsData?.regions?.find(
                            (region: any) =>
                              region.id === Number(detailConfigs.backupRegionId)
                          );

                          if (region) {
                            return region.code;
                          } else {
                            return "";
                          }
                        })()}
                        <span className="dashboard-font-Size dashboard">
                          {" "}
                          ({t("pages.reports.dbServerSelection.dr")})
                        </span>
                      </div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Table
                      className="table-responsive dbtable"
                      style={{
                        margin: "2px",
                      }}
                    >
                      <TableHeader />
                      <tbody className="tableBody">
                        <tr style={{ verticalAlign: "middle" }}>
                          <td style={{ width: "75px" }}>
                            {t("pages.reports.instanceSelection.hanaDb")}
                          </td>

                          <td>
                            {dbinstanceda ? (
                              <div className="loader">
                                <ButtonLoader />
                              </div>
                            ) : (
                              <InstanceSelect
                                ref={inputRef}
                                isInvalid={
                                  !isInstanceValid &&
                                  reportErrors[gcpserver.sid] &&
                                  !dbinstanceda
                                }
                                id="databaseInstanceSelect"
                                usage={UsageType.HANA_INSTANCE}
                                region={primaryRegion}
                                disabled={!cloudVendor || !primaryRegion}
                                placeholder={
                                  t(
                                    "pages.reports.dbServerSelection.instance"
                                  ) as string
                                }
                                context="dbInstance"
                                isloading={dbinstanceda}
                                value={
                                  cloudVendor === "GCP"
                                    ? gcpserver?.DbandAppsConfig
                                        ?.primaryDBServerInstance
                                    : gcpserver?.DbandAppsConfig
                                        ?.primaryDBServerInstance
                                }
                                onChange={(data) => {
                                  handleChangeDBServer({
                                    id: 1,
                                    landscapeId: landscapeId,
                                    sid: gcpserver?.sid,
                                    key: "primaryDBServerInstance",
                                    value: data,
                                  });
                                }}
                                options={instanceData}
                              />
                            )}
                          </td>
                          <td>
                            {dbinstanceda ? (
                              <div className="loader">
                                <ButtonLoader />
                              </div> // Your loading indicator
                            ) : (
                              <InstanceSelect
                                ref={inputRef}
                                isInvalid={
                                  !isInstanceValid &&
                                  reportErrors[gcpserver.sid] &&
                                  !dbinstanceda
                                }
                                context="cpu"
                                id="vcpuInstanceSelect"
                                placeholder={
                                  t("pages.reports.quotation.cpu") as string
                                }
                                usage={UsageType.HANA_INSTANCE}
                                region={primaryRegion}
                                isloading={dbinstanceda}
                                disabled={!cloudVendor || !primaryRegion}
                                //@ts-ignore
                                value={gcpserver?.DbandAppsConfig?.cpu}
                                onChange={(data) => {
                                  handleChangeDBServer({
                                    id: 1,
                                    landscapeId: landscapeId,
                                    sid: gcpserver?.sid,
                                    key: "primaryDBServerInstance",
                                    value: data,
                                  });
                                }}
                                options={instanceData}
                              />
                            )}
                          </td>
                          <td>
                            {dbinstanceda ? (
                              <div className="loader">
                                <ButtonLoader />
                              </div>
                            ) : (
                              <InstanceSelect
                                ref={inputRef}
                                isInvalid={
                                  !isInstanceValid &&
                                  reportErrors[gcpserver.sid] &&
                                  !dbinstanceda
                                }
                                context="ram"
                                id="ramInstanceSelect"
                                placeholder={
                                  t(
                                    "pages.reports.dbServerSelection.ram"
                                  ) as string
                                }
                                toolTipText="Tooltip Info"
                                usage={UsageType.HANA_INSTANCE}
                                isloading={dbinstanceda}
                                region={primaryRegion}
                                disabled={!cloudVendor || !primaryRegion}
                                //@ts-ignore
                                value={gcpserver?.DbandAppsConfig?.ram}
                                onChange={(data: any) => {
                                  handleChangeDBServer({
                                    id: 1,
                                    landscapeId: landscapeId,
                                    sid: gcpserver?.sid,
                                    key: "primaryDBServerInstance",
                                    value: data,
                                  });
                                }}
                                options={instanceData}
                              />
                            )}
                          </td>
                          {/* ///////////////////////////////////////////////////////////////////////////////////////////////////// */}

                          <td>
                            <Form.Select
                              name="Storage Type"
                              value={`${
                                gcpserver?.DbandAppsConfig?.storageType
                              },${
                                gcpserver?.DbandAppsConfig?.storageCode || ""
                              }`}
                              className="storage storageType"
                              onChange={(data) => {
                                handleChangeDBServer({
                                  id: 1,
                                  landscapeId: landscapeId,
                                  sid: gcpserver?.sid,
                                  key: "dbstorageType",
                                  value: data?.target?.value,
                                });
                              }}
                            >
                              {" "}
                              <option value="">Select</option>
                              {options.map((option) => (
                                <option
                                  key={option.label}
                                  value={`${option.type},${option.code}`}
                                >
                                  {option.label}
                                </option>
                              ))}
                            </Form.Select>
                          </td>

                          <td>
                            {dbinstanceda ? (
                              <div className="loader">
                                <ButtonLoader />
                              </div>
                            ) : (
                              <div>
                                <input
                                  className="form-control storage storageSpace"
                                  type="text"
                                  style={{
                                    border: storageWarning
                                      ? " 1px solid #F7C600"
                                      : "1px solid  #CBD5E0",
                                  }}
                                  value={
                                    gcpserver?.DbandAppsConfig?.storageSpace
                                  }
                                  onChange={(data) => {
                                    const validNumber =
                                      data.target.value.replace(/[^0-9]/g, "");

                                    handleChangeDBServer({
                                      id: 1,
                                      landscapeId: landscapeId,
                                      sid: gcpserver?.sid,
                                      key: "dbstorageSpace",
                                      value: validNumber,
                                    });
                                  }}
                                  onBlur={(event: any) => {
                                    const enteredStorageSpace = Number(
                                      event.target.value
                                    );

                                    const minimumStorageSpace = 10;
                                    if (
                                      enteredStorageSpace < minimumStorageSpace
                                    ) {
                                      setStorageWarning(
                                        t("errorMessages.warning") as string
                                      );
                                    } else {
                                      setStorageWarning("");
                                    }
                                  }}
                                />
                              </div>
                            )}
                          </td>

                          {/* //////////////////////////////////////////////////////////////////////////////////////////////////////// */}
                          {/* <td>
                            {dbinstanceda ? (
                              <div className="loader">
                                <ButtonLoader />
                              </div>
                            ) : (
                              <div>
                                <input
                                  className="form-control storage storageSpace"
                                  type="text"
                                  style={{
                                    border: storageWarning
                                      ? " 1px solid #F7C600"
                                      : "1px solid  #CBD5E0",
                                  }}
                                  value={
                                    gcpserver?.DbandAppsConfig?.storageSpace
                                  }
                                  onChange={(data) => {
                                    const validNumber =
                                      data.target.value.replace(/[^0-9]/g, "");

                                    handleChangeDBServer({
                                      id: 1,
                                      landscapeId: landscapeId,
                                      sid: gcpserver?.sid,
                                      key: "dbstorageSpace",
                                      value: validNumber,
                                    });
                                  }}
                                  onBlur={(event: any) => {
                                    const enteredStorageSpace = Number(
                                      event.target.value
                                    );
                                    const ramValue = gcpserver?.DbandAppsConfig
                                      ?.ram
                                      ? +gcpserver?.DbandAppsConfig?.ram
                                      : 0;
                                    const minimumStorageSpace = ramValue + 10;
                                    if (
                                      enteredStorageSpace < minimumStorageSpace
                                    ) {
                                      setStorageWarning(
                                        t("errorMessages.warning") as string
                                      );
                                    } else {
                                      setStorageWarning("");
                                    }
                                  }}
                                />
                              </div>
                            )}
                          </td>
                          <td>
                            <Form.Select
                              name="Storage Type"
                              value={gcpserver?.DbandAppsConfig?.storageType}
                             className="storage storageType"
                              onChange={(data) => {
                                const validNumber = data.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                                handleChangeDBServer({
                                  id: 1,
                                  landscapeId: landscapeId,
                                  sid: gcpserver?.sid,
                                  key: "dbstorageType",
                                  value: validNumber,
                                });
                              }}
                            >
                              {Object.entries(storageTypeOptions).map(
                                ([key, val]) => (
                                  <option key={String(key)} value={String(val)}>
                                    {val}
                                  </option>
                                )
                              )}
                            </Form.Select>
                          </td>
                          <td>
                            <Form.Select
                              name="Storage Code"
                              value={gcpserver?.DbandAppsConfig?.storageCode}
                              className="storage storageCode"
                              onChange={(data) => {
                                const dbSelectedValue = data?.target?.value;
                                setDbSelectedStorageCode(dbSelectedValue);
                                const selectedDbDisk =
                                  StorageData?.getDisksCode.find(
                                    (disk: any) => disk.code === dbSelectedValue
                                  );
                                const dbMaxThroughputValue = selectedDbDisk
                                  ? selectedDbDisk.max_throughput
                                  : "";
                                setDatabaseMaxThroughput(dbMaxThroughputValue);
                                handleChangeDBServer({
                                  id: 1,
                                  landscapeId: landscapeId,
                                  sid: gcpserver?.sid,
                                  key: "dbstorageCode",
                                  value: dbSelectedValue,
                                });
                              }}
                            >
                              {options.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </Form.Select>
                          </td> */}
                          <td>
                            <input
                              className="form-control storage dbOS"
                              type="text"
                              readOnly
                              value="SUSE"
                            />
                          </td>

                          <td>
                            <div>
                              <input
                                className="form-control storage dbCount"
                                type="text"
                                style={{
                                  border: dbHaCountErrors[gcpserver?.sid]
                                    ? "1px solid red"
                                    : "1px solid #CBD5E0",
                                }}
                                value={gcpserver?.DbandAppsConfig?.dbHaCountDR}
                                onChange={(data) => {
                                  handleChangeDBServer({
                                    id: 1,
                                    landscapeId: landscapeId,
                                    sid: gcpserver?.sid,
                                    key: "dbHaCountDR",
                                    value: data?.target?.value,
                                  });
                                }}
                                onKeyDown={(e) => allowOnlyNumbers(e)}
                                inputMode="numeric"
                              />
                            </div>
                          </td>
                          <td>
                            <div
                              onClick={() =>
                                requestDeleteSID({
                                  tiername: landscapeId,
                                  sid: gcpserver?.sid,
                                  sizingReportId: gcpserver?.id,
                                })
                              }
                            >
                              {delLoading ? (
                                <>
                                  <div
                                    className={`d-flex justify-content-center align-items-center`}
                                  >
                                    <Spinner
                                      as="span"
                                      animation="grow"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                      variant="primary"
                                    />
                                  </div>
                                </>
                              ) : (
                                <Button
                                  title="Delete"
                                  variant="outline-danger"
                                  type="button"
                                  size="sm"
                                  onClick={() => {
                                    setShowDeleteSID(true);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faTrashCan} />
                                </Button>
                              )}
                            </div>
                          </td>
                        </tr>
                        {gcpserver?.DbandAppsConfig?.AppServerConfigurations?.map(
                          (appData: any, index: any) => (
                            <tr style={{ verticalAlign: "middle" }}>
                              <td style={{ width: "75px" }}>
                                {t("pages.reports.dbServerSelection.app")}
                              </td>

                              <td>
                                <InstanceSelect
                                  ref={inputRef}
                                  isInvalid={
                                    appReportErrors[
                                      `${gcpserver.sid}-AppServer-${index}`
                                    ]?.backupInstanceError ?? false
                                  }
                                  context="dbInstance"
                                  id="applicationInstanceSelect"
                                  placeholder={
                                    t(
                                      "pages.reports.dbServerSelection.instance"
                                    ) as string
                                  }
                                  toolTipText="Tooltip Info"
                                  usage={UsageType.ANY}
                                  vendor={cloudVendor}
                                  region={primaryRegion}
                                  disabled={
                                    !cloudVendor ||
                                    !primaryRegion ||
                                    server?.showLoading ||
                                    gcpserver?.showLoading
                                  }
                                  value={
                                    cloudVendor === "GCP"
                                      ? appData?.backupAppServerInstance
                                      : appData?.backupAppServerInstance
                                  }
                                  onChange={(data) => {
                                    handleChangeDBServer({
                                      id: index,
                                      landscapeId: landscapeId,
                                      sid: gcpserver?.sid,
                                      key: "backupAppServerInstance",
                                      value: data,
                                    });
                                  }}
                                  options={appServerInstanceData}
                                />
                              </td>
                              <td>
                                <InstanceSelect
                                  ref={inputRef}
                                  isInvalid={
                                    appReportErrors[
                                      `${gcpserver.sid}-AppServer-${index}`
                                    ]?.backupInstanceError ?? false
                                  }
                                  context="cpu"
                                  id="applicationInstanceSelect"
                                  placeholder={
                                    t("pages.reports.quotation.cpu") as string
                                  }
                                  toolTipText="Tooltip Info"
                                  usage={UsageType.ANY}
                                  vendor={cloudVendor}
                                  region={primaryRegion}
                                  disabled={
                                    !cloudVendor ||
                                    !primaryRegion ||
                                    server?.showLoading ||
                                    gcpserver?.showLoading
                                  }
                                  //@ts-ignore
                                  value={appData?.cpuDR}
                                  onChange={(data) => {
                                    handleChangeDBServer({
                                      id: index,
                                      landscapeId: landscapeId,
                                      sid: gcpserver?.sid,
                                      key: "backupAppServerInstance",
                                      value: data,
                                    });
                                  }}
                                  options={appServerInstanceData}
                                />
                              </td>
                              <td>
                                <InstanceSelect
                                  ref={inputRef}
                                  isInvalid={
                                    appReportErrors[
                                      `${gcpserver.sid}-AppServer-${index}`
                                    ]?.backupInstanceError ?? false
                                  }
                                  context="ram"
                                  placeholder={
                                    t(
                                      "pages.reports.dbServerSelection.ram"
                                    ) as string
                                  }
                                  id="applicationInstanceSelect"
                                  toolTipText="Tooltip Info"
                                  usage={UsageType.ANY}
                                  vendor={cloudVendor}
                                  region={primaryRegion}
                                  disabled={
                                    !cloudVendor ||
                                    !primaryRegion ||
                                    server?.showLoading ||
                                    gcpserver?.showLoading
                                  }
                                  //@ts-ignore
                                  value={appData?.ramDR}
                                  onChange={(data) => {
                                    handleChangeDBServer({
                                      id: index,
                                      landscapeId: landscapeId,
                                      sid: gcpserver?.sid,
                                      key: "backupAppServerInstance",
                                      value: data,
                                    });
                                  }}
                                  options={appServerInstanceData}
                                />
                              </td>

                              {/* //////////////////////////////////////////////////////////////////////////// */}
                              <td>
                                <Form.Select
                                  name="Storage Type"
                                  value={`${
                                    gcpserver?.DbandAppsConfig
                                      ?.AppServerConfigurations[index]
                                      ?.storageTypeDR
                                  },${
                                    gcpserver?.DbandAppsConfig
                                      ?.AppServerConfigurations[index]
                                      ?.storageCodeDR || ""
                                  }`}
                                  className="storage storageType"
                                  onChange={(data) => {
                                    handleChangeDBServer({
                                      id: index,
                                      landscapeId: landscapeId,
                                      sid: gcpserver?.sid,
                                      key: "storageTypeDR",
                                      value: data?.target?.value,
                                    });
                                  }}
                                >
                                  <option value="">Select</option>
                                  {options.map((option) => (
                                    <option
                                      key={option.label}
                                      value={`${option.type},${option.code}`}
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                                </Form.Select>
                              </td>

                              <td>
                                <div>
                                  <input
                                    className="form-control storage storageSpace"
                                    type="text"
                                    style={{
                                      border: appStorageWarning
                                        ? " 1px solid #F7C600"
                                        : "1px solid  #CBD5E0",
                                      //border: "1px solid  #CBD5E0",
                                    }}
                                    value={appData?.storageSpaceDR}
                                    onChange={(data) => {
                                      const validNumber =
                                        data.target.value.replace(
                                          /[^0-9]/g,
                                          ""
                                        );
                                      handleChangeDBServer({
                                        id: index,
                                        landscapeId: landscapeId,
                                        sid: gcpserver?.sid,
                                        key: "storageSpaceDR",
                                        value: validNumber,
                                      });
                                    }}
                                    onBlur={(event: any) => {
                                      const enteredStorageSpace = Number(
                                        event.target.value
                                      );
                                      const minimumStorageSpace = 10;
                                      if (
                                        enteredStorageSpace <
                                        minimumStorageSpace
                                      ) {
                                        setAppStorageWarning(
                                          t("errorMessages.warning1") as string
                                        );
                                      } else {
                                        setAppStorageWarning("");
                                      }
                                    }}
                                  />
                                </div>
                              </td>

                              {/* //////////////////////////////////////////////////////////////////////////////// */}
                              {/* <td>
                                <div>
                                  <input
                                    className="form-control storage storageSpace"
                                    type="text"
                                    style={{
                                      border: appStorageWarning
                                        ? " 1px solid #F7C600"
                                        : "1px solid  #CBD5E0",
                                      //border: "1px solid  #CBD5E0",
                                    }}
                                    value={appData?.storageSpaceDR}
                                    onChange={(data) => {
                                      const validNumber =
                                        data.target.value.replace(
                                          /[^0-9]/g,
                                          ""
                                        );
                                      handleChangeDBServer({
                                        id: index,
                                        landscapeId: landscapeId,
                                        sid: gcpserver?.sid,
                                        key: "storageSpaceDR",
                                        value: validNumber,
                                      });
                                    }}
                                    onBlur={(event: any) => {
                                      const enteredStorageSpace = Number(
                                        event.target.value
                                      );
                                      const minimumStorageSpace = 10;
                                      if (
                                        enteredStorageSpace <
                                        minimumStorageSpace
                                      ) {
                                        setAppStorageWarning(
                                          t("errorMessages.warning1") as string
                                        );
                                      } else {
                                        setAppStorageWarning("");
                                      }
                                    }}
                                  />
                                </div>
                              </td>
                              <td>
                                <Form.Select
                                  name="Storage Type"
                                  value={appData?.storageTypeDR}
                                  className="storage storageType"
                                  onChange={(data) => {
                                    handleChangeDBServer({
                                      id: index,
                                      landscapeId: landscapeId,
                                      sid: gcpserver?.sid,
                                      key: "storageTypeDR",
                                      value: data?.target?.value,
                                    });
                                  }}
                                >
                                  {Object.entries(storageTypeOptions).map(
                                    ([key, val]) => (
                                      <option
                                        key={String(key)}
                                        value={String(val)}
                                      >
                                        {val}
                                      </option>
                                    )
                                  )}
                                </Form.Select>
                              </td>
                              <td>
                                <Form.Select
                                  name="Storage Code"
                                  value={appData?.storageCodeDR}
                                  className="storage storageCode"
                                  onChange={(data) => {
                                    const SelectedValue = data?.target?.value;
                                    setSelectedStorageCode(SelectedValue);
                                    const selectedDisk =
                                      StorageData?.getDisksCode.find(
                                        (disk: any) =>
                                          disk.code === SelectedValue
                                      );
                                    const maxThroughputValue = selectedDisk
                                      ? selectedDisk.max_throughput
                                      : "";
                                    setMaxThroughput(maxThroughputValue);
                                    handleChangeDBServer({
                                      id: index,
                                      landscapeId: landscapeId,
                                      sid: gcpserver?.sid,
                                      key: "storageCodeDR",
                                      value: SelectedValue,
                                    });
                                  }}
                                >
                                  {options.map((option) => (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                                </Form.Select>
                              </td> */}
                              <td>
                                <Form.Select
                                  name=" appDROperatingSystem"
                                  value={appData?.appDROperatingSystem}
                                  className="storage appDROperatingSystem"
                                  onChange={(data) => {
                                    handleChangeDBServer({
                                      id: index,
                                      landscapeId: landscapeId,
                                      sid: gcpserver?.sid,
                                      key: "appDROperatingSystem",
                                      value: data?.target?.value,
                                    });
                                  }}
                                >
                                  {cloudVendor === "GCP"
                                    ? Object.entries(GcpOperatingSystem).map(
                                        ([key, val]) => (
                                          <option key={val} value={val}>
                                            {val}
                                          </option>
                                        )
                                      )
                                    : Object.entries(OperatingSystem).map(
                                        ([key, val]) => (
                                          <option key={val} value={val}>
                                            {val}
                                          </option>
                                        )
                                      )}
                                </Form.Select>
                              </td>
                              <td>
                                <div>
                                  <input
                                    className="form-control storage dbCount"
                                    type="text"
                                    style={{
                                      border:
                                        !isAppCountDRValid &&
                                        appReportErrors[
                                          `${gcpserver.sid}-AppServer-${index}`
                                        ]?.drCountError
                                          ? "1px solid red"
                                          : "1px solid #CBD5E0",
                                      // border: "1px solid  #CBD5E0",
                                    }}
                                    value={appData?.appDRServerCount}
                                    onChange={(data) =>
                                      handleChangeDBServer({
                                        id: index,
                                        landscapeId: landscapeId,
                                        sid: gcpserver?.sid,
                                        key: "appDRServerCount",
                                        value: data?.target?.value,
                                      })
                                    }
                                    onKeyDown={(e) => allowOnlyNumbers(e)}
                                    inputMode="numeric"
                                  />
                                </div>
                              </td>
                              <td>
                                <div
                                  onClick={() =>
                                    requestDeleteItem({
                                      tiername: landscapeId,
                                      sid: gcpserver?.sid,
                                      sizingReportId: appData?.id,
                                      appindex: index,
                                    })
                                  }
                                >
                                  {delLoadings && Loadingapp === appData?.id ? (
                                    <>
                                      <div
                                        className={`d-flex justify-content-center align-items-center`}
                                      >
                                        <Spinner
                                          as="span"
                                          animation="grow"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                          variant="primary"
                                        />
                                      </div>
                                    </>
                                  ) : (
                                    <Button
                                      title="Delete"
                                      variant="outline-danger"
                                      type="button"
                                      size="sm"
                                      onClick={() =>
                                        setShowDeleteConfirmation(true)
                                      }
                                    >
                                      <FontAwesomeIcon icon={faTrashCan} />
                                    </Button>
                                  )}
                                </div>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  </Accordion.Body>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {storageWarning && (
                      <div
                        className="error-warning"
                        style={{ flex: 1, marginRight: "10px" }}
                      >
                        <strong>
                          <FontAwesomeIcon
                            icon={faTriangleExclamation}
                            className="error-icon-warning"
                          />{" "}
                          {t("Qutation.warning")}
                        </strong>
                        {t("errorMessages.warning")}
                      </div>
                    )}

                    {appStorageWarning && (
                      <div className="error-warning" style={{ flex: 1 }}>
                        <strong>
                          <FontAwesomeIcon
                            icon={faTriangleExclamation}
                            className="error-icon-warning"
                          />{" "}
                          {t("Qutation.warning")}
                        </strong>
                        {t("errorMessages.warning1")}
                      </div>
                    )}
                  </div>
                </Accordion.Item>
              </>
            ) : (
              <Accordion.Item eventKey="0">
                <Accordion.Header className="acordianHeader">
                  <div style={{ flex: 1 }} className="dashboard-font-Size">
                    {(() => {
                      const region = reportsData?.regions?.find(
                        (region: any) =>
                          region.id === Number(detailConfigs.primaryRegionId)
                      );

                      if (region) {
                        return region.code;
                      } else {
                        return "";
                      }
                    })()}

                    <span className="dashboard-font-Size dashboard">
                      {" "}
                      (Primary)
                    </span>
                  </div>

                  <CustomButton
                    variant="outline-primary"
                    type="button"
                    className="button-spacing dashboard-font-Size"
                    style={{ marginLeft: "auto", color: "white" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCopyAPPServer({
                        landscapeId: landscapeId,
                        sid: gcpserver?.sid,
                      });
                    }}
                  >
                    {t("pages.reports.dbServerSelection.addAppServer")}
                  </CustomButton>
                </Accordion.Header>
                <Accordion.Body>
                  <Table
                    className="table-responsive dbtable"
                    // responsive
                    style={{
                      margin: "2px",
                    }}
                  >
                    <TableHeader />
                    <tbody className="tableBody">
                      <tr style={{ verticalAlign: "middle" }}>
                        <td style={{ width: "75px" }}>
                          {t("pages.reports.instanceSelection.hanaDb")}
                        </td>
                        <td
                        // style={{ paddingTop: "18px" }}
                        >
                          {dbinstanceda ? (
                            <div className="loader">
                              <ButtonLoader />
                            </div> // Your loading indicator
                          ) : (
                            <InstanceSelect
                              ref={inputRef}
                              isInvalid={
                                !isInstanceValid && reportErrors[gcpserver.sid]
                              }
                              id="databaseInstanceSelect"
                              usage={UsageType.HANA_INSTANCE}
                              placeholder={
                                t(
                                  "pages.reports.dbServerSelection.instance"
                                ) as string
                              }
                              region={primaryRegion}
                              disabled={
                                !cloudVendor || !primaryRegion
                                // ||
                                // dbinstance === true
                              }
                              context="dbInstance"
                              isloading={dbinstanceda}
                              value={
                                cloudVendor === "GCP"
                                  ? gcpserver?.DbandAppsConfig
                                      ?.primaryDBServerInstance
                                  : gcpserver?.DbandAppsConfig
                                      ?.primaryDBServerInstance
                              }
                              onChange={(data) => {
                                handleChangeDBServer({
                                  id: 1,
                                  landscapeId: landscapeId,
                                  sid: gcpserver?.sid,
                                  key: "primaryDBServerInstance",
                                  value: data,
                                });
                                // setIsInstanceValid(true);
                              }}
                              options={instanceData}
                            />
                          )}
                        </td>
                        <td style={{ width: "80px" }}>
                          {dbinstanceda ? (
                            <div className="loader">
                              <ButtonLoader />
                            </div> // Your loading indicator
                          ) : (
                            <InstanceSelect
                              ref={inputRef}
                              isInvalid={
                                !isInstanceValid && reportErrors[gcpserver.sid]
                              }
                              context="cpu"
                              placeholder={
                                t("pages.reports.quotation.cpu") as string
                              }
                              id="vcpuInstanceSelect"
                              usage={UsageType.HANA_INSTANCE}
                              region={primaryRegion}
                              isloading={dbinstanceda}
                              disabled={!cloudVendor || !primaryRegion}
                              //@ts-ignore
                              value={gcpserver?.DbandAppsConfig?.cpu}
                              onChange={(data) => {
                                handleChangeDBServer({
                                  id: 1,
                                  landscapeId: landscapeId,
                                  sid: gcpserver?.sid,
                                  key: "primaryDBServerInstance",
                                  value: data,
                                });
                              }}
                              options={instanceData}
                            />
                          )}
                        </td>
                        <td>
                          {dbinstanceda ? (
                            <div className="loader">
                              <ButtonLoader />
                            </div>
                          ) : (
                            <InstanceSelect
                              ref={inputRef}
                              isInvalid={
                                !isInstanceValid && reportErrors[gcpserver.sid]
                              }
                              context="ram"
                              placeholder={
                                t(
                                  "pages.reports.dbServerSelection.ram"
                                ) as string
                              }
                              id="ramInstanceSelect"
                              toolTipText="Tooltip Info"
                              usage={UsageType.HANA_INSTANCE}
                              isloading={dbinstanceda}
                              region={primaryRegion}
                              disabled={!cloudVendor || !primaryRegion}
                              //@ts-ignore
                              value={gcpserver?.DbandAppsConfig?.ram}
                              onChange={(data: any) => {
                                handleChangeDBServer({
                                  id: 1,
                                  landscapeId: landscapeId,
                                  sid: gcpserver?.sid,
                                  key: "primaryDBServerInstance",
                                  value: data,
                                });
                              }}
                              options={instanceData}
                            />
                          )}
                        </td>
                        {/* /////////////////////////////////////////////////// */}
                        <td>
                          <Form.Select
                            name="Storage Type"
                            value={`${
                              gcpserver?.DbandAppsConfig?.storageType
                            },${gcpserver?.DbandAppsConfig?.storageCode || ""}`}
                            className=" storage storageType"
                            onChange={(data) => {
                              handleChangeDBServer({
                                id: 1,
                                landscapeId: landscapeId,
                                sid: gcpserver?.sid,
                                key: "dbstorageType",
                                value: data?.target?.value,
                              });
                            }}
                          >
                            {" "}
                            <option value="">Select</option>
                            {options.map((option) => (
                              <option
                                key={option.label}
                                value={`${option.type},${option.code}`}
                              >
                                {option.label}
                              </option>
                            ))}
                          </Form.Select>
                        </td>
                        <td>
                          {dbinstanceda ? (
                            <div className="loader">
                              <ButtonLoader />
                            </div>
                          ) : (
                            <div>
                              <input
                                className="form-control storage storageSpace"
                                type="text"
                                style={{
                                  border: storageWarning
                                    ? " 1px solid #F7C600"
                                    : "1px solid  #CBD5E0",
                                  // border: "1px solid  #CBD5E0",
                                }}
                                value={gcpserver?.DbandAppsConfig?.storageSpace}
                                onChange={(data) => {
                                  const validNumber = data.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                  handleChangeDBServer({
                                    id: 1,
                                    landscapeId: landscapeId,
                                    sid: gcpserver?.sid,
                                    key: "dbstorageSpace",
                                    value: validNumber,
                                  });
                                }}
                                onBlur={(event: any) => {
                                  const enteredStorageSpace = Number(
                                    event.target.value
                                  );

                                  const minimumStorageSpace = 10;
                                  if (
                                    enteredStorageSpace < minimumStorageSpace
                                  ) {
                                    setStorageWarning(
                                      t("errorMessages.warning") as string
                                    );
                                  } else {
                                    setStorageWarning("");
                                  }
                                }}
                              />
                            </div>
                          )}
                        </td>
                        {/* /////////////////////////////////////////////////// */}

                        {/* <td>
                          {dbinstanceda ? (
                            <div className="loader">
                              <ButtonLoader />
                            </div>
                          ) : (
                            <div>
                              <input
                                className="form-control storage storageSpace"
                                type="text"
                                style={{
                                  border: storageWarning
                                    ? " 1px solid ##F7C600"
                                    : "1px solid  #CBD5E0",
                                  // border: "1px solid  #CBD5E0",
                                }}
                                value={gcpserver?.DbandAppsConfig?.storageSpace}
                                onChange={(data) => {
                                  const validNumber = data.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                  handleChangeDBServer({
                                    id: 1,
                                    landscapeId: landscapeId,
                                    sid: gcpserver?.sid,
                                    key: "dbstorageSpace",
                                    value: validNumber,
                                  });
                                }}
                                onBlur={(event: any) => {
                                  const enteredStorageSpace = Number(
                                    event.target.value
                                  );
                                  const ramValue = gcpserver?.DbandAppsConfig
                                    ?.ram
                                    ? +gcpserver?.DbandAppsConfig?.ram
                                    : 0;
                                  const minimumStorageSpace = ramValue + 10;
                                  if (
                                    enteredStorageSpace < minimumStorageSpace
                                  ) {
                                    setStorageWarning(
                                      t("errorMessages.warning") as string
                                    );
                                  } else {
                                    setStorageWarning("");
                                  }
                                }}
                              />
                            </div>
                          )}
                        </td>

                        <td>
                          <Form.Select
                            name="Storage Type"
                            value={gcpserver?.DbandAppsConfig?.storageType}
                            className="storage storageType"
                            onChange={(data) => {
                              handleChangeDBServer({
                                id: 1,
                                landscapeId: landscapeId,
                                sid: gcpserver?.sid,
                                key: "dbstorageType",
                                value: data?.target?.value,
                              });
                            }}
                          >
                            {Object.entries(storageTypeOptions).map(
                              ([key, val]) => (
                                <option key={String(key)} value={String(val)}>
                                  {val}
                                </option>
                              )
                            )}
                          </Form.Select>
                        </td>
                        <td>
                          <Form.Select
                            name="Storage Code"
                            value={gcpserver?.DbandAppsConfig?.storageCode}
                            className="storage storageCode"
                            onChange={(data) => {
                              const dbSelectedValue = data?.target?.value;
                              setDbSelectedStorageCode(dbSelectedValue);
                              const selectedDbDisk =
                                StorageData?.getDisksCode.find(
                                  (disk: any) => disk.code === dbSelectedValue
                                );
                              const dbMaxThroughputValue = selectedDbDisk
                                ? selectedDbDisk.max_throughput
                                : "";
                              setDatabaseMaxThroughput(dbMaxThroughputValue);
                              handleChangeDBServer({
                                id: 1,
                                landscapeId: landscapeId,
                                sid: gcpserver?.sid,
                                key: "dbstorageCode",
                                value: dbSelectedValue,
                              });
                            }}
                          >
                            {options.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </Form.Select>
                        </td> */}
                        <td>
                          <input
                            className="form-control storage dbOS"
                            type="text"
                            readOnly
                            value="SUSE"
                          />
                        </td>
                        <td>
                          <div>
                            <input
                              className="form-control storage dbCount"
                              type="text"
                              ref={inputRef}
                              style={{
                                border: dbHaCountErrors[gcpserver?.sid]
                                  ? "1px solid red"
                                  : "1px solid #CBD5E0",
                              }}
                              value={gcpserver?.DbandAppsConfig?.dbHaCount}
                              onChange={(data) => {
                                handleChangeDBServer({
                                  id: 1,
                                  landscapeId: landscapeId,
                                  sid: gcpserver?.sid,
                                  key: "dbHaCount",
                                  value: data?.target?.value,
                                });
                              }}
                              onKeyDown={(e) => allowOnlyNumbers(e)}
                              inputMode="numeric"
                            />
                          </div>
                        </td>
                        <td>
                          <div
                            onClick={() =>
                              requestDeleteSID({
                                tiername: landscapeId,
                                sid: gcpserver?.sid,
                                sizingReportId: gcpserver?.id,
                              })
                            }
                          >
                            {delLoading ? (
                              <>
                                <div
                                  className={`d-flex justify-content-center align-items-center`}
                                >
                                  <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    variant="primary"
                                  />
                                </div>
                              </>
                            ) : (
                              <Button
                                title="Delete"
                                variant="outline-danger"
                                type="button"
                                size="sm"
                                onClick={() => {
                                  setShowDeleteSID(true);
                                }}
                              >
                                <FontAwesomeIcon icon={faTrashCan} />
                              </Button>
                            )}
                          </div>
                        </td>
                      </tr>
                      {gcpserver?.DbandAppsConfig?.AppServerConfigurations?.map(
                        (appData: any, index: any) => (
                          <tr style={{ verticalAlign: "middle" }}>
                            <td style={{ width: "75px" }}>
                              {t("pages.reports.dbServerSelection.app")}{" "}
                            </td>
                            <td>
                              <InstanceSelect
                                ref={inputRef}
                                isInvalid={
                                  appReportErrors[
                                    `${gcpserver.sid}-AppServer-${index}`
                                  ]?.instanceError ?? false
                                }
                                context="dbInstance"
                                id="applicationInstanceSelect"
                                toolTipText="Tooltip Info"
                                placeholder={
                                  t(
                                    "pages.reports.dbServerSelection.instance"
                                  ) as string
                                }
                                usage={UsageType.ANY}
                                vendor={cloudVendor}
                                region={primaryRegion}
                                disabled={
                                  !cloudVendor ||
                                  !primaryRegion ||
                                  server?.showLoading ||
                                  gcpserver?.showLoading
                                }
                                value={
                                  cloudVendor === "GCP"
                                    ? appData?.primaryAppServerInstance
                                    : appData?.primaryAppServerInstance
                                }
                                onChange={(data) => {
                                  handleChangeDBServer({
                                    id: index,
                                    landscapeId: landscapeId,
                                    sid: gcpserver?.sid,
                                    key: "primaryAppServerInstance",
                                    value: data,
                                  });
                                }}
                                options={appServerInstanceData}
                              />
                            </td>
                            <td>
                              <InstanceSelect
                                ref={inputRef}
                                isInvalid={
                                  appReportErrors[
                                    `${gcpserver.sid}-AppServer-${index}`
                                  ]?.instanceError ?? false
                                }
                                // isInvalid={appReportErrors[gcpserver.sid] || !isAppServerInstanceValid}
                                context="cpu"
                                id="applicationInstanceSelect"
                                toolTipText="Tooltip Info"
                                usage={UsageType.ANY}
                                vendor={cloudVendor}
                                placeholder={
                                  t("pages.reports.quotation.cpu") as string
                                }
                                region={primaryRegion}
                                disabled={
                                  !cloudVendor ||
                                  !primaryRegion ||
                                  server?.showLoading ||
                                  gcpserver?.showLoading
                                }
                                //@ts-ignore
                                value={appData?.cpu}
                                onChange={(data) => {
                                  handleChangeDBServer({
                                    id: index,
                                    landscapeId: landscapeId,
                                    sid: gcpserver?.sid,
                                    key: "primaryAppServerInstance",
                                    value: data,
                                  });
                                }}
                                options={appServerInstanceData}
                              />
                            </td>
                            <td>
                              <InstanceSelect
                                ref={inputRef}
                                isInvalid={
                                  appReportErrors[
                                    `${gcpserver.sid}-AppServer-${index}`
                                  ]?.instanceError ?? false
                                }
                                context="ram"
                                placeholder={
                                  t(
                                    "pages.reports.dbServerSelection.ram"
                                  ) as string
                                }
                                id="applicationInstanceSelect"
                                toolTipText="Tooltip Info"
                                usage={UsageType.ANY}
                                vendor={cloudVendor}
                                region={primaryRegion}
                                disabled={
                                  !cloudVendor ||
                                  !primaryRegion ||
                                  server?.showLoading ||
                                  gcpserver?.showLoading
                                }
                                //@ts-ignore
                                value={appData?.ram}
                                onChange={(data) => {
                                  handleChangeDBServer({
                                    id: index,
                                    landscapeId: landscapeId,
                                    sid: gcpserver?.sid,
                                    key: "primaryAppServerInstance",
                                    value: data,
                                  });
                                }}
                                options={appServerInstanceData}
                              />
                            </td>
                            <td>
                              <Form.Select
                                name="Storage Type"
                                value={`${
                                  gcpserver?.DbandAppsConfig
                                    ?.AppServerConfigurations[index]
                                    ?.storageType
                                },${
                                  gcpserver?.DbandAppsConfig
                                    ?.AppServerConfigurations[index]
                                    ?.storageCode || ""
                                }`}
                                className=" storage storageType"
                                onChange={(data) => {
                                  handleChangeDBServer({
                                    id: index,
                                    landscapeId: landscapeId,
                                    sid: gcpserver?.sid,
                                    key: "storageType",
                                    value: data?.target?.value,
                                  });
                                }}
                              >
                                <option value="">Select</option>
                                {options.map((option) => (
                                  <option
                                    key={option.label}
                                    value={`${option.type},${option.code}`}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </Form.Select>
                            </td>
                            <td>
                              {dbinstanceda ? (
                                <div className="loader">
                                  <ButtonLoader />
                                </div>
                              ) : (
                                <div>
                                  <input
                                    className="form-control storage storageSpace"
                                    type="text"
                                    style={{
                                      border: storageWarning
                                        ? " 1px solid #F7C600"
                                        : "1px solid  #CBD5E0",
                                      // border: "1px solid  #CBD5E0",
                                    }}
                                    value={appData?.storageSpace}
                                    onChange={(data) => {
                                      const validNumber =
                                        data.target.value.replace(
                                          /[^0-9]/g,
                                          ""
                                        );
                                      handleChangeDBServer({
                                        id: index,
                                        landscapeId: landscapeId,
                                        sid: gcpserver?.sid,
                                        key: "storageSpace",
                                        value: validNumber,
                                      });
                                    }}
                                    onBlur={(event: any) => {
                                      const enteredStorageSpace = Number(
                                        event.target.value
                                      );
                                      const minimumStorageSpace = 10;
                                      if (
                                        enteredStorageSpace <
                                        minimumStorageSpace
                                      ) {
                                        setAppStorageWarning(
                                          t("errorMessages.warning1") as string
                                        );
                                      } else {
                                        setAppStorageWarning("");
                                      }
                                    }}
                                  />
                                </div>
                              )}
                            </td>
                            {/* <td>
                              <div>
                                <input
                                  className="form-control storage storageSpace"
                                  type="text"
                                  style={{
                                    //border: "1px solid  #CBD5E0",
                                    border: appStorageWarning
                                      ? " 1px solid #F7C600"
                                      : "1px solid  #CBD5E0",
                                  }}
                                  value={appData?.storageSpace}
                                  onChange={(data) => {
                                    const validNumber =
                                      data.target.value.replace(/[^0-9]/g, "");
                                    handleChangeDBServer({
                                      id: index,
                                      landscapeId: landscapeId,
                                      sid: gcpserver?.sid,
                                      key: "storageSpace",
                                      value: validNumber,
                                    });
                                  }}
                                  onBlur={(event: any) => {
                                    const enteredStorageSpace = Number(
                                      event.target.value
                                    );
                                    const minimumStorageSpace = 10;
                                    if (
                                      enteredStorageSpace < minimumStorageSpace
                                    ) {
                                      setAppStorageWarning(
                                        t("errorMessages.warning1") as string
                                      );
                                    } else {
                                      setAppStorageWarning("");
                                    }
                                  }}
                                />
                              </div>
                            </td>
                            <td>
                              <Form.Select
                                name="Storage Type"
                                value={appData?.storageType}
                                className="storage storageType"
                                onChange={(data) => {
                                  handleChangeDBServer({
                                    id: index,
                                    landscapeId: landscapeId,
                                    sid: gcpserver?.sid,
                                    key: "storageType",
                                    value: data?.target?.value,
                                  });
                                }}
                              >
                                {Object.entries(storageTypeOptions).map(
                                  ([key, val]) => (
                                    <option
                                      key={String(key)}
                                      value={String(val)}
                                    >
                                      {val}
                                    </option>
                                  )
                                )}
                              </Form.Select>
                            </td>
                            <td>
                              <Form.Select
                                name="Storage Code"
                                value={appData?.storageCode}
                                className="storage storageCode"
                                onChange={(data) => {
                                  const SelectedValue = data?.target?.value;
                                  setSelectedStorageCode(SelectedValue);
                                  const selectedDisk =
                                    StorageData?.getDisksCode.find(
                                      (disk: any) => disk.code === SelectedValue
                                    );
                                  const maxThroughputValue = selectedDisk
                                    ? selectedDisk.max_throughput
                                    : "";
                                  setMaxThroughput(maxThroughputValue);
                                  handleChangeDBServer({
                                    id: index,
                                    landscapeId: landscapeId,
                                    sid: gcpserver?.sid,
                                    key: "storageCode",
                                    value: SelectedValue,
                                  });
                                }}
                              >
                                {options.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </Form.Select>
                            </td> */}
                            <td>
                              <Form.Select
                                name="operatingSystem"
                                value={appData?.appOperatingSystem}
                                className="storage operatingSystem"
                                onChange={(data) => {
                                  handleChangeDBServer({
                                    id: index,
                                    landscapeId: landscapeId,
                                    sid: gcpserver?.sid,
                                    key: "appOperatingSystem",
                                    value: data?.target?.value,
                                  });
                                }}
                              >
                                {cloudVendor === "GCP"
                                  ? Object.entries(GcpOperatingSystem).map(
                                      ([key, val]) => (
                                        <option key={val} value={val}>
                                          {val}
                                        </option>
                                      )
                                    )
                                  : Object.entries(OperatingSystem).map(
                                      ([key, val]) => (
                                        <option key={val} value={val}>
                                          {val}
                                        </option>
                                      )
                                    )}
                              </Form.Select>
                            </td>
                            <td>
                              <div>
                                <input
                                  className="form-control storage dbCount"
                                  type="text"
                                  style={{
                                    border: appReportErrors[
                                      `${gcpserver.sid}-AppServer-${index}`
                                    ]?.countError
                                      ? "1px solid red"
                                      : "1px solid #CBD5E0",
                                    // border: appCountErrors[gcpserver?.sid]  ? "1px solid red" : "1px solid #CBD5E0",
                                  }}
                                  value={appData?.appServerCount}
                                  onChange={(data) =>
                                    handleChangeDBServer({
                                      id: index,
                                      landscapeId: landscapeId,
                                      sid: gcpserver?.sid,
                                      key: "appServerCount",
                                      value: data?.target?.value,
                                    })
                                  }
                                  onKeyDown={(e) => allowOnlyNumbers(e)}
                                  inputMode="numeric"
                                />
                              </div>
                            </td>
                            <td>
                              <div
                                onClick={() =>
                                  requestDeleteItem({
                                    tiername: landscapeId,
                                    sid: gcpserver?.sid,
                                    sizingReportId: appData?.id,
                                    appindex: index,
                                  })
                                }
                              >
                                {delLoadings && Loadingapp === appData?.id ? (
                                  <>
                                    <div
                                      className={`d-flex justify-content-center align-items-center`}
                                    >
                                      <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        variant="primary"
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <Button
                                    title="Delete"
                                    variant="outline-danger"
                                    type="button"
                                    size="sm"
                                    onClick={(event: any) => {
                                      setShowDeleteConfirmation(true);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faTrashCan} />
                                  </Button>
                                )}
                              </div>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                </Accordion.Body>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {storageWarning && (
                    <div
                      className="error-warning"
                      style={{ flex: 1, marginRight: "10px" }}
                    >
                      <strong>
                        <FontAwesomeIcon
                          icon={faTriangleExclamation}
                          className="error-icon-warning"
                        />{" "}
                        {t("Qutation.warning")}
                      </strong>
                      {t("errorMessages.warning")}
                    </div>
                  )}

                  {appStorageWarning && (
                    <div className="error-warning" style={{ flex: 1 }}>
                      <strong>
                        <FontAwesomeIcon
                          icon={faTriangleExclamation}
                          className="error-icon-warning"
                        />{" "}
                        {t("Qutation.warning")}
                      </strong>
                      {t("errorMessages.warning1")}
                    </div>
                  )}
                </div>
              </Accordion.Item>
            )}
          </Accordion>
        </div>

        <DeleteModal
          title={t("pages.reports.dbServerSelection.instance")}
          showModal={showDeleteConfirmation}
          closeModal={() => setShowDeleteConfirmation(false)}
          handleDelete={() => {
            //@ts-ignore
            handleDeleteAPPServer(itemToDelete);
            setShowDeleteConfirmation(false);
          }}
          loading={false}
        />
      </div>
      {showDeleteSIDModal && (
        <Modal
          show={showDeleteSIDModal}
          onHide={() => {
            setShowDeleteSIDModal(false);
            setShowDeleteSID(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title> {t("delete-modals.deletetitle")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{t("delete-modals.deletemsg")}</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setShowDeleteSIDModal(false);
                setShowDeleteSID(false);
              }}
            >
              {t("delete-modals.no")}
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                //@ts-ignore
                handleDeleteSID(SIDToDelete);
                setShowDeleteSIDModal(false);
                setShowDeleteSID(false);
              }}
            >
              {t("delete-modals.delete")}
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {showDeleteSID && (
        <Modal
          show={showDeleteSID}
          onHide={() => {
            setShowDeleteSID(false);
            setShowDeleteSIDModal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("delete-modals.deletetitle")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{t("delete-modals.deletemsg1")}</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setShowDeleteSID(false);
                setShowDeleteSIDModal(false);
              }}
            >
              {t("delete-modals.no")}
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                //@ts-ignore
                handleDeleteSID(SIDToDelete);
                setShowDeleteSID(false);
                setShowDeleteSIDModal(false);
              }}
            >
              {t("delete-modals.delete")}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
export default DbServerSelection;
