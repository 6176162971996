import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  FormControlProps,
  Form,
  InputGroup,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";

type CustomInputProps = FormControlProps & {
  controlId?: string;
  formGroupClassName?: string;
  label?: any;
  type: string;
  errorMessage?: string | TrustedHTML;
  hookForm?: any;
  maxLength?: number;
  cols?: string;
  rows?: string;
  inputGroupText?: string;
  name?: string; // Add the name prop for React Hook Form
  onClickValidation?: React.MouseEventHandler<HTMLDivElement> | undefined;
  toolTipText?: string;
  onChange?: React.ChangeEventHandler<any> | undefined;
  ref?:any;
  aboutusEmail?: boolean;
};

const CustomInput: React.FC<CustomInputProps> = ({
  controlId,
  formGroupClassName,
  label,
  type,
  // className,
  errorMessage,
  hookForm,
  maxLength,
  className,
  cols,
  rows,
  inputGroupText,
  name, // Include the name prop for React Hook Form
  onClickValidation,
  toolTipText,
  onChange,
  aboutusEmail,
  ...props
}) => {
  return (
    <Form.Group className={formGroupClassName} controlId={controlId}>
      {label && (
        <>
          <div className="options-container">
            <Form.Label>{label}</Form.Label>

            {/* <OverlayTrigger
                  trigger={['hover', 'focus']}
                  overlay={
                     <Popover>
                        <Popover.Header as='h3'></Popover.Header>
                        <Popover.Body>
                           {toolTipText}
                        </Popover.Body>
                     </Popover>
                  }
               >
                  <span>
                     <FontAwesomeIcon
                        icon={faInfoCircle}
                        style={{
                           cursor: "pointer",
                           marginLeft: "4px",
                           color: "#236ee8"
                        }}
                     />
                  </span>
               </OverlayTrigger> */}
          </div>
        </>
      )}
      {inputGroupText ? (
        <InputGroup>
          <>
            <Form.Control
              type={type}
              {...props}
              {...hookForm}
              className={aboutusEmail? className:''}
              name={controlId} // Pass the name prop for React Hook Form
              maxLength={maxLength}
              cols={cols}
              rows={rows}
              onChange={onChange}
            />
            <InputGroup.Text>{inputGroupText}</InputGroup.Text>
            <Form.Control.Feedback type="invalid">
              {/* {errorMessage} */}
              <div
                onClick={onClickValidation}
                dangerouslySetInnerHTML={{
                  __html: errorMessage as string | TrustedHTML,
                }}
              />
            </Form.Control.Feedback>
          </>
        </InputGroup>
      ) : (
        <>
          <Form.Control
            type={type}
            {...props}
            {...hookForm}
            name={controlId} // Pass the name prop for React Hook Form
            maxLength={maxLength}
            cols={cols}
            rows={rows}
            onChange={onChange}
            className={`custom-input-placeholder ${aboutusEmail? 'form-email-about': ''} `}
            
          />
          <Form.Control.Feedback type="invalid">
            {/* {errorMessage} */}
            <div
              onClick={onClickValidation}
              dangerouslySetInnerHTML={{
                __html: errorMessage as string | TrustedHTML,
              }}
            />
          </Form.Control.Feedback>
        </>
      )}
    </Form.Group>
  );
};

export default CustomInput;
