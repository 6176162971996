import { faCheck, faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, FC, ChangeEvent, KeyboardEvent, Dispatch, SetStateAction, useEffect } from 'react';
import { Button, InputGroup } from 'react-bootstrap';
import CustomInput from '../custom-input';
import { APP_ROUTES } from 'router/routes';
import { useNavigate } from 'react-router-dom';
import {  useQuery } from '@apollo/client';
import {GET_Container } from 'graphql/queries';

interface InlineEditProps {
   value: string;
   maxlength?: number;
   onChange: (value: string) => void;
   defaultValue?: string;
   editable?: boolean;
   setEditable: Dispatch<SetStateAction<boolean>>
   setReportData?: any;
   reportData?: any;
   reportId: string
   isCompleted:number

}
// TODO: Need to add required support
const InlineEdit: FC<InlineEditProps> = ({ value, onChange, defaultValue, maxlength, editable,
   setEditable, setReportData, reportData, reportId,isCompleted }) => {
   const [editingValue, setEditingValue] = useState<string>(value);
   const navigate = useNavigate();

   const onToggleEditClose = () => {
      setReportData(reportData?.map((report: any, index: number) => {
         if (report.id === reportId) {
            Object.assign(report, { isEdit: false })
            return report;
         }
         return report;
      }))
      if (editable) {
         setEditingValue(value);
      }
   }

   const onInputChange = (event: ChangeEvent<HTMLInputElement>) => setEditingValue(event.target.value);
   const onSubmit = () => {
      if (value !== editingValue) {
         onChange(editingValue)
      };
      onToggleEditClose();
   }
   const { loading:pageloading, data:containerdata } = useQuery(GET_Container , {

      variables: { cid:reportId },
      fetchPolicy: "no-cache",
    });
   
   
   const editReport = () => {
       const ReportContainerData= containerdata?.getContainerReports;
       const storageData = {
         hasGeneratedReport: true,
         cid: reportId,
     };
 
     // Store the data in local storage
     localStorage.setItem('reportGenerationState', JSON.stringify(storageData));
      // navigate(`${APP_ROUTES.EDITREPORT.replace(':id', reportId)}`);
      navigate(`${APP_ROUTES.EDITREPORT.replace(':id', reportId)}`, { state: { isCompleted,ReportContainerData } });
   };


   useEffect(() => {
      if (editable) {
         setEditingValue(value);
      }
   }, [editable])

   const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
         onSubmit();
      } else if (event.key === "Escape") {
         onToggleEditClose();
      }
   }

   if (!editable) {
      return <p className='cursor-pointer text-primary' onClick={editReport}>{value ? value : defaultValue}</p>
   }

   return (
      <>
         <InputGroup className="form-inline">
            <CustomInput
               name='name'
               controlId="name"
               type="text"
               aria-label="Field name"
               value={editingValue}
               maxLength={maxlength ?? 100}
               onKeyDown={onKeyDown}
               onChange={onInputChange}
            />

            <Button variant="outline-primary" size="sm" onClick={onSubmit}>
               <FontAwesomeIcon icon={faCheck} />
            </Button>{' '}
            <Button variant="outline-danger" type="button" size="sm" onClick={onToggleEditClose}>
               <FontAwesomeIcon icon={faRemove} />
            </Button>
         </InputGroup >

      </>
   );
};

export default InlineEdit;