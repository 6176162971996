import {
  ApolloClient,
  ApolloProvider,
  from,
  InMemoryCache,
} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {onError} from '@apollo/client/link/error';
import {createUploadLink} from 'apollo-upload-client';
import {toast} from 'react-toastify';
import {APP_ROUTES} from 'router/routes';
import {UserHelper} from 'utils';

import {API_URL} from 'config/environment.config';


const errorLink = onError(({graphQLErrors, networkError}) => {
  if (graphQLErrors) {
    // Handle 401
    const isUnauthorized = graphQLErrors.some(
      (e) => (e.extensions || {}).statusCode === 401
    );
    if (isUnauthorized) {
      UserHelper.logoutUser();
      window.location.href = APP_ROUTES.HOME;
      return;
    }

    graphQLErrors.forEach(({message, extensions}: any) => {
      const errorMessage = extensions.message ? extensions.message : message;
        // Check if the error is "UserNotExist" and handle it differently
        if (extensions.code === 'INTERNAL_SERVER_ERROR' && message === 'UserNotExist') {
         // Handle this error differently, don't display a toaster or take other action.
         return;
       }
      toast.error(errorMessage);
      console.log(
        `[GraphQL error]: Extension Message: ${message}, Extension Message: ${
          extensions?.message
        }, Path: ${JSON.stringify(extensions?.exception)}`
      );
    });
  }
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const uploadLink = createUploadLink({
  uri: `${API_URL}`,
});

const authLink = setContext((_, {headers}) => {
  const token = UserHelper.getToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([errorLink, authLink.concat(authLink).concat(uploadLink)]),
});

export {client, ApolloProvider};
