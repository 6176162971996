import React, { useState, useRef } from 'react';
import { Button, Typography, Box, Stepper, Step, StepLabel } from '@mui/material';
import { useMutation } from '@apollo/client';
import { CREATESERVERINCATALOGUE, CREATESERVERCATALOGUESIZE_STEP2 } from '../../graphql/mutations/server-catalogue.mutations';
import Step1Form, { Step1FormRef } from './catalogue-step1';
import Step2Form, { Step2FormRef } from './catalogue-step2';
import Step3Form from './catalogue-step3';
import { Step1FormData, Step2FormData, Step3FormData } from '../../entities/models/catalogue.model';
import { ToasterHelper } from 'utils/toaster.helper';

const CatalogueCreate = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [catalogueId, setCatalogueId] = useState('');
  const [catalogueSizeId, setCatalogueSizeId] = useState('');
  const [step1Data, setStep1Data] = useState<Step1FormData | null>(null);
  const [step2Data, setStep2Data] = useState<Step2FormData[]>([]);
  const step1Ref = useRef<Step1FormRef>(null);
  const step2Ref = useRef<Step2FormRef>(null);
  const [systemTiers, setSystemTiers] = useState<string[]>([]); // Lifted state for systemTiers

  const steps = ['Service Information', 'Database Details'];

  const [createServerInCatalogue] = useMutation(CREATESERVERINCATALOGUE, {
    onCompleted: (data) => {
      console.log('Server catalogue created successfully:', data);
      ToasterHelper.success(`${data.createServerCatalogue.message}`);
      setCatalogueId(data.createServerCatalogue.id);
      handleNext();
    },
    onError: (error) => {
      console.error('Error creating server catalogue:', error);
      ToasterHelper.error('Error creating server catalogue');
    },
  });

  const [createServerCatalogueSize] = useMutation(CREATESERVERCATALOGUESIZE_STEP2, {
    onCompleted: (data) => {
      console.log('Server catalogue size created successfully:', data);
      ToasterHelper.success('Server catalogue size created successfully');
      setCatalogueSizeId(data.createServerCatalogueSize.id);
      handleNext();
    },
    onError: (error) => {
      console.error('Error creating server catalogue size:', error);
      ToasterHelper.error('Error creating server catalogue size');
    },
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep2Submit = async (data: Step2FormData) => {
    setStep2Data([...step2Data, data]);
    try {
      await createServerCatalogueSize({
        variables: {
          input: {
            catalogueId: catalogueId,
            databaseType: data.databaseType,
            size: data.size,
          },
        },
      });
    } catch (error) {
      console.error('Error submitting step 2:', error);
    }
  };

  const handleButtonClick = async () => {
    if (activeStep === 0) {
      step1Ref.current?.submitForm();
      const step1FormData = step1Ref.current?.getFormData();
      if (step1FormData) {
        interface Input {
          serviceName: string;
          slaEligibility: string;
          catalogueId?: string;
          systemTiers: string[];
        }
        setStep1Data(step1FormData);
        try {
          const input: Input = {
            serviceName: step1FormData.serviceName,
            slaEligibility: step1FormData.slaEligibility,
            systemTiers: systemTiers, // Use lifted state
          };

          // If catalogueId exists, include it in the input
          if (catalogueId) {
            input.catalogueId = catalogueId;
          }

          await createServerInCatalogue({
            variables: { input },
          });
        } catch (error) {
          console.error('Error submitting step 1:', error);
          return;
        }
      }
    } else if (activeStep === 1) {
      // Handle Step 2 submission logic here
    }
  };

  return (
    <Box sx={{ 
      width: '100%', 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      minHeight: '100vh' 
    }}>
      <Box sx={{ width: '70%', maxWidth: '1000px' }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ mt: 4 }}>
          {activeStep === 0 && <Step1Form ref={step1Ref} initialData={step1Data || undefined} systemTiers={systemTiers} setSystemTiers={setSystemTiers} />}
          {activeStep === 1 && <Step2Form ref={step2Ref} onSubmit={handleStep2Submit} catalogueId={catalogueId} systemTiers={systemTiers}/>}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button disabled={activeStep === 0} onClick={handleBack}>
              Back
            </Button>
            <Button 
              variant="contained" 
              onClick={handleButtonClick}
            >
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CatalogueCreate;
