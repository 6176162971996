
// import React, { ChangeEvent, useState } from 'react';
// import { Dropdown } from 'react-bootstrap';
// import { StorageHelper, StorageKeys } from 'utils';
// import i18n, { defaultLanguage } from 'i18n';
// import Usa from 'assets/icons/Usa';
// import Japan from 'assets/icons/Japan';
// import Germany from 'assets/icons/Germany';

// const LanguageSelector = () => {
//   const currentLocale = StorageHelper.get(StorageKeys.Language) || defaultLanguage;
//   const [language, setLanguage] = useState(currentLocale);

//   // Languages with flags as components
//   const languages = [
//     { label: "English", value: "en", flag: Usa },
//     { label: "日本語", value: "ja", flag: Japan },
//     { label: "Deutsch", value: "de", flag: Germany }
//   ];

//   const handleChangeLocale = (value:any) => {
//     setLanguage(value);
//     StorageHelper.set(StorageKeys.Language, value);
//     i18n.changeLanguage(value);
//   };

//   // Get the current language object
//   const currentLanguage = languages.find((lang) => lang.value === language);

//   // Flag component for the current language
//   const FlagIcon = currentLanguage ? currentLanguage.flag : null;

//   return (
//     <Dropdown onSelect={handleChangeLocale}>
//       <Dropdown.Toggle 
//         variant="secondary" 
//         id="dropdown-language-selector" 
//         className="rounded-pill" 
//         style={{ backgroundColor: "#343434", border: "1px solid #343434", paddingRight:"15px" }}
//       >
//         {FlagIcon && <FlagIcon  />}
//       <span style={{marginLeft:'10px'}}> {currentLanguage ? currentLanguage.label : null}</span>  
//       </Dropdown.Toggle>

//       <Dropdown.Menu className="custom-dropdown-menu" style={{ backgroundColor: "#343434" }}>
//         {languages.map((lang, idx) => (
//           <Dropdown.Item 
//             key={idx} 
//             eventKey={lang.value} 
//             style={{ color: 'gray' }}
//           >
//             {React.createElement(lang.flag, { style: { width: '20px', marginRight: '10px' } })}
//            <span style={{marginLeft:'20px'}}>{lang.label}</span> 
//           </Dropdown.Item>
//         ))}
//       </Dropdown.Menu>
//     </Dropdown>
//   );
// };

// export default LanguageSelector;

import React, { ChangeEvent, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { StorageHelper, StorageKeys } from 'utils';
import i18n, { defaultLanguage } from 'i18n';
import Usa from 'assets/icons/Usa';
import Japan from 'assets/icons/Japan';
import Germany from 'assets/icons/Germany';
import { useLocation } from 'react-router-dom';

const LanguageSelector = () => {
    const location = useLocation();
    const IsHome = location.pathname === '/';
  const currentLocale = StorageHelper.get(StorageKeys.Language) || defaultLanguage;
  const [language, setLanguage] = useState(currentLocale);

  // Languages with flags as components
  const languages = [
    { label: "English", value: "en", flag: Usa },
    { label: "日本語", value: "ja", flag: Japan },
    { label: "Deutsch", value: "de", flag: Germany }
  ];

  const handleChangeLocale = (value:any) => {
    setLanguage(value);
    StorageHelper.set(StorageKeys.Language, value);
    i18n.changeLanguage(value);
  };

  // Get the current language object
  const currentLanguage = languages.find((lang) => lang.value === language);

  // Flag component for the current language
  const FlagIcon = currentLanguage ? currentLanguage.flag : null;

  return (
    <Dropdown onSelect={handleChangeLocale}>
      <Dropdown.Toggle 
        variant="secondary" 
        id="dropdown-language-selector" 
        className="rounded-pill" 
        // style={{ backgroundColor: "#343434", border: "1px solid #343434", paddingRight:"15px" }}
        style={{ 
            backgroundColor: IsHome ? "#161616" : "#f3f3f3", 
            border:IsHome? "1px solid #161616":"#f3f3f3", 
            color: IsHome? "white":"black",
            paddingRight: "15px"
          }}
      >
        {FlagIcon && <FlagIcon  />}
      <span style={{marginLeft:'10px'}}> {currentLanguage ? currentLanguage.label : null}</span>  
      </Dropdown.Toggle>

      <Dropdown.Menu className="custom-dropdown-menu" style={{  backgroundColor: IsHome ? "#161616" : "#f3f3f3",  }}>
        {languages.map((lang, idx) => (
          <Dropdown.Item 
            key={idx} 
            eventKey={lang.value} 
            className="custom-dropdown-item"
            style={ { color: IsHome ? "#7f8389" : "black"} }
          >
            {React.createElement(lang.flag, { style: { width: '20px', marginRight: '10px' }})}
           <span style={{marginLeft:'20px'}}>{lang.label}</span> 
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguageSelector;