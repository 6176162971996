const Japan = () => {
  return (
    <svg
      id="Layer_1"
      enableBackground="new 0 0 512 512"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      width={"30px"}
      style={{ paddingRight: "0px",marginLeft:'-7px'  }}
    >
      <circle cx="256" cy="256" fill="#f0f0f0" r="256" />
      <circle cx="256" cy="256" fill="#d80027" r="111.304" />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </svg>
  );
};
export default Japan;
