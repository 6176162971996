import { useMutation, useQuery } from "@apollo/client";
import { faTrash, faCodeCompare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DefaultConfig, DeleteModal, InlineEdit, Loader } from "components";
import { APP_ROUTES } from "router/routes";
import ButtonLoader from "components/ui-elements/loader/buttonLoader";
import {
  DELETE_REPORT,
  UPDATE_REPORT_NAME,
  Create_Container,
} from "graphql/mutations";
import { GET_CONTAINER_DATA, GET_DETAILINFO_CATALOGUE } from "graphql/queries";
import { useTranslate } from "hooks";
import { formatAmount } from "pages/edit-report/quotation-display";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GET_Container } from "graphql/queries";
import { Button, Pagination, Table } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import {
  StorageHelper,
  StorageKeys,
  ToasterHelper,
  UserHelper,
  formatTimestamp,
} from "utils";
import { Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import inputSearch from "../../assets/images/input-search.png";
import Sidebar from "components/layouts/main-layout/SideBar";

type ReportOverview = {
  createdAt: string;
  id: string;
  memoryRequirement: number;
  memoryRequirementAfterOptimization: number;
  memoryRequirementForShadowInstance: number;
  sapsCategory: string;
  sid: string;
  sizingReportFile: string;
  updatedAt: string;
  reportModifiedAt: string;
  completedAt: string;
  amount: number;
  isCompleted: boolean;
  isEdit: any;
  containerName: string;

  sids: string[];
  totalAmount: number;
  paymentPlan: string;
  platform: string;
  status: string;
  primaryRegionId: number;
};

type Props = {
  itemsPerPage?: number;
};

const SapRiseDetailPage: React.FC<Props> = ({ itemsPerPage = 10 }) => {
  const t = useTranslate();
  const location = useLocation();
  var detail_id = location?.state?.detail_id;
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");
  const [sortColumn, setSortColumn] = useState<string>("createdAt");
  const [reportToDelete, setReportToDelete] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [reportData, setReportData] = useState<ReportOverview[]>([]);
  const [editable, setEditable] = useState<boolean>(false);
  const [editingItemId, setEditingItemId] = useState<string | null>(null);
  const [showDefaultConfig, setShowDefaultConfig] = useState(false);
  const [repoData, setRepoData] = useState("");
  const [createRepo, setCreateRepo] = useState(false);
  const reportsData = UserHelper.getRegions();
  const [isLoading, setIsLoading] = useState(true);
  const [isProd, setIsProd] = useState(true);
  const { loading, data, refetch } = useQuery(GET_CONTAINER_DATA, {
    fetchPolicy: "no-cache",
  });

  const { data: detailInfo } = useQuery(GET_DETAILINFO_CATALOGUE, {
    variables: {
      catalogueId: detail_id,
    },
    skip: false,
    onCompleted: async (data) => {
      setIsLoading(false);
    },

    onError: async (error) => {
      console.log(error);
    },
  });

  console.log("catalogureList == ", detailInfo?.getCatalogueDetail);

  const {
    loading: pageloading,
    data: containerdata,
    refetch: refetchContainer,
  } = useQuery(GET_Container, {
    variables: { cid: repoData },
    fetchPolicy: "no-cache",

    skip: !createRepo,
    onCompleted: async (data) => {
      const ReportContainerData = data?.getContainerReports;
      setCreateRepo(false);
      const isCompleted = null;
      navigate(`${APP_ROUTES.EDITREPORT.replace(":id", repoData)}`, {
        state: { isCompleted, ReportContainerData },
      });
    },
  });

  const [UpdateContainerNameMutation, { loading: updateLoading }] = useMutation(
    UPDATE_REPORT_NAME,
    {
      onCompleted: async (data) => {
        const updateContainerResponse = data?.UpdateContainerName;
        if (updateContainerResponse) {
          const { success, errors } = updateContainerResponse;
          if (success) {
            ToasterHelper.success("Report name updated successfully.");
          } else {
            console.error("UpdateContainerNameMutation failed:", errors);
          }
        } else {
          console.error(
            "Invalid response format from UpdateContainerNameMutation"
          );
        }
        refetch();
      },
      onError: async (error) => {
        console.error("UpdateContainerNameMutation error:", error);
      },
    }
  );

  const [deleteReportMutation, { loading: deleteLoading }] = useMutation(
    DELETE_REPORT,
    {
      onCompleted: async ({ deleteReportGroup: { success } }) => {
        ToasterHelper.success(
          t("pages.reports.pricingReport.message.reportdel")
        );
        closeDeleteModal();
        setReportData(reportData.filter((item) => item.id !== reportToDelete));
        setReportToDelete("");
      },
      onError: async (error) => {
        console.log(error);
      },
    }
  );

  const [CreateContainerMutation, { loading: create }] = useMutation(
    Create_Container,
    {
      onCompleted: async ({ createContainer }) => {
        setRepoData(createContainer?.containerId);
        setCreateRepo(true);
        setTimeout(() => {
          refetchContainer();
        }, 1000);
      },

      onError: async (error) => {
        console.log(error);
      },
    }
  );

  const handleAddAnother = () => {
    CreateContainerMutation();
  };

  useEffect(() => {
    if (data?.containers) {
      setReportData(
        data?.containers
          ?.map((reports: ReportOverview) => ({
            ...reports,
            isEdit: false,
          }))
          .sort((a: ReportOverview, b: ReportOverview) => {})
      );
    }
  }, [data]);

  const handleReportNameChange = (reportId: string, containerName: string) => {
    if (!containerName) {
      ToasterHelper.error(t("delete-modals.repoName") as string);
      return;
    }

    UpdateContainerNameMutation({
      variables: { input: { containerId: reportId, name: containerName } },
    });
  };

  const handleCompareClick = (reportId: string) => {
    navigate(`${APP_ROUTES.COMPARE_REPORT.replace(":id", reportId)}`, {
      state: { reportId },
    });
  };

  const filteredItems = useMemo(() => {
    let filtered = reportData.filter(
      (item) => item.status === "In Progress" || item.status === "Completed"
    );

    if (searchTerm) {
      filtered = filtered.filter((item) => {
        const sidMatches = item.status
          ?.toLowerCase()
          .includes(searchTerm?.toLowerCase());
        const plateform = item?.platform
          ?.toLowerCase()
          .includes(searchTerm?.toLowerCase());
        const fileMatches = item.containerName
          ?.toLowerCase()
          .includes(searchTerm?.toLowerCase());
        return sidMatches || plateform || fileMatches;
      });
    }

    return filtered;
  }, [searchTerm, reportData]);

  const catalogueDetailInfo = useMemo(() => {
    return detailInfo?.getCatalogueDetail?.serverSizes;
  }, [detailInfo]);

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  if (loading) {
    return <Loader />;
  }

  const totalItems = catalogueDetailInfo?.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = catalogueDetailInfo?.slice(startIndex, endIndex);

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const openDeleteModal = (containerId: string) => {
    setReportToDelete(containerId);
    setShowDeleteModal(true);
  };

  const handleDelete = () => {
    deleteReportMutation({ variables: { containerId: reportToDelete } });
  };

  const handleAddButton = () => {
  };

  const handleBackButton = () => {
    navigate(`${APP_ROUTES.SAP_RISE}`, {});
  };

  StorageHelper.remove(StorageKeys.ConfigurationDBInstanceType);
  localStorage.removeItem("PrimaryDBServerInstances");
  StorageHelper.remove(StorageKeys.ConfigurationApplicationServerInstanceType);

  const handleChange = (type: any) => {
    if (type === "prod") {
      setIsProd(true);
    } else {
      setIsProd(false);
    }
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="col-1">
          <Sidebar />
        </div>
        <div className="container-fluid p-3 col-11 edit-report-wrapper mt-lg-5 mt-md-3">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <p className="fw-bolder fs-4">
              {detailInfo?.getCatalogueDetail?.serviceName}
            </p>
          </div>
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center flex-wrap mb-lg-3 mb-md-2 mx-2">
              <Form.Check
                inline
                type="radio"
                value={t("pages.sap_rise.radio.prod_value") || ""}
                label={t("pages.sap_rise.radio.prod_label") || ""}
                checked={isProd}
                onChange={() => handleChange("prod")}
                id="prod"
              />
              <Form.Check
                inline
                type="radio"
                value={t("pages.sap_rise.radio.non_prod_value") || ""}
                label={t("pages.sap_rise.radio.non_prod_label") || ""}
                checked={!isProd}
                onChange={() => handleChange("non_prod")}
                id="non_prod"
              />
            </div>
            <div className="d-flex align-items-center flex-wrap mb-lg-3 mb-md-2 mx-2">
              <Button className="px-4 py-2 font-medium text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:shadow-outline-blue active:bg-blue-600 transition duration-150 ease-in-out" onClick={() => handleAddButton()}>{t("pages.sap_rise.add")}</Button>
              <Button className="px-4 py-2 font-medium text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:shadow-outline-blue active:bg-blue-600 transition duration-150 ease-in-out mx-2" onClick={() => handleBackButton()}>{t("pages.sap_rise.back")}</Button>
            </div>
          </div>

          <div className="report-table-responsive">
            <Table
              className="w-full border-collapse border border-blue-500 max-w-xl mt-16 mx-auto"
              // responsive
            >
              <thead
                className="rounded-5"
                style={{
                  borderRadius: "10px",
                  position: "sticky",
                  top: "0",
                  zIndex: "100",
                }}
              >
                {catalogueDetailInfo?.length > 0 ? (
                  <tr className="bg-blue-500 text-white">
                    {catalogueDetailInfo[0]?.usageTire && (
                      <th
                        className="p-3 align-middle"
                        scope="col"
                        style={{
                          backgroundColor: "#0D61BB",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        {catalogueDetailInfo[0]?.usageTire?.label &&
                          catalogueDetailInfo[0]?.usageTire?.label}
                      </th>
                    )}
                    <th
                      className="p-3 align-middle"
                      scope="col"
                      style={{
                        backgroundColor: "#0D61BB",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      {t("pages.sap_rise.system_size")}
                    </th>
                    {catalogueDetailInfo[0]?.databaseType && (
                      <th
                        className="p-3 align-middle"
                        scope="col"
                        style={{
                          backgroundColor: "#0D61BB",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        {catalogueDetailInfo[0]?.databaseType?.label &&
                          catalogueDetailInfo[0]?.databaseType?.label}
                      </th>
                    )}
                    {catalogueDetailInfo[0]?.devSize && (
                      <th
                        className="p-3 align-middle"
                        scope="col"
                        style={{
                          backgroundColor: "#0D61BB",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        {catalogueDetailInfo[0]?.devSize?.label &&
                          catalogueDetailInfo[0]?.devSize?.label}
                      </th>
                    )}
                    {catalogueDetailInfo[0]?.prdSize && (
                      <th
                        className="p-3 align-middle"
                        scope="col"
                        style={{
                          backgroundColor: "#0D61BB",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        {catalogueDetailInfo[0]?.prdSize?.label &&
                          catalogueDetailInfo[0]?.prdSize?.label}
                      </th>
                    )}
                    {catalogueDetailInfo[0]?.nonProdSize && (
                      <th
                        className="p-3 align-middle"
                        scope="col"
                        style={{
                          backgroundColor: "#0D61BB",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        {catalogueDetailInfo[0]?.nonProdSize?.label &&
                          catalogueDetailInfo[0]?.nonProdSize?.label}
                      </th>
                    )}
                    {catalogueDetailInfo[0]?.qasSize && (
                      <th
                        className="p-3 align-middle"
                        scope="col"
                        style={{
                          backgroundColor: "#0D61BB",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        {catalogueDetailInfo[0]?.qasSize?.label &&
                          catalogueDetailInfo[0]?.qasSize?.label}
                      </th>
                    )}
                    <th
                      className="p-3 align-middle"
                      scope="col"
                      style={{
                        backgroundColor: "#0D61BB",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      Selection
                    </th>
                  </tr>
                ) : (
                  <></>
                )}
              </thead>

              <tbody>
                {isLoading ? (
                  <Loader />
                ) : catalogueDetailInfo?.length > 0 ? (
                  catalogueDetailInfo?.map((item: any, index: number) => (
                    <tr
                      key={item.id}
                      className="bg-white border-b border-blue-500"
                    >
                      {item?.usageTire && (
                        <td
                          className="memory-size-column ms-2 py-2 px-4 align-middle"
                          style={{ textAlign: "center" }}
                        >
                          {item?.usageTire?.type1}
                        </td>
                      )}
                      {item?.size && (
                        <td
                          className="memory-size-column ms-2 py-2 px-4 align-middle"
                          style={{ textAlign: "center" }}
                        >
                          {item?.size}
                        </td>
                      )}
                      {item?.databaseType && (
                        <td
                          className="memory-size-column ms-2 py-2 px-4 align-middle"
                          style={{ textAlign: "center" }}
                        >
                          <Table>
                            {item?.databaseType &&
                              item?.databaseType?.type1 && (
                                <tr>
                                  <td
                                    className="memory-size-column ms-2 py-2 px-4 align-middle"
                                    style={{ textAlign: "center" }}
                                  >
                                    {item?.databaseType?.type1}
                                  </td>
                                </tr>
                              )}
                            {item?.databaseType &&
                              item?.databaseType?.type2 && (
                                <tr>
                                  <td
                                    className="memory-size-column ms-2 py-2 px-4 align-middle"
                                    style={{ textAlign: "center" }}
                                  >
                                    {item?.databaseType?.type2}
                                  </td>
                                </tr>
                              )}
                            {item?.databaseType &&
                              item?.databaseType?.type3 && (
                                <tr>
                                  <td
                                    className="memory-size-column ms-2 py-2 px-4 align-middle"
                                    style={{ textAlign: "center" }}
                                  >
                                    {item?.databaseType?.type3}
                                  </td>
                                </tr>
                              )}
                          </Table>
                        </td>
                      )}
                      {item?.devSize && (
                        <td
                          className="memory-size-column ms-2 py-2 px-4 align-middle"
                          style={{ textAlign: "center" }}
                        >
                          <Table>
                            {item?.devSize && item?.devSize?.type1 && (
                              <tr>
                                <td
                                  className="memory-size-column ms-2 py-2 px-4 align-middle"
                                  style={{ textAlign: "center" }}
                                >
                                  {item?.devSize?.type1}
                                </td>
                              </tr>
                            )}
                            {item?.devSize && item?.devSize?.type2 && (
                              <tr>
                                <td
                                  className="memory-size-column ms-2 py-2 px-4 align-middle"
                                  style={{ textAlign: "center" }}
                                >
                                  {item?.devSize?.type2}
                                </td>
                              </tr>
                            )}
                            {item?.devSize && item?.devSize?.type3 && (
                              <tr>
                                <td
                                  className="memory-size-column ms-2 py-2 px-4 align-middle"
                                  style={{ textAlign: "center" }}
                                >
                                  {item?.devSize?.type3}
                                </td>
                              </tr>
                            )}
                          </Table>
                        </td>
                      )}
                      {item?.prdSize && (
                        <td
                          className="memory-size-column ms-2 py-2 px-4 align-middle"
                          style={{ textAlign: "center" }}
                        >
                          <Table>
                            {item?.prdSize && item?.prdSize?.type1 && (
                              <tr>
                                <td
                                  className="memory-size-column ms-2 py-2 px-4 align-middle"
                                  style={{ textAlign: "center" }}
                                >
                                  {item?.prdSize?.type1}
                                </td>
                              </tr>
                            )}
                            {item?.prdSize && item?.prdSize?.type2 && (
                              <tr>
                                <td
                                  className="memory-size-column ms-2 py-2 px-4 align-middle"
                                  style={{ textAlign: "center" }}
                                >
                                  {item?.prdSize?.type2}
                                </td>
                              </tr>
                            )}
                            {item?.prdSize && item?.prdSize?.type3 && (
                              <tr>
                                <td
                                  className="memory-size-column ms-2 py-2 px-4 align-middle"
                                  style={{ textAlign: "center" }}
                                >
                                  {item?.prdSize?.type3}
                                </td>
                              </tr>
                            )}
                          </Table>
                        </td>
                      )}
                      {item?.nonProdSize && (
                        <td
                          className="memory-size-column ms-2 py-2 px-4 align-middle"
                          style={{ textAlign: "center" }}
                        >
                          <Table>
                            {item?.nonProdSize && item?.nonProdSize?.type1 && (
                              <tr>
                                <td
                                  className="memory-size-column ms-2 py-2 px-4 align-middle"
                                  style={{ textAlign: "center" }}
                                >
                                  {item?.nonProdSize?.type1}
                                </td>
                              </tr>
                            )}
                            {item?.nonProdSize && item?.nonProdSize?.type2 && (
                              <tr>
                                <td
                                  className="memory-size-column ms-2 py-2 px-4 align-middle"
                                  style={{ textAlign: "center" }}
                                >
                                  {item?.nonProdSize?.type2}
                                </td>
                              </tr>
                            )}
                            {item?.nonProdSize && item?.nonProdSize?.type3 && (
                              <tr>
                                <td
                                  className="memory-size-column ms-2 py-2 px-4 align-middle"
                                  style={{ textAlign: "center" }}
                                >
                                  {item?.nonProdSize?.type3}
                                </td>
                              </tr>
                            )}
                          </Table>
                        </td>
                      )}
                      {item?.qasSize && (
                        <td
                          className="memory-size-column ms-2 py-2 px-4 align-middle"
                          style={{ textAlign: "center" }}
                        >
                          <Table>
                            {item?.qasSize && item?.qasSize?.type1 && (
                              <tr>
                                <td
                                  className="memory-size-column ms-2 py-2 px-4 align-middle"
                                  style={{ textAlign: "center" }}
                                >
                                  {item?.qasSize?.type1}
                                </td>
                              </tr>
                            )}
                            {item?.qasSize && item?.qasSize?.type2 && (
                              <tr>
                                <td
                                  className="memory-size-column ms-2 py-2 px-4 align-middle"
                                  style={{ textAlign: "center" }}
                                >
                                  {item?.qasSize?.type2}
                                </td>
                              </tr>
                            )}
                            {item?.qasSize && item?.qasSize?.type3 && (
                              <tr>
                                <td
                                  className="memory-size-column ms-2 py-2 px-4 align-middle"
                                  style={{ textAlign: "center" }}
                                >
                                  {item?.qasSize?.type3}
                                </td>
                              </tr>
                            )}
                          </Table>
                        </td>
                      )}
                      <td
                        className="text-center py-2 px-4 align-middle"
                        style={{ textAlign: "center" }}
                      >
                        <Form.Check
                          inline
                          type="checkbox"
                          value={t("pages.sap_rise.radio.prod_value") || ""}
                          id="prod"
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8} style={{ textAlign: "center" }}>
                      {t("pages.notFound.norecordfound")}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>

          {/* {totalPages > 1 && (
            <Pagination>
              {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                (page) => (
                  <Pagination.Item
                    key={page}
                    active={page === currentPage}
                    onClick={() => handlePageChange(page)}
                  >
                    {page}
                  </Pagination.Item>
                )
              )}
            </Pagination>
          )} */}

          <DeleteModal
            title={t("errorMessages.Report")}
            showModal={showDeleteModal}
            closeModal={closeDeleteModal}
            handleDelete={handleDelete}
            loading={deleteLoading}
          />
        </div>
        {showDefaultConfig && <DefaultConfig />}
      </div>
    </>
  );
};

export default SapRiseDetailPage;
