export const StorageKeys = {
  Token: 'token',
  User: 'user',
  SizingReportId: 'sizing_report_id',
  tier:'tier',
  Language: 'language',
  Regions: 'regions',
  ExcludedEmailDomains: "excluded_email_domain",
  ConfigurationDBInstanceType: "Configuration_db_instance_type",
  ConfigurationApplicationServerInstanceType: "Configuration_application_server_instance_type",
  ConfigurationVendor: "ConfigurationVendor",
  containerId:"containerId",
  expireToken:'expireToken',
  refreshToken: "refreshToken",
  refreshExpiration: 'refreshExpiration'
};

export class StorageHelper {
  public static get(key: string) {
    try {
      const value = localStorage.getItem(key);
      return JSON.parse(value || '');
    } catch (error) {
      return null;
    }
  }

  public static set(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  public static remove(key: string) {
    localStorage.removeItem(key);
  }

  public static clear() {
    localStorage.clear();
  }
}
