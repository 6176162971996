import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom"; 
import { StorageHelper, StorageKeys } from "utils";
import SessionModal from "./SessionModal";
import { useMutation } from "@apollo/client";
import { REFRESH_TOKEN } from "graphql/mutations"; 

const INACTIVE_TIME = 120

const SessionExpiry = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const lastActivityRef = useRef(Date.now());

  const [refreshTokenMutation] = useMutation(REFRESH_TOKEN, {
    onCompleted: ({ getRefreshToken: { accessToken, accessExpiration } }) => {
      StorageHelper.set(StorageKeys.Token, accessToken);
      StorageHelper.set(StorageKeys.expireToken, accessExpiration);
      console.log("did login refresh")
      setShowModal(false);
    },
    onError: () => {
      console.log("Error on refresh token")
      StorageHelper.remove(StorageKeys.Token);
      setShowModal(true); 
    }
  });

  const handleUserActivity = useCallback(() => {
    lastActivityRef.current = Date.now();
  }, []);

  useEffect(() => {
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    return () => {
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
    };
  }, [handleUserActivity]);

  useEffect(() => {
    const checkSession = () => {
      const expirationTime = parseInt(StorageHelper.get(StorageKeys.expireToken));
      const refreshToken = StorageHelper.get(StorageKeys.refreshToken);
      const currentTime = Math.floor(Date.now() / 1000);
      const timeRemaining = expirationTime - currentTime;
      const minutesLeft = Math.floor(timeRemaining / 60);
      const inactiveTimeMinutes = Math.floor((Date.now() - lastActivityRef.current) / (1000 * 60));
      
      console.log(`minutesLeft time (minutes):`, minutesLeft);
      console.log(`Inactive time (minutes):`, inactiveTimeMinutes);

      if (expirationTime) {
        if (minutesLeft <= 5) {
          if (inactiveTimeMinutes < INACTIVE_TIME && refreshToken) {
            console.log("Refreshing the token ...");
            refreshTokenMutation({ variables: { refreshToken } });
          } else {
            console.log("Closing the session ...");
            setShowModal(true);
          }
        }
      }
    };

    checkSession();

    const interval = setInterval(checkSession, 60000); 

    return () => clearInterval(interval);
  }, [refreshTokenMutation]);

  const handleModalConfirm = () => {
    StorageHelper.remove(StorageKeys.Token);
    navigate('/login');
  };

  return <SessionModal show={showModal} onConfirm={handleModalConfirm} />;
};

export default SessionExpiry;
