import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TextField, Button, Box, MenuItem, Select, InputLabel, FormControl, Typography, IconButton, Paper } from '@mui/material';
import { Step3FormData } from '../../entities/models/catalogue.model';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import '../../../src/assets/scss/catalogue-admin.scss';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_SYSTEM_TIERS_TO_SIZE } from 'graphql/mutations/server-catalogue.mutations';
import { ToasterHelper } from 'utils/toaster.helper';
import { OperatingSystem, GcpOperatingSystem } from 'entities/enums';
import { STORAGE_CODE } from 'graphql/queries';
import { formatDiskLabel } from '../../../src/pages/edit-report/db-server-selection'; // Ensure this import path is correct


interface Step3FormProps {
  onSubmit: (data: Step3FormData) => void;
  catalogueId: string;
  catalogueSizeId: string;
  systemTiers: string[];
}

const Step3Form: React.FC<Step3FormProps> = ({ onSubmit, catalogueId, catalogueSizeId,systemTiers }) => {
  const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm<Step3FormData>({
    defaultValues: { catalogueSizeId }
  });

  const [currentTier, setCurrentTier] = useState(0);
  const tiers = systemTiers 

  //logging
  useEffect(() => {
    console.log('catalogueSizeId changed:', catalogueSizeId);
  }, [catalogueSizeId]);


  const { data: StorageData } = useQuery(STORAGE_CODE);

  // State for each collapsible section
  const [isAWSOpen, setIsAWSOpen] = useState(false);
  const [isAZUREOpen, setIsAZUREOpen] = useState(false);
  const [isGCPOpen, setIsGCPOpen] = useState(false);

  // Toggle functions
  const toggleAWSAppServer = () => setIsAWSOpen(!isAWSOpen);
  const toggleAZURETab = () => setIsAZUREOpen(!isAZUREOpen);
  const toggleGCPTab = () => setIsGCPOpen(!isGCPOpen);

  const [addSystemTiersToSize] = useMutation(ADD_SYSTEM_TIERS_TO_SIZE, {
    onCompleted: (data) => {
      console.log('System tiers added successfully:', data);
      ToasterHelper.success('System tiers added successfully');
      onSubmit(data);
    },
    onError: (error) => {
      console.error('Error adding system tiers:', error);
      ToasterHelper.error('Error adding system tiers');
    },
  });

  const [awsStorageOptions, setAwsStorageOptions] = useState<{ label: string; type: string; code: string }[]>([]);
  const [azureStorageOptions, setAzureStorageOptions] = useState<{ label: string; type: string; code: string }[]>([]);
  const [gcpStorageOptions, setGcpStorageOptions] = useState<{ label: string; type: string; code: string }[]>([]);

  // State for storage types and codes
  const [awsAppServerStorage, setAwsAppServerStorage] = useState({ type: '', code: '' });
  const [awsDbServerStorage, setAwsDbServerStorage] = useState({ type: '', code: '' });
  const [azureAppServerStorage, setAzureAppServerStorage] = useState({ type: '', code: '' });
  const [azureDbServerStorage, setAzureDbServerStorage] = useState({ type: '', code: '' });
  const [gcpAppServerStorage, setGcpAppServerStorage] = useState({ type: '', code: '' });
  const [gcpDbServerStorage, setGcpDbServerStorage] = useState({ type: '', code: '' });

  useEffect(() => {
    // Update form values when storage state changes
    setValue('AWS.appServer.storageType', awsAppServerStorage.type);
    setValue('AWS.appServer.storageCode', awsAppServerStorage.code);
    setValue('AWS.databaseServer.storageType', awsDbServerStorage.type);
    setValue('AWS.databaseServer.storageCode', awsDbServerStorage.code);

    //azure
    setValue('AZURE.appServer.storageType', azureAppServerStorage.type);
    setValue('AZURE.appServer.storageCode', azureAppServerStorage.code);
    setValue('AZURE.databaseServer.storageType', azureDbServerStorage.type);
    setValue('AZURE.databaseServer.storageCode', azureDbServerStorage.code);

    //gcp
    setValue('GCP.appServer.storageType', gcpAppServerStorage.type);
    setValue('GCP.appServer.storageCode', gcpAppServerStorage.code);
    setValue('GCP.databaseServer.storageType', gcpDbServerStorage.type);
    setValue('GCP.databaseServer.storageCode', gcpDbServerStorage.code);
  }, [awsAppServerStorage, awsDbServerStorage, azureAppServerStorage, azureDbServerStorage, gcpAppServerStorage, gcpDbServerStorage, setValue]);

  useEffect(() => {
    if (StorageData?.getDisksCode) {
      const processStorageOptions = (vendor: string) => {
        const seenCodes = new Set<string>();
        return StorageData.getDisksCode
          .filter((disk: any) => disk.vendor === vendor)
          .filter((disk: any) => {
            if (seenCodes.has(disk.code)) {
              return false;
            } else {
              seenCodes.add(disk.code);
              return true;
            }
          })
          .map((disk: any) => ({
            label: formatDiskLabel(disk.code, disk.vendor),
            type: disk.type,
            code: disk.code,
          }))
          .sort((a: any, b: any) => a.code.localeCompare(b.code));
      };

      setAwsStorageOptions(processStorageOptions('AWS'));
      setAzureStorageOptions(processStorageOptions('AZURE'));
      setGcpStorageOptions(processStorageOptions('GCP'));
    }
  }, [StorageData]);

  const handleFormSubmit = async (formData: Step3FormData) => {
    try {
      // Convert properties to integers
      const processedFormData = {
        ...formData,
        dbServerCount: Number(formData.dbServerCount),
        appServerCount: Number(formData.appServerCount),
        AWS: {
          ...formData.AWS,
          appServer: {
            ...formData.AWS?.appServer,
            storageSpace: Number(formData.AWS?.appServer?.storageSpace),
          },
          databaseServer: {
            ...formData.AWS?.databaseServer,
            storageSpace: Number(formData.AWS?.databaseServer?.storageSpace),
          },
        },
        AZURE: {
          ...formData.AZURE,
          appServer: {
            ...formData.AZURE?.appServer,
            storageSpace: Number(formData.AZURE?.appServer?.storageSpace),
          },
          databaseServer: {
            ...formData.AZURE?.databaseServer,
            storageSpace: Number(formData.AZURE?.databaseServer?.storageSpace),
          },
        },
        GCP: {
          ...formData.GCP,
          appServer: {
            ...formData.GCP?.appServer,
            storageSpace: Number(formData.GCP?.appServer?.storageSpace),
          },
          databaseServer: {
            ...formData.GCP?.databaseServer,
            storageSpace: Number(formData.GCP?.databaseServer?.storageSpace),
          },
        },
      };

      await addSystemTiersToSize({
        variables: {
          input: {
            ...processedFormData,
            catalogueSizeId,
            name: tiers[currentTier], 
          },
        },
      });

      if (currentTier < tiers.length - 1) {
        setCurrentTier(currentTier + 1);
      } else {
        onSubmit(processedFormData);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {/* <Typography variant="h6">System Tier: {tiers[currentTier]}</Typography> */}
        
        <Box sx={{ display: 'flex', gap: 2 }}>
        <TextField
          label="System Tier"
          value={tiers[currentTier]}
          InputProps={{
            readOnly: true,
          }}
          fullWidth
        />
          <TextField
            label="DB Size"
            {...register('dbSize', { required: 'DB Size is required' })}
            error={!!errors.dbSize}
            helperText={errors.dbSize?.message}
            fullWidth
          />
        </Box>


        {/* Three Inputs on the Same Line */}
        <Box sx={{ display: 'flex', gap: 2 }}>
          <TextField
            label="DB Server Count"
            type="number"
            {...register('dbServerCount', {
              required: 'DB Server Count is required',
              valueAsNumber: true  
            })}
            error={!!errors.dbServerCount}
            helperText={errors.dbServerCount?.message}
            fullWidth
          />
          <TextField
            label="DB Server Size"
            {...register('dbServerSize', { required: 'DB Server Size is required' })}
            error={!!errors.dbServerSize}
            helperText={errors.dbServerSize?.message}
            fullWidth
          />
          <TextField
            label="App Server Count"
            type="number"
            {...register('appServerCount', {
              required: 'App Server Count is required',
              valueAsNumber: true 
            })}
            error={!!errors.appServerCount}
            helperText={errors.appServerCount?.message}
            fullWidth
          />
          <TextField
            label="App Server Size"
            {...register('appServer', { required: 'App Server is required' })}
            error={!!errors.appServer}
            helperText={errors.appServer?.message}
            fullWidth
          />
        </Box>

        {/* Cloud Provider Sections */}
        <Typography variant="h5" sx={{ mt: 3, mb: 2 }}>Cloud Provider Configurations</Typography>

        {/* AWS */}

        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
          <Typography variant="h6" sx={{ cursor: 'pointer' }} onClick={toggleAWSAppServer}>
            AWS
            <IconButton>{isAWSOpen ? <ExpandLess /> : <ExpandMore />}</IconButton>
          </Typography>

          {isAWSOpen && (
            <Box sx={{ mt: 2, gap: 2, display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h6" sx={{ fontSize: '18px', marginLeft: '10px' }}>APP SERVER CONFIGURATION</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, marginLeft: '10px' }}>
                <TextField
                  label="Server Instance"
                  {...register('AWS.appServer.instanceCode', { required: 'Instance Code is required' })}
                  error={!!errors?.AWS?.appServer?.instanceCode}
                  helperText={errors?.AWS?.appServer?.instanceCode?.message}
                  fullWidth
                />
                <TextField
                  label="vCPU"
                  {...register('AWS.appServer.vcpu', { required: 'vCPU is required' })}
                  error={!!errors.AWS?.appServer?.vcpu}
                  helperText={errors.AWS?.appServer?.vcpu?.message}
                  fullWidth
                />
                <TextField
                  label="Memory"
                  {...register('AWS.appServer.memory', { required: 'Memory is required' })}
                  error={!!errors.AWS?.appServer?.memory}
                  helperText={errors.AWS?.appServer?.memory?.message}
                  fullWidth
                />
                
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, marginLeft: '10px' }}>
              <FormControl fullWidth error={!!errors.AWS?.appServer?.operatingSystem}>
                  <InputLabel>Operating System</InputLabel>
                  <Select
                    {...register('AWS.appServer.operatingSystem', { required: 'Operating System is required' })}
                    label="App Server Operating System"
                  >
                    {Object.values(OperatingSystem).map((os) => (
                      <MenuItem key={os} value={os}>
                        {os}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.AWS?.appServer?.operatingSystem && (
                    <Typography variant="caption" color="error">
                      {errors.AWS?.appServer?.operatingSystem.message}
                    </Typography>
                  )}
                </FormControl>
                <FormControl fullWidth error={!!errors.AWS?.appServer?.storageType}>
                  <InputLabel>App Server Storage Type</InputLabel>
                  <Select
                    value={`${awsAppServerStorage.type},${awsAppServerStorage.code}`}
                    onChange={(e) => {
                      const [storageType, storageCode] = e.target.value.split(',');
                      setAwsAppServerStorage({ type: storageType, code: storageCode });
                    }}
                    label="App Server Storage Type"
                  >
                    <MenuItem value="">Select</MenuItem>
                    {awsStorageOptions.map((option) => (
                      <MenuItem key={option.label} value={`${option.type},${option.code}`}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.AWS?.appServer?.storageType && (
                    <Typography variant="caption" color="error">
                      {errors.AWS?.appServer?.storageType.message}
                    </Typography>
                  )}
                </FormControl>
                <TextField
                  label="Storage Space"
                  type="number"
                  {...register('AWS.appServer.storageSpace', 
                    { 
                      required: 'Storage Space is required',
                      valueAsNumber: true 
                    })}
                  error={!!errors.AWS?.appServer?.storageSpace}
                  helperText={errors.AWS?.appServer?.storageSpace?.message}
                  fullWidth
                />
              </Box>
              <Typography variant="h6" sx={{ fontSize: '18px', marginLeft: '10px' }}>DATABASE CONFIGURATION</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, marginLeft: '10px' }}>
                <TextField
                  label="Server Instance"
                  {...register('AWS.databaseServer.instanceCode', { required: 'Instance Code is required' })}
                  error={!!errors.AWS?.databaseServer?.instanceCode}
                  helperText={errors.AWS?.databaseServer?.instanceCode?.message}
                  fullWidth
                />
                <TextField
                  label="vCPU"
                  {...register('AWS.databaseServer.vcpu', { required: 'vCPU is required' })}
                  error={!!errors.AWS?.databaseServer?.vcpu}
                  helperText={errors.AWS?.databaseServer?.vcpu?.message}
                  fullWidth
                />
                <TextField
                  label="RAM"
                  {...register('AWS.databaseServer.memory', { required: 'Memory is required' })}
                  error={!!errors.AWS?.databaseServer?.memory}
                  helperText={errors.AWS?.databaseServer?.memory?.message}
                  fullWidth
                />
                
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, marginLeft: '10px' }}>
                <FormControl fullWidth error={!!errors.AWS?.databaseServer?.operatingSystem}>
                  <InputLabel>Operating System</InputLabel>
                  <Select
                    {...register('AWS.databaseServer.operatingSystem', { required: 'Operating System is required' })}
                    label="Operating System"
                  >
                    {Object.values(OperatingSystem).map((os) => (
                      <MenuItem key={os} value={os}>
                        {os}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.AWS?.databaseServer?.operatingSystem && (
                    <Typography variant="caption" color="error">
                      {errors.AWS?.databaseServer?.operatingSystem.message}
                    </Typography>
                  )}
                </FormControl>
                <FormControl fullWidth error={!!errors.AWS?.databaseServer?.storageType}>
                  <InputLabel>Database Server Storage Type</InputLabel>
                  <Select
                    value={`${awsDbServerStorage.type},${awsDbServerStorage.code}`}
                    onChange={(e) => {
                      const [storageType, storageCode] = e.target.value.split(',');
                      setAwsDbServerStorage({ type: storageType, code: storageCode });
                    }}
                    label="Database Server Storage Type"
                  >
                    <MenuItem value="">Select</MenuItem>
                    {awsStorageOptions.map((option) => (
                      <MenuItem key={option.label} value={`${option.type},${option.code}`}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.AWS?.databaseServer?.storageType && (
                    <Typography variant="caption" color="error">
                      {errors.AWS?.databaseServer?.storageType.message}
                    </Typography>
                  )}
                </FormControl>
                <TextField
                  label="Storage Space"
                  type="number"
                  {...register('AWS.databaseServer.storageSpace', 
                    { required: 'Storage Space is required', 
                      valueAsNumber: true 
                    })}
                  error={!!errors.AWS?.databaseServer?.storageSpace}
                  helperText={errors.AWS?.databaseServer?.storageSpace?.message}
                  fullWidth
                />
              </Box>
            </Box>
          )}
        </Paper>

        {/* AZURE */}

        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
          <Typography variant="h6" sx={{ cursor: 'pointer' }} onClick={toggleAZURETab}>AZURE<IconButton>{isAZUREOpen ? <ExpandLess /> : <ExpandMore />}</IconButton> </Typography>

          {isAZUREOpen && (
            <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Typography variant="h6" sx={{ fontSize: '18px', marginLeft: '10px' }}>APP SERVER CONFIGURATION</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, marginLeft: '10px' }}>
                <TextField
                  label="App Server Instance Code"
                  {...register('AZURE.appServer.instanceCode', { required: 'Instance Code is required' })}
                  error={!!errors?.AZURE?.appServer?.instanceCode}
                  helperText={errors?.AZURE?.appServer?.instanceCode?.message}
                  fullWidth
                />
                <TextField
                  label="vCPU"
                  {...register('AZURE.appServer.vcpu', { required: 'vCPU is required' })}
                  error={!!errors.AZURE?.appServer?.vcpu}
                  helperText={errors.AZURE?.appServer?.vcpu?.message}
                  fullWidth
                />
                <TextField
                  label="RAM"
                  {...register('AZURE.appServer.memory', { required: 'Memory is required' })}
                  error={!!errors.AZURE?.appServer?.memory}
                  helperText={errors.AZURE?.appServer?.memory?.message}
                  fullWidth
                />
                
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, marginLeft: '10px' }}>
                <FormControl fullWidth error={!!errors.AZURE?.appServer?.operatingSystem}>
                  <InputLabel>Operating System</InputLabel>
                  <Select
                    {...register('AZURE.appServer.operatingSystem', { required: 'Operating System is required' })}
                    label="App Server Operating System"
                  >
                    {Object.values(OperatingSystem).map((os) => (
                      <MenuItem key={os} value={os}>
                        {os}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.AZURE?.appServer?.operatingSystem && (
                    <Typography variant="caption" color="error">
                      {errors.AZURE?.appServer?.operatingSystem.message}
                    </Typography>
                  )}
                </FormControl>
                {/* <FormControl fullWidth error={!!errors.AZURE?.appServer?.storageType}>
                  <InputLabel>Storage Type</InputLabel>
                  <Select
                    {...register('AZURE.appServer.storageType', { required: 'Storage Type is required' })}
                    label="Storage Type"
                    // value={`${watch('AZURE.appServer.storageType')},${watch('AZURE.appServer.storageCode')}`}
                    value={`${awsAppServerStorage.type},${awsAppServerStorage.code}`}
                    onChange={(e) => {
                      const [storageType, storageCode] = e.target.value.split(',');
                      setAzureAppServerStorage({ type: storageType, code: storageCode });
                      // setValue('AZURE.appServer.storageType', storageType);
                      // setValue('AZURE.appServer.storageCode', storageCode);
                    }}
                  >
                    <MenuItem value="">Select</MenuItem>
                    {azureStorageOptions.map((option) => (
                      <MenuItem key={option.label} value={`${option.type},${option.code}`}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.AZURE?.appServer?.storageType && (
                    <Typography variant="caption" color="error">
                      {errors.AZURE?.appServer?.storageType?.message}
                    </Typography>
                  )}
                </FormControl> */}
                <FormControl fullWidth error={!!errors.AZURE?.appServer?.storageType}>
                  <InputLabel>Storage Type</InputLabel>
                  <Select
                    value={`${azureAppServerStorage.type},${azureAppServerStorage.code}`}
                    onChange={(e) => {
                      const [storageType, storageCode] = e.target.value.split(',');
                      setAzureAppServerStorage({ type: storageType, code: storageCode });
                    }}
                    label="Storage Type"
                  >
                    <MenuItem value="">Select</MenuItem>
                    {azureStorageOptions.map((option) => (
                      <MenuItem key={option.label} value={`${option.type},${option.code}`}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.AZURE?.appServer?.storageType && (
                    <Typography variant="caption" color="error">
                      {errors.AZURE?.appServer?.storageType?.message}
                    </Typography>
                  )}
                </FormControl>
                <TextField
                  label="Storage Space"
                  type="number"
                  {...register('AZURE.appServer.storageSpace', 
                    { 
                      required: 'Storage Space is required',
                      valueAsNumber: true 
                    })}
                  error={!!errors.AZURE?.appServer?.storageSpace}
                  helperText={errors.AZURE?.appServer?.storageSpace?.message}
                  fullWidth
                />
                
              </Box>
              <Typography variant="h6" sx={{ fontSize: '18px', marginLeft: '10px' }}>DATABASE CONFIGURATION</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, marginLeft: '10px' }}>
                <TextField
                  label="Server Instance"
                  {...register('AZURE.databaseServer.instanceCode', { required: 'Instance Code is required' })}
                  error={!!errors.AZURE?.databaseServer?.instanceCode}
                  helperText={errors.AZURE?.databaseServer?.instanceCode?.message}
                  fullWidth
                />
                <TextField
                  label="vCPU"
                  {...register('AZURE.databaseServer.vcpu', { required: 'vCPU is required' })}
                  error={!!errors.AZURE?.databaseServer?.vcpu}
                  helperText={errors.AZURE?.databaseServer?.vcpu?.message}
                  fullWidth
                />
                <TextField
                  label="RAM"
                  {...register('AZURE.databaseServer.memory', { required: 'Memory is required' })}
                  error={!!errors.AZURE?.databaseServer?.memory}
                  helperText={errors.AZURE?.databaseServer?.memory?.message}
                  fullWidth
                />
                
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, marginLeft: '10px' }}>
                <FormControl fullWidth error={!!errors.AZURE?.appServer?.operatingSystem}>
                  <InputLabel>Operating System</InputLabel>
                  <Select
                    {...register('AZURE.databaseServer.operatingSystem', { required: 'Operating System is required' })}
                    label="App Server Operating System"
                  >
                    {Object.values(OperatingSystem).map((os) => (
                      <MenuItem key={os} value={os}>
                        {os}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.AZURE?.databaseServer?.operatingSystem && (
                    <Typography variant="caption" color="error">
                      {errors.AZURE?.databaseServer?.operatingSystem.message}
                    </Typography>
                  )}
                </FormControl>
                
                <FormControl fullWidth error={!!errors.AZURE?.databaseServer?.storageType}>
                  <InputLabel>Storage Type</InputLabel>
                  <Select
                    value={`${azureDbServerStorage.type},${azureDbServerStorage.code}`}
                    onChange={(e) => {
                      const [storageType, storageCode] = e.target.value.split(',');
                      setAzureDbServerStorage({ type: storageType, code: storageCode });
                    }}
                    label="Database Server Storage Type"
                  >
                    <MenuItem value="">Select</MenuItem>
                    {azureStorageOptions.map((option) => (
                      <MenuItem key={option.label} value={`${option.type},${option.code}`}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.AZURE?.databaseServer?.storageType && (
                    <Typography variant="caption" color="error">
                      {errors.AZURE?.databaseServer?.storageType.message}
                    </Typography>
                  )}
                </FormControl>
                <TextField
                  label="Storage Space"
                  type="number"
                  {...register('AZURE.databaseServer.storageSpace', 
                    { 
                      required: 'Storage Space is required',
                      valueAsNumber: true 
                    })}
                  error={!!errors.AZURE?.databaseServer?.storageSpace}
                  helperText={errors.AZURE?.databaseServer?.storageSpace?.message}
                  fullWidth
                />
              </Box>
            </Box>
          )}
        </Paper>


        {/* GCP */}

        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>

          <Typography variant="h6" sx={{ cursor: 'pointer' }} onClick={toggleGCPTab}>GCP<IconButton>{isGCPOpen ? <ExpandLess /> : <ExpandMore />}</IconButton> </Typography>

          {isGCPOpen && (
            <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Typography variant="h6" sx={{ fontSize: '18px', marginLeft: '10px' }}>APP SERVER CONFIGURATION</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, marginLeft: '10px' }}>
                <TextField
                  label="Server Instance"
                  {...register('GCP.appServer.instanceCode', { required: 'Instance Code is required' })}
                  error={!!errors?.GCP?.appServer?.instanceCode}
                  helperText={errors?.GCP?.appServer?.instanceCode?.message}
                  fullWidth
                />
                <TextField
                  label="vCPU"
                  {...register('GCP.appServer.vcpu', { required: 'vCPU is required' })}
                  error={!!errors.GCP?.appServer?.vcpu}
                  helperText={errors.GCP?.appServer?.vcpu?.message}
                  fullWidth
                />
                <TextField
                  label="RAM"
                  {...register('GCP.appServer.memory', { required: 'Memory is required' })}
                  error={!!errors.GCP?.appServer?.memory}
                  helperText={errors.GCP?.appServer?.memory?.message}
                  fullWidth
                />
                
                
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, marginLeft: '10px' }}>
                <FormControl fullWidth error={!!errors.GCP?.appServer?.operatingSystem}>
                  <InputLabel>Operating System</InputLabel>
                  <Select
                    {...register('GCP.appServer.operatingSystem', { required: 'Operating System is required' })}
                    label="App Server Operating System"
                  >
                    {Object.values(GcpOperatingSystem).map((os) => (
                      <MenuItem key={os} value={os}>
                        {os}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.AZURE?.appServer?.operatingSystem && (
                    <Typography variant="caption" color="error">
                      {errors.GCP?.appServer?.operatingSystem?.message}
                    </Typography>
                  )}
                </FormControl>
                <FormControl fullWidth error={!!errors.GCP?.appServer?.storageType}>
                  <InputLabel>Storage Type</InputLabel>
                  <Select
                    {...register('GCP.appServer.storageType', { required: 'Storage Type is required' })}
                    label="Storage Type"
                    // value={`${watch('GCP.appServer.storageType')},${watch('GCP.appServer.storageCode')}`}
                    // onChange={(e) => {
                    //   const [storageType, storageCode] = e.target.value.split(',');
                    //   setValue('GCP.appServer.storageType', storageType);
                    //   setValue('GCP.appServer.storageCode', storageCode);
                    // }}
                    value={`${gcpAppServerStorage.type},${gcpAppServerStorage.code}`}
                    onChange={(e) => {
                      const [storageType, storageCode] = e.target.value.split(',');
                      setGcpAppServerStorage({ type: storageType, code: storageCode });
                      // setValue('AZURE.appServer.storageType', storageType);
                      // setValue('AZURE.appServer.storageCode', storageCode);
                    }}
                  >
                    <MenuItem value="">Select</MenuItem>
                    {gcpStorageOptions.map((option) => (
                      <MenuItem key={option.label} value={`${option.type},${option.code}`}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.GCP?.appServer?.storageType && (
                    <Typography variant="caption" color="error">
                      {errors.GCP?.appServer?.storageType?.message}
                    </Typography>
                  )}
                </FormControl>
                <TextField
                  label="Storage Space"
                  type="number"
                  {...register('GCP.appServer.storageSpace', 
                    { 
                      required: 'Storage Space is required',
                      valueAsNumber: true 
                     })}
                  error={!!errors.GCP?.appServer?.storageSpace}
                  helperText={errors.GCP?.appServer?.storageSpace?.message}
                  fullWidth
                />
                
                {/* <TextField
                  label="Storage Type"
                  {...register('GCP.appServer.storageType', { required: 'Storage Type is required' })}
                  error={!!errors.GCP?.appServer?.storageType}
                  helperText={errors.GCP?.appServer?.storageType?.message}
                  fullWidth
                /> */}
              </Box>
              <Typography variant="h6" sx={{ fontSize: '18px', marginLeft: '10px' }}>DATABASE CONFIGURATION</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, marginLeft: '10px' }}>
                <TextField
                  label="Server Instance"
                  {...register('GCP.databaseServer.instanceCode', { required: 'Instance Code is required' })}
                  error={!!errors.GCP?.databaseServer?.instanceCode}
                  helperText={errors.GCP?.databaseServer?.instanceCode?.message}
                  fullWidth
                />
                <TextField
                  label="CPU"
                  {...register('GCP.databaseServer.vcpu', { required: 'vCPU is required' })}
                  error={!!errors.GCP?.databaseServer?.vcpu}
                  helperText={errors.GCP?.databaseServer?.vcpu?.message}
                  fullWidth
                />
                <TextField
                  label="RAM"
                  {...register('GCP.databaseServer.memory', { required: 'Memory is required' })}
                  error={!!errors.GCP?.databaseServer?.memory}
                  helperText={errors.GCP?.databaseServer?.memory?.message}
                  fullWidth
                />
                {/* <TextField
                  label="Database Server Operating System"
                  {...register('GCP.databaseServer.operatingSystem', { required: 'Operating System is required' })}
                  error={!!errors.GCP?.databaseServer?.operatingSystem}
                  helperText={errors.GCP?.databaseServer?.operatingSystem?.message}
                  fullWidth
                /> */}
                
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, marginLeft: '10px' }}>
                {/* <TextField
                  label="Storage Code"
                  {...register('GCP.databaseServer.storageCode', { required: 'Storage Code is required' })}
                  error={!!errors.GCP?.databaseServer?.storageCode}
                  helperText={errors.GCP?.databaseServer?.storageCode?.message}
                  fullWidth
                /> */}
                <FormControl fullWidth error={!!errors.GCP?.databaseServer?.operatingSystem}>
                  <InputLabel>Operating System</InputLabel>
                  <Select
                    {...register('GCP.databaseServer.operatingSystem', { required: 'Operating System is required' })}
                    label="App Server Operating System"
                  >
                    {Object.values(GcpOperatingSystem).map((os) => (
                      <MenuItem key={os} value={os}>
                        {os}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.GCP?.databaseServer?.operatingSystem && (
                    <Typography variant="caption" color="error">
                      {errors.GCP?.databaseServer?.operatingSystem?.message}
                    </Typography>
                  )}
                </FormControl>
                <FormControl fullWidth error={!!errors.GCP?.databaseServer?.storageType}>
                  <InputLabel>Storage Type</InputLabel>
                  <Select
                    {...register('GCP.databaseServer.storageType', { required: 'Storage Type is required' })}
                    label="Storage Type"
                    // value={`${watch('GCP.databaseServer.storageType')},${watch('GCP.databaseServer.storageCode')}`}
                    // onChange={(e) => {
                    //   const [storageType, storageCode] = e.target.value.split(',');
                    //   setValue('GCP.databaseServer.storageType', storageType);
                    //   setValue('GCP.databaseServer.storageCode', storageCode);
                    // }}
                    value={`${gcpDbServerStorage.type},${gcpDbServerStorage.code}`}
                    onChange={(e) => {
                      const [storageType, storageCode] = e.target.value.split(',');
                      setGcpDbServerStorage({ type: storageType, code: storageCode });
                      // setValue('AZURE.appServer.storageType', storageType);
                      // setValue('AZURE.appServer.storageCode', storageCode);
                    }}
                    >
                    <MenuItem value="">Select</MenuItem>
                    {gcpStorageOptions.map((option) => (
                      <MenuItem key={option.label} value={`${option.type},${option.code}`}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.GCP?.databaseServer?.storageType && (
                    <Typography variant="caption" color="error">
                      {errors.GCP?.databaseServer?.storageType?.message}
                    </Typography>
                  )}
                </FormControl>
                
                <TextField
                  label="Storage Space"
                  type="number"
                  {...register('GCP.databaseServer.storageSpace', 
                    { 
                      required: 'Storage Space is required',
                      valueAsNumber: true 
                    })}
                  error={!!errors.GCP?.databaseServer?.storageSpace}
                  helperText={errors.GCP?.databaseServer?.storageSpace?.message}
                  fullWidth
                />
                {/* <TextField
                  label="Storage Type"
                  {...register('GCP.databaseServer.storageType', { required: 'Storage Type is required' })}
                  error={!!errors.GCP?.databaseServer?.storageType}
                  helperText={errors.GCP?.databaseServer?.storageType?.message}
                  fullWidth
                /> */}
                
              </Box>
            </Box>)}
        </Paper>
        <Button type="submit" variant="contained">
          {currentTier < tiers.length - 1 ? 'Next Tier' : 'Submit'}
        </Button>
      </Box>
    </form>
  );
};

export default Step3Form;