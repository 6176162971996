import { Yup } from "hooks";
import { StorageHelper, StorageKeys } from "./storage.helper";


export const emailSchema = (requiredMsg: string, invalidMsg: string) =>
    Yup.string()
        .email(invalidMsg)
        .required(requiredMsg)
        .matches(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/, invalidMsg);

export const companyEmailSchema = (requiredMsg: string, invalidMsg: string, mustCompanyEmail: string) =>
    Yup.string()
        .email(invalidMsg)
        .required(requiredMsg)
        .test('is-company-email', mustCompanyEmail, (value: any) =>
            checkEmailDomain(value, StorageHelper.get(StorageKeys.ExcludedEmailDomains))
        );
        


function checkEmailDomain(email: string, domains: any) {

   
        if (!domains) {
            return true; // or false, based on how you want to handle this case
        }
        // rest of the code...
    
    // Construct an array of regular expressions for the domains
    if (!domains) {
        return true; // or false, based on how you want to handle this case
    }
    const domainRegexes = domains.map((domain: string) => new RegExp(`@${domain}$`, "i"));

    // Use the Array.some() method to check if the email address matches any of the domains
    return !domainRegexes.some((regex: any) => regex.test(email));
}
