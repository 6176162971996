import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { useTranslate } from "hooks";
import { StorageHelper, StorageKeys } from "utils";
interface ModalProps {
  //onHide: () => void;
  show: boolean;
  onConfirm: () => void;
}
function SessionModal({ show }: ModalProps) {
  const t = useTranslate();
  const navigate = useNavigate();
  const handleLogin = () => {
    localStorage.clear();

    StorageHelper.remove(StorageKeys.Token);
    navigate("/login");
  };
  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title> {t("pages.expiry.title")},</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p> {t("pages.expiry.subtitle")}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={handleLogin}>
        {t("pages.expiry.button")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SessionModal;
