export const allowOnlyNumbers = (e: any) => {
  const {key, target} = e;

  // if (key === ' ') {
  //   e.preventDefault();
  //   return;
  // }

  if (key === 'Backspace') {
    return;
  }

  const re = /^[0-9]*$/;
  const currentValue = target.value;
  const newValue =
    currentValue.slice(0, target.selectionStart) +
    key +
    currentValue.slice(target.selectionEnd);

  if (!re.test(newValue)) {
    e.preventDefault();
  }
};
