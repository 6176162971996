import { gql } from '@apollo/client';

export const MANUAL_INPUT = gql`
    mutation ManualInput($input: SizingInput!) {
        manualInput(input: $input) {
            id
        }
    } 
`;

export const UPLOAD_SIZING_MUTATION = gql`
  mutation UploadSizing($input: UploadSizingInput!) {
    uploadSizing(input: $input) {
      id
      containerId
    }
  }
`;

export const UPLOAD_SIZING_MUTATION_Array = gql`
  mutation UploadSizing($input: UploadSizingInput!) {
    uploadSizing(input: $input) {
      uploadData {
        containerId
        memoryRequirement
        memoryRequirementAfterOptimization
        memoryRequirementForShadowInstance
        sapsCategory
        sid
        uploadedBy
      }
      error
    }
  }
`;