import { useMutation, useQuery } from "@apollo/client";
import { faTrash, faCodeCompare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DefaultConfig, DeleteModal, InlineEdit, Loader } from "components";
import { APP_ROUTES } from "router/routes";
import ButtonLoader from "components/ui-elements/loader/buttonLoader";
import {
  DELETE_REPORT,
  UPDATE_REPORT_NAME,
  Create_Container,
} from "graphql/mutations";
import { GET_CONTAINER_DATA, GET_SERVER_CATALOGUE_LIST } from "graphql/queries";
import { useTranslate } from "hooks";
import { formatAmount } from "pages/edit-report/quotation-display";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GET_Container } from "graphql/queries";
import { Button, Pagination, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  StorageHelper,
  StorageKeys,
  ToasterHelper,
  UserHelper,
  formatTimestamp,
} from "utils";
import { Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import inputSearch from "../../assets/images/input-search.png";
import Sidebar from "components/layouts/main-layout/SideBar";

type ReportOverview = {
  createdAt: string;
  id: string;
  memoryRequirement: number;
  memoryRequirementAfterOptimization: number;
  memoryRequirementForShadowInstance: number;
  sapsCategory: string;
  sid: string;
  sizingReportFile: string;
  updatedAt: string;
  reportModifiedAt: string;
  completedAt: string;
  amount: number;
  isCompleted: boolean;
  isEdit: any;
  containerName: string;

  sids: string[];
  totalAmount: number;
  paymentPlan: string;
  platform: string;
  status: string;
  primaryRegionId: number;
};

type Props = {
  itemsPerPage?: number;
};

const SapRisePage: React.FC<Props> = ({ itemsPerPage = 10 }) => {
  const t = useTranslate();

  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");
  const [sortColumn, setSortColumn] = useState<string>("createdAt");
  const [reportToDelete, setReportToDelete] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [reportData, setReportData] = useState<ReportOverview[]>([]);
  const [editable, setEditable] = useState<boolean>(false);
  const [editingItemId, setEditingItemId] = useState<string | null>(null);
  const [showDefaultConfig, setShowDefaultConfig] = useState(false);
  const [repoData, setRepoData] = useState("");
  const [createRepo, setCreateRepo] = useState(false);
  const reportsData = UserHelper.getRegions();
  const [isLoading, setIsLoading] = useState(true);
  const { loading, data, refetch } = useQuery(GET_CONTAINER_DATA, {
    fetchPolicy: "no-cache",
  });

  const { data: catalogureList } = useQuery(GET_SERVER_CATALOGUE_LIST, {
    skip: false,
    onCompleted: async (data) => {
        // StorageHelper.set(StorageKeys.Regions, data);
        // console.log(data);
        console.log(catalogureList?.getServerCatalogueList?.data);
        setIsLoading(false);
    },
    
    onError: async error => {
        console.log(error);
    }
  });

  const {
    loading: pageloading,
    data: containerdata,
    refetch: refetchContainer,
  } = useQuery(GET_Container, {
    variables: { cid: repoData },
    fetchPolicy: "no-cache",

    skip: !createRepo,
    onCompleted: async (data) => {
      const ReportContainerData = data?.getContainerReports;
      setCreateRepo(false);
      const isCompleted = null;
      navigate(`${APP_ROUTES.EDITREPORT.replace(":id", repoData)}`, {
        state: { isCompleted, ReportContainerData },
      });
    },
  });

  const [UpdateContainerNameMutation, { loading: updateLoading }] = useMutation(
    UPDATE_REPORT_NAME,
    {
      onCompleted: async (data) => {
        const updateContainerResponse = data?.UpdateContainerName;
        if (updateContainerResponse) {
          const { success, errors } = updateContainerResponse;
          if (success) {
            ToasterHelper.success("Report name updated successfully.");
          } else {
            console.error("UpdateContainerNameMutation failed:", errors);
          }
        } else {
          console.error(
            "Invalid response format from UpdateContainerNameMutation"
          );
        }
        refetch();
      },
      onError: async (error) => {
        console.error("UpdateContainerNameMutation error:", error);
      },
    }
  );

  const [deleteReportMutation, { loading: deleteLoading }] = useMutation(
    DELETE_REPORT,
    {
      onCompleted: async ({ deleteReportGroup: { success } }) => {
        ToasterHelper.success(
          t("pages.reports.pricingReport.message.reportdel")
        );
        closeDeleteModal();
        setReportData(reportData.filter((item) => item.id !== reportToDelete));
        setReportToDelete("");
      },
      onError: async (error) => {
        console.log(error);
      },
    }
  );

  const [CreateContainerMutation, { loading: create }] = useMutation(
    Create_Container,
    {
      onCompleted: async ({ createContainer }) => {
        setRepoData(createContainer?.containerId);
        setCreateRepo(true);
        setTimeout(() => {
          refetchContainer();
        }, 1000);
      },

      onError: async (error) => {
        console.log(error);
      },
    }
  );

  const handleAddAnother = () => {
    // CreateContainerMutation();
  };

  useEffect(() => {
    if (data?.containers) {
      setReportData(
        data?.containers
          ?.map((reports: ReportOverview) => ({
            ...reports,
            isEdit: false,
          }))
          .sort((a: ReportOverview, b: ReportOverview) => {})
      );
    }
  }, [data]);

  const handleReportNameChange = (reportId: string, containerName: string) => {
    if (!containerName) {
      ToasterHelper.error(t("delete-modals.repoName") as string);
      return;
    }

    UpdateContainerNameMutation({
      variables: { input: { containerId: reportId, name: containerName } },
    });
  };

  const handleCompareClick = (reportId: string) => {
    navigate(`${APP_ROUTES.COMPARE_REPORT.replace(":id", reportId)}`, {
      state: { reportId },
    });
  };

  const filteredItems = useMemo(() => {
    let filtered = reportData.filter(
      (item) => item.status === "In Progress" || item.status === "Completed"
    );

    if (searchTerm) {
      filtered = filtered.filter((item) => {
        const sidMatches = item.status
          ?.toLowerCase()
          .includes(searchTerm?.toLowerCase());
        const plateform = item?.platform
          ?.toLowerCase()
          .includes(searchTerm?.toLowerCase());
        const fileMatches = item.containerName
          ?.toLowerCase()
          .includes(searchTerm?.toLowerCase());
        return sidMatches || plateform || fileMatches;
      });
    }

    return filtered;
  }, [searchTerm, reportData]);

  const catalogueList = useMemo(() => {
    let temp_data = catalogureList?.getServerCatalogueList?.data;
    let filtered;

    if (searchTerm) {
      filtered = temp_data.filter((item: any) => {
        return item?.serviceName.toLowerCase().includes(searchTerm.toLowerCase());
      });
    }else {
      filtered = temp_data;
    }
    return filtered;
  }, [catalogureList, searchTerm]);

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  if (loading) {
    return <Loader />;
  }

  const totalItems = catalogueList?.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = filteredItems?.slice(startIndex, endIndex);

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const openDeleteModal = (containerId: string) => {
    setReportToDelete(containerId);
    setShowDeleteModal(true);
  };

  const handleDelete = () => {
    deleteReportMutation({ variables: { containerId: reportToDelete } });
  };

  StorageHelper.remove(StorageKeys.ConfigurationDBInstanceType);
  localStorage.removeItem("PrimaryDBServerInstances");
  StorageHelper.remove(StorageKeys.ConfigurationApplicationServerInstanceType);

  currentItems.forEach((item) => {
    item?.sids?.forEach((sid) => {});
  });

  const handleDetailPage = (id: any) => {
    const detail_id = catalogueList[id]?.id;
    navigate(`${APP_ROUTES.SAP_RISE_DETAIL.replace(":id", detail_id)}`, {
      state: { detail_id, },
    });
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="col-1">
          <Sidebar />
        </div>
        <div className="container-fluid p-3 col-11 edit-report-wrapper pt-4">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <p className="fw-bolder fs-4">
              {t("pages.sap_rise.sap_rise")}
            </p>

            <div className="d-flex align-items-center justify-content-between gap-4 flex-wrap ">
              <div className="position-relative">
                <img
                  src={inputSearch}
                  alt=""
                  className="position-absolute"
                  style={{
                    left: "7px",
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                />
                <Form.Control
                  className="w-100 ps-5"
                  type="text"
                  placeholder={t("Qutation.search") as string}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>

              <div>
                <Button
                  className="prt_submit_link w-100 "
                  style={{ backgroundColor: "#0D61BB", fontWeight: "600" }}
                  onClick={handleAddAnother}
                >
                  {create ? (
                    <div
                      className={`d-flex justify-content-center align-items-center`}
                    >
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        variant="primary"
                      />
                      {t("loadTemplate.loading")}
                    </div>
                  ) : (
                    <>
                      {t("pages.sap_rise.search")}
                      <span className="d-none d-lg-block d-md-block ms-2"></span>
                    </>
                  )}
                </Button>
              </div>
            </div>
          </div>

          <div className="report-table-responsive">
            <Table
              className="w-full border-collapse border border-blue-500 max-w-xl mt-16 mx-auto"
              // responsive
            >
              <thead
                className="rounded-5"
                style={{
                  borderRadius: "10px",
                  position: "sticky",
                  top: "0",
                  zIndex: "100",
                }}
              >
                <tr className="bg-blue-500 text-white">
                  <th
                    className="rounded-start p-3 ps-4"
                    style={{
                      backgroundColor: "#0D61BB",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                   #
                  </th>

                  <th
                    className="p-3"
                    scope="col"
                    style={{
                      backgroundColor: "#0D61BB",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    {t("pages.sap_rise.tableHead.serviceName")}
                  </th>
                  <th
                    className="p-3"
                    scope="col"
                    style={{
                      backgroundColor: "#0D61BB",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    {t("pages.sap_rise.tableHead.action")}
                  </th>
                </tr>
              </thead>

              <tbody>
                {isLoading ? <Loader /> : (catalogueList?.length > 0 ? (
                  catalogueList?.map((item: any, index: number) => (
                    <tr key={item.id} className="bg-white border-b border-blue-500">
                      <td
                        className="memory-size-column ms-2 py-2 px-4 align-middle"
                        style={{ textAlign: "center" }}
                      >
                        {index + 1}
                      </td>

                      <td
                        className="text-center py-2 px-4 align-middle"
                        style={{ textAlign: "center" }}
                      >
                        {item?.serviceName}
                      </td>
                      <td
                        className="text-center py-2 px-4 align-middle"
                        style={{ textAlign: "center" }}
                      >
                         <Button className="px-4 py-2 font-medium text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:shadow-outline-blue active:bg-blue-600 transition duration-150 ease-in-out" onClick={() => handleDetailPage(index)}>{t("pages.sap_rise.show")}</Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8} style={{ textAlign: "center" }}>
                      {t("pages.notFound.norecordfound")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          {totalPages > 1 && (
            <Pagination>
              {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                (page) => (
                  <Pagination.Item
                    key={page}
                    active={page === currentPage}
                    onClick={() => handlePageChange(page)}
                  >
                    {page}
                  </Pagination.Item>
                )
              )}
            </Pagination>
          )}

          <DeleteModal
            title={t("errorMessages.Report")}
            showModal={showDeleteModal}
            closeModal={closeDeleteModal}
            handleDelete={handleDelete}
            loading={deleteLoading}
          />
        </div>
        {showDefaultConfig && <DefaultConfig />}
      </div>
    </>
  );
};

export default SapRisePage;
