import { CloudVendor, UsageType } from "entities/enums";
import { InstanceModel } from "entities/models";
import { Region } from "entities/models/report";
import { forwardRef, useState, useEffect } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { useTranslation } from "react-i18next";
import NoSSR from "react-no-ssr";

type PropTypes = {
  id: string;
  placeholder?: string;
  vendor?: CloudVendor | null;
  region?: Region | null;
  usage: UsageType;
  disabled?: boolean;
  isInvalid?: boolean;
  value?: InstanceModel | null;
  onChange?: (value?: InstanceModel | null) => void;
  options?: any;
  label?: string | null;
  toolTipText?: string;
  isloading?: boolean;
  showsort?: boolean;
  context: "dbInstance" | "cpu" | "ram";
  props?: any;
  ref?: any;
  onBlur?: () => void;

  style?: React.CSSProperties;
};

const InstanceSelect = forwardRef<any, PropTypes>(
  (
    {
      onBlur,
      id,
      usage,
      placeholder,
      vendor,
      region,
      disabled,
      isInvalid,
      value,
      onChange,
      options,
      isloading,
      label,

      context, // Include context in the destructuring
      props,
    },
    ref
  ) => {
    const { t } = useTranslation();

    const [sortingOption, setSortingOption] = useState<string | null>("Name");
    const [sortingOrder, setSortingOrder] = useState("asc");
    const [searchQuery, setSearchQuery] = useState("");
    const [displayOptions, setDisplayOptions] = useState([]);

    const handleChange = (selected: InstanceModel[] | null) => {
      onChange && onChange((selected && selected[0]) || null);
    };

    const handleSearch = (query: string) => {
      setSearchQuery(query);
    };

    const getFilteredData = () => {
      // Context-based filtering logic
      switch (context) {
        case "cpu":
          // Filter for CPU related instances
          return options?.instances.filter((d: InstanceModel) => {
            // Log the vcpu value
            return d.vcpu; // Your CPU filtering logic here
          });
        case "ram":
          // Filter for RAM related instances
          return options?.instances.filter(
            (d: InstanceModel) => d.memory /* RAM filtering logic */
          );
        case "dbInstance":
        default:
          return options?.instances || [];
      }
    };

    const filteredData = getFilteredData();

    const getSortedOptions = () => {
      return (options?.instances || [])
        .filter((d: { isCertified: boolean }) =>
          usage === UsageType.HANA_INSTANCE ? d.isCertified === true : true
        )
        .sort(
          (
            a: {
              code: string;
              isCertified: boolean;
              memory: number;
              vcpu: number;
            },
            b: {
              code: string;
              isCertified: boolean;
              memory: number;
              vcpu: number;
            }
          ) => {
            const sortOrderFactor = sortingOrder === "asc" ? 1 : -1;

            if (sortingOption === "Name") {
              // Extract letters and numbers separately
              const [, prefixA, numberA] =
                a.code.match(/(.{2})(?:.*-([0-9]+))?/) || [];
              const [, prefixB, numberB] =
                b.code.match(/(.{2})(?:.*-([0-9]+))?/) || [];

              // Compare first two characters
              const letterComparison = prefixA.localeCompare(prefixB);
              if (letterComparison !== 0) {
                return sortOrderFactor * letterComparison;
              }

              // If first two characters are the same, compare their numeric values
              const numA = numberA ? parseInt(numberA, 10) : 0;
              const numB = numberB ? parseInt(numberB, 10) : 0;

              return sortOrderFactor * (numA - numB);
            } else if (sortingOption === "Memory") {
              return sortOrderFactor * (a.memory - b.memory);
            } else if (sortingOption === "CPU") {
              return sortOrderFactor * (a.vcpu - b.vcpu);
            }
          }
        );
    };

    const renderTypeaheadLabel = () => {
      // Context-based label rendering
      switch (context) {
        case "cpu":
          return "CPU Instance";
        case "ram":
          return "RAM Instance";
        case "dbInstance":
        default:
          return label || "Instance";
      }
    };
    const getLabelKeyForContext = () => {
      switch (context) {
        case "cpu":
          return "vcpu"; // Assuming 'vcpu' is the property for CPU
        case "ram":
          return "memory"; // Assuming 'memory' is the property for RAM
        case "dbInstance":
          return "code"; // Assuming 'code' is a property for dbInstance
        default:
          return "code"; // Fallback property
      }
    };

    const preprocessOptions = (options: any) => {
      return options.map((option: any) => ({
        ...option,
        vcpu: option.vcpu ? option.vcpu.toString() : "N/A",
        memory: option.memory ? option.memory.toString() : "N/A",
        code: option.code ? option.code.toString() : "N/A",
      }));
    };

    useEffect(() => {
      // Update sortedOptions and displayOptions when options or other dependencies change
      const sortedOptions = preprocessOptions(getSortedOptions());
      if (searchQuery) {
        const filteredOptions = options?.instances?.filter((option: any) =>
          option.code.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setDisplayOptions(preprocessOptions(filteredOptions));
      } else {
        setDisplayOptions(sortedOptions);
      }
    }, [searchQuery, options?.instances, sortingOption, sortingOrder]);

    const typeahead = (
      <>
        <div className="options-container">
          <NoSSR>
            <div className="d-flex gap-1">
              <Typeahead
                ref={ref}
                className="w-100 custom-typeahead "
                id={id}
                onBlur={onBlur}
                selected={value ? [value] : []}
                onChange={handleChange as any}
                disabled={disabled}
                labelKey={getLabelKeyForContext()}
                placeholder={placeholder}
                isInvalid={isInvalid}
                isLoading={isloading}
                options={displayOptions}
                onInputChange={handleSearch}
                renderMenuItemChildren={(optionItems: any) => {
                  if (context === "dbInstance") {
                    return (
                      <div>
                        <div className="server-container">
                          <div className="server-options">
                            <div>{optionItems?.code}&nbsp;|</div>
                            <div className="text-muted">
                              CPU
                              <strong>{optionItems?.vcpu}&nbsp;|</strong>
                            </div>
                            <div>
                              RAM
                              <strong>{optionItems?.memory}</strong>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }

                  return (
                    <div className="server-container">
                      <div className="server-options">
                        {context === "cpu" ? (
                          <div className="text-muted">
                            CPU:<strong>{optionItems?.vcpu}</strong>
                          </div>
                        ) : (
                          <div className="text-muted">
                            RAM:<strong>{optionItems?.memory}</strong>
                          </div>
                        )}
                        <div>|&nbsp;{optionItems?.code}</div>
                      </div>
                    </div>
                  );
                }}
              />
            </div>
          </NoSSR>
        </div>
      </>
    );

    return typeahead;
  }
);

export default InstanceSelect;
