export enum DetailedUsageType {
  HANA_PRIMARY,
  HANA_SECONDARY,
  HANA_DR_PRIMARY,
  HANA_DR_SECONDARY,
  APP_SERVER_PRIMARY,
  APP_SERVER_SECONDARY,
  APP_SERVER_DR_PRIMARY,
  APP_SERVER_DR_SECONDARY,
  OTHER_INFRASTRUCTURE,

}

export const DetailedUsageTypeTextMapping = {

  [DetailedUsageType.HANA_PRIMARY]: 'HANA Primary',
  [DetailedUsageType.HANA_SECONDARY]: 'HANA Secondary',
  [DetailedUsageType.HANA_DR_PRIMARY]: 'HANA DR Primary',
  [DetailedUsageType.HANA_DR_SECONDARY]: 'HANA DR Secondary',
  [DetailedUsageType.APP_SERVER_PRIMARY]: 'APP Server Primary',
  [DetailedUsageType.APP_SERVER_SECONDARY]: 'APP Server Secondary',
  [DetailedUsageType.APP_SERVER_DR_PRIMARY]: 'APP Server DR Primary',
  [DetailedUsageType.APP_SERVER_DR_SECONDARY]: 'APP Server DR Secondary',
 [DetailedUsageType.OTHER_INFRASTRUCTURE]:'Other Infrastructure'
}