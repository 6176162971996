import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { TextField, Button, Box, Typography, IconButton, Paper, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, styled } from '@mui/material';
import { Step2FormData } from '../../entities/models/catalogue.model';
import { ExpandLess, ExpandMore, Delete, Add, Settings, Done } from '@mui/icons-material';
import styles from './catalogue-create.module.scss';
import Step3Form from './catalogue-step3';
import { useMutation, useQuery } from '@apollo/client';
import { CREATESERVERCATALOGUESIZE_STEP2 } from '../../graphql/mutations/server-catalogue.mutations';
import { GETCATALOGUESIZES } from '../../graphql/queries/server-catalogue';
import { ToasterHelper } from 'utils/toaster.helper';

interface Step2FormProps {
  onSubmit: (data: Step2FormData) => void;
  catalogueId: string;
  systemTiers: string[]; // Add this prop
}

export interface Step2FormRef {
  submitForm: () => void;
}

const Step2Form = forwardRef<Step2FormRef, Step2FormProps>(({ onSubmit, catalogueId, systemTiers }, ref) => {
  const { register, handleSubmit, formState: { errors }, reset, setValue, getValues, trigger, clearErrors } = useForm<Step2FormData>({
    defaultValues: { catalogueId, databaseType: '', size: '' }
  });

  const [sizes, setSizes] = useState<Step2FormData[]>([]);
  const [expanded, setExpanded] = useState<number | null>(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedSizeId, setSelectedSizeId] = useState('');
  const [selectedSize, setSelectedSize] = useState('');
  const [currentTierIndex, setCurrentTierIndex] = useState(0);
  // const [systemTiers, setSystemTiers] = useState([])
  // const tiers = ['PRD', 'QAS', 'DEV']; // Static tiers, can be replaced with a query result in the future

  useEffect(() => {
    console.log('selectedSizeId changed:', selectedSizeId);
  }, [selectedSizeId]);


  const { data: catalogueSizesData, loading: catalogueSizesLoading } = useQuery(GETCATALOGUESIZES, {
    variables: { catalogueId },
    fetchPolicy: 'network-only',
  });

  const BlueDialogTitle = styled(DialogTitle)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    textAlign: 'center',
    padding: theme.spacing(2),
  }));

  useEffect(() => {
    if (catalogueSizesData && catalogueSizesData.getCatalogueSizes) {
      console.log(catalogueSizesData.getCatalogueSizes?.serverSizes);
      setSizes(catalogueSizesData.getCatalogueSizes?.serverSizes.map((size: any) => ({
        catalogueId,
        id: size.id,
        usageTier: size.usageTier,
        databaseType: size.databaseType,
        size: size.size,
      })));

      // Only set systemTiers if it's empty to avoid losing data
      if (systemTiers.length === 0) {
        // setSystemTiers(catalogueSizesData.getCatalogueSizes?.systemTiers);
      }
    }
  }, [catalogueSizesData, catalogueId]);

  const [createServerCatalogueSize] = useMutation(CREATESERVERCATALOGUESIZE_STEP2, {
    onCompleted: (data) => {
      console.log('Server catalogue size created successfully:', data);
      ToasterHelper.success('Server catalogue size created successfully');
      const newSize = {
        id: data.createServerCatalogueSize.id,
        catalogueId,
        databaseType: getValues('databaseType'),
        size: getValues('size'),
      };
      setSizes([...sizes, newSize]);
      reset({ catalogueId, usageTier: '', databaseType: '', size: '' });
    },
    onError: (error) => {
      console.error('Error creating server catalogue size:', error);
      // ToasterHelper.error('Error creating server catalogue size');
    },
  });

  useImperativeHandle(ref, () => ({
    submitForm: handleSubmit(onSubmit)
  }));

  const toggleExpand = (index: number) => {
    setExpanded(expanded === index ? null : index);
    // Clear form and errors when collapsing or expanding
    reset({ catalogueId, usageTier: '', databaseType: '', size: '' });
    clearErrors();
  };

  const handleEditSize = (index: number, field: keyof Step2FormData, value: string) => {
    const updatedSizes = [...sizes];
    updatedSizes[index] = { ...updatedSizes[index], [field]: value };
    setSizes(updatedSizes);
  };

  const handleDeleteSize = (index: number) => {
    const updatedSizes = sizes.filter((_, i) => i !== index);
    setSizes(updatedSizes);
    if (expanded === index) {
      setExpanded(null);
    }
  };

  const handleOpenPopup = (sizeId: string, size: string) => {
    if (sizeId) {
      console.log('Opening popup with sizeId:', sizeId); 
      setSelectedSizeId(sizeId); 
      setSelectedSize(size);
      setOpenPopup(true);
    } else {
      console.error('Attempted to open popup with undefined sizeId');
    }
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
    setCurrentTierIndex(0); // Reset the tier index when closing the popup
  };

  const handleStep3Submit = (data: any) => {
    console.log('Step 3 data:', data);
    if (currentTierIndex < systemTiers.length - 1) {
      setCurrentTierIndex(currentTierIndex + 1);
    } else {
      // All tiers have been submitted
      handleClosePopup();
      // Implement any additional logic needed after all tiers are submitted
    }
  };

  const handleDoneClick = async () => {
    // Clear previous errors
    clearErrors();

    // Trigger validation for all fields
    const isValid = await trigger();
    
    if (!isValid) {
      // If validation fails, the errors will be shown on the inputs
      return;
    }

    const data = getValues();
    const existingSize = sizes.find(size => size.size === data.size);
    if (existingSize) {
      setValue('size', '', { shouldValidate: true });
      return;
    }

    try {
      const result = await createServerCatalogueSize({
        variables: {
          input: {
            catalogueId,
            databaseType: data.databaseType,
            size: data.size,
          },
        },
      });

      if (result.data) {
        const newSize = {
          catalogueId,
          databaseType: data.databaseType,
          usageTier: data.usageTier,
          size: data.size,
          id: result.data.createServerCatalogueSize.id,
        };
        setSizes([...sizes, newSize]);
        reset({ catalogueId, usageTier: '', databaseType: '', size: '' });
      }
    } catch (error) {
      console.error('Error submitting size:', error);
      ToasterHelper.error('Error creating server catalogue size');
    }
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      {catalogueSizesLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="200px">
          <CircularProgress />
        </Box>
      ) : sizes.length > 0 ? (
        sizes.map((size, index) => (
          <Paper key={index} elevation={3} sx={{ mb: 2, p: 2, position: 'relative' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6" sx={{ cursor: 'pointer' }} onClick={() => toggleExpand(index)}>
                System Size: {size.size}
              </Typography>
              <Box>
                <IconButton onClick={() => toggleExpand(index)}>
                  {expanded === index ? <ExpandLess /> : <ExpandMore />}
                </IconButton> 
                <IconButton color="primary" title="Add Tier Config" onClick={() => handleOpenPopup(size.id, size.size)}>
                  <Settings />
                </IconButton>
                <IconButton onClick={() => handleDeleteSize(index)}>
                  <Delete />
                </IconButton>
              </Box>
            </Box>
            {expanded === index && (
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, mt: 2 }}>
                <TextField
                  label="Usage Tier"
                  value={size.usageTier}
                  onChange={(e) => handleEditSize(index, 'usageTier', e.target.value)}
                  fullWidth
                />
                <TextField
                  label="Database Type"
                  value={size.databaseType}
                  onChange={(e) => handleEditSize(index, 'databaseType', e.target.value)}
                  fullWidth
                />
                <TextField
                  label="System Size"
                  value={size.size}
                  onChange={(e) => handleEditSize(index, 'size', e.target.value)}
                  fullWidth
                />
              </Box>
            )}
          </Paper>
        ))
      ) : null}
      <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
          <TextField
            label="Usage Tier"
            placeholder="e.g (Up to 135 FUE)"
            {...register('usageTier')} 
            error={!!errors.usageTier}
            helperText={errors.usageTier?.message}
            InputProps={{
              autoComplete: 'off',
            }}
            fullWidth
          />
          <TextField
            label="Database Type"
            placeholder="e.g HANA"
            {...register('databaseType', { required: 'Database Type is required' })}
            error={!!errors.databaseType}
            helperText={errors.databaseType?.message}
            InputProps={{
              autoComplete: 'off',
            }}
            fullWidth
          />
          <TextField
            label="System Size"
            placeholder="e.g XS, S, M"
            {...register('size', { 
              required: 'Size is required',
              validate: value => !sizes.find(size => size.size === value) || 'System Size already exists'
            })}
            error={!!errors.size}
            helperText={errors.size?.message}
            inputProps={{
              autoComplete: 'off',
            }}
            fullWidth
          />
          <IconButton onClick={handleDoneClick}>
            <Done />
          </IconButton>
        </Box>
      </Paper>
      <Dialog 
        open={openPopup} 
        onClose={handleClosePopup} 
        maxWidth="md" 
        fullWidth
        scroll="paper"
      >
        <BlueDialogTitle>
          DETAIL CONFIGURATION FOR: "{selectedSize}" - {systemTiers[currentTierIndex]}
        </BlueDialogTitle>
        <DialogContent dividers>
          <Step3Form 
            onSubmit={handleStep3Submit} 
            catalogueId={catalogueId} 
            catalogueSizeId={selectedSizeId} 
            systemTiers={systemTiers}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePopup}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </form>
  );
});

export default Step2Form;