import { gql } from '@apollo/client';

export const DELETE_REPORT = gql`
  mutation DeleteReportGroup($containerId: String!) {
    deleteReportGroup(containerId: $containerId) {
      success
    }
  }
`;
export const UPDATE_REPORT_NAME = gql`
  mutation UpdateContainerName($input: UpdateContainerNameInput) {
    updateContainerName(input: $input) {
      success
    }
  }
`;

export const UPDATE_SIZING_REPORT_AMOUNT = gql`
mutation UpdateSizingReportAmount($input: SaveReportInput!) {
  updateSizingReportAmount(input: $input)
}`


export const ADD_SIZING_CONFIGURATION_STEP_ONE= gql`
mutation AddSizingReportConfigurationStepOne($sizingReportConfiguration: SizingReportConfigurationStepOneInput) {
  addSizingReportConfigurationStepOne(sizingReportConfiguration: $sizingReportConfiguration) {
    id
    success
  }
}
`

export const CREATE_BULK_REPORT=gql`
mutation CreateBulkReports($input: [SizingInput!]!) {
  createBulkReports(input: $input)
}
`
export const UPDATE_BULK_REPORT=gql`
mutation UpdateBulkReports($input: [UpdateSizingInput!]!) {
  updateBulkReports(input: $input)
}`

export const ADD_SIZING_CONFIGURATION_STEP_TWO = gql`
mutation AddSizingReportConfigurationStepTwo($sizingReportConfiguration: [SizingReportConfigurationStepTwoInput]) {
  addSizingReportConfigurationStepTwo(sizingReportConfiguration: $sizingReportConfiguration) {
    id
    success
  }
}
`

export const ADD_SIZING_CONFIGURATION_STEP_THREE = gql`
mutation AddSizingReportConfigurationStepThree($sizingReportConfiguration: [SizingReportConfigurationStepThreeInputNew]) {
  addSizingReportConfigurationStepThree(sizingReportConfiguration: $sizingReportConfiguration) {
    id
    success
  }
}
`

export const ADD_SIZING_CONFIGURATION_STEP_FOUR = gql`
mutation AddSizingReportConfigurationStepFour($sizingReportConfiguration: SizingReportConfigurationStepFourInput) {
  addSizingReportConfigurationStepFour(sizingReportConfiguration: $sizingReportConfiguration) {
    id
    success
  }
}
`
export const CLONE_SIZING_REPORT = gql`
mutation UpdateContainerName($input: UpdateContainerNameInput) {
  updateContainerName(input: $input) {
    success
    containerId
  }
}
`
export const COPY_STEP1=gql`
 mutation CopyStep1ReportConfiguration($sizingReportId: String, $newSizingReportId: String) {
  copyStep1ReportConfiguration(sizingReportId: $sizingReportId, newSizingReportId: $newSizingReportId) {
    id
    success
  }
}
`
export const COPY_DB=gql`
mutation CopySizingReport($sizingReportId: String, $sid: String) {
  copySizingReport(sizingReportId: $sizingReportId, sid: $sid) {
    id
    success
  }
}`
export const DELETE_DBSERVER_INSTANCE = gql`
  mutation Delete($sizingReportId: String!) {
    delete(sizingReportId: $sizingReportId) {
      success
    }
  }
`;

export const Create_Container=gql`
mutation CreateContainer {
  createContainer {
    containerId
  }
}
`
export const DEFAULT_CONFIGURATION=gql`
mutation AddContainerConfiguration($containerSettingsInput: addContainerSettingsInput) {
  addContainerConfiguration(containerSettingsInput: $containerSettingsInput) {
    success
    primaryRegionId
    backupRegionId
    appOperatingSystem
    isLoadTemplate
    dbOperatingSystem
    additionalInfrastructure {
      id
      cpu
      serverInstance
      storageCode
      storageSpace
      storageType
      operatingSystem
      ram
      count
    }
    landscapes {
      id
      tierName
      SizingReports {
        id
        sid
    
        DbandAppsConfig {
          AppServerConfigurations {
            ramDR
            storageSpaceDR
            storageSpace
            ram
            cpuDR
            cpu
            storageTypeDR
            storageType
            storageCodeDR
            storageCode
            appDRServerCount
            appServerCount
            enableDR
            appDROperatingSystem
            appOperatingSystem
            backupAppServerInstance
            primaryAppServerInstance
            id
          }
          dbHaCountDR
          storageSpace
          backupDBServerInstance
          cpu
          dbHaCount
          dbOperatingSystem
          enableDR
          primaryDBServerInstance
          id
          storageCode
          storageType
          ram
        }
      }
    }
  }
}`
export const DELETE_SID=gql`
mutation Delete($sizingReportId: String!) {
  delete(sizingReportId: $sizingReportId) {
    success
  }
}`

export const DELETE_APP=gql`
mutation DeleteAppServer($deleteAppServerId: String!) {
  deleteAppServer(id: $deleteAppServerId)
}
`

export const ADD_DETAIL_CONFIG=gql`
mutation AddDetailConfiguration($detailInput: [DetailConfigInput]) {
  addDetailConfiguration(detailInput: $detailInput)
}`

export const DETAIL_INPUT=gql`
mutation AddDetailConfiguration($detailInput: [DetailConfigInput]) {
  addDetailConfiguration(detailInput: $detailInput)
}`

export const COPY_SID=gql`
mutation CopySid($reportId: String!, $sid: String!) {
  copySid(reportId: $reportId, sid: $sid) {
    id
  }
}`

export const ADD_SID=gql`
mutation AddNewSid($input: SizingInput!) {
  addNewSid(input: $input) {
    id
    needRecommendation
  }
}`
export const FIX_RIGION=gql`
mutation FixRegion($containerId: String!) {
  fixRegion(containerId: $containerId)
}
`

export const DELETE_TIER=gql`
mutation DeleteTier($tierId: Int!, $containerId: String!) {
  deleteTier(tierId: $tierId, containerId: $containerId) {
    success
  }
}`

export const UPDATED_STEP=gql`
mutation UpdateStepCount($step: Int!, $containerId: String!) {
  updateStepCount(step: $step, containerId: $containerId) {
    success
  }
}`

export const ADD_TIER=gql`
mutation CreateCustomTier($tierName: String!) {
  createCustomTier(tierName: $tierName)
}`

export const STEP4_FILTRATION=gql`
mutation AddStepFourFilters($filtersSettings: FiltersStepFourInput) {
  addStepFourFilters(filtersSettings: $filtersSettings) {

    containerConfig {
      containerName
      vendor
      includeDR
      primaryRegionId
      backupRegionId
      appOsLicenseModel
      dbOsLicenseModel
      supportedDrSites
      step
      dbOperatingSystem
      appOperatingSystem
      
      awsConfiguration {
        tenancy
        paymentOption
        reservationTerm
        purchaseOption
      }
      azureConfiguration {
        tier
        purchaseOption
        paymentOption
      }
      gcpConfiguration {
        committedUsage
        paymentOption
      }
    }
    additionalInfrastructure {
      containerId
      id
      cpu
      serverInstance
      storageCode
      storageSpace
      storageType
      operatingSystem
      ram
      count
    }
    landscapes {
      id
      tierName
      SizingReports {
        id
        sid
        memoryRequirement
        DbandAppsConfig {
          id
          primaryDBServerInstance
          backupDBServerInstance
          dbOperatingSystem
          dbHaCount
          enableDR
          storageSpace
          storageCode
          storageType
          cpu
          ram
          dbHaCountDR
          AppServerConfigurations {
            primaryAppServerInstance
            id
            backupAppServerInstance
            appOperatingSystem
            appDROperatingSystem
            enableDR
            appServerCount
            appDRServerCount
            storageCode
            storageCodeDR
            storageType
            storageTypeDR
            cpu
            cpuDR
            ram
            ramDR
            storageSpace
            storageSpaceDR
          }
        }
      }
    }
  }
}`

// export const ADD_OTHER_INFRASTRUCTURE_MUTATION =gql`
// mutation AddOtherInfrastructure($input: OtherConfigInput) {
//   addOtherInfrastructure(input: $input)
// }
// `

export const ADD_OTHER_INFRASTRUCTURE_MUTATION =gql`
mutation AddOtherInfrastructure($input: OtherConfigInput) {
  addOtherInfrastructure(input: $input){
    id
    containerId
    serverInstance
    operatingSystem
    storageType
    storageCode
    storageSpace
    cpu
    ram
    count
  }
}
`
export const DELETE_INFRASTRUCTURE_MUTATION =gql`
mutation DeleteAdditionalInfrastructure($aid: String) {
  deleteAdditionalInfrastructure(aid: $aid) {
    success
  }
}
`
export const Load_Template =gql`
mutation LoadTemplate($input: LoadTemplateInput!) {
	loadTemplate(input: $input)
  }
`
export const UPDATE_SID = gql`
mutation UpdateSid($input: UpdateSidInput!) {
  updateSid(input: $input)
}
`
export const DELETE_CONTAINER_DATA = gql`
  mutation DeleteContainerData($containerId: String!, $reset: Boolean!) {
    deleteContainerData(containerId: $containerId, reset: $reset) {
      success
    }
  }
`