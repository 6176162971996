import {
  ProvidersData,
  ProviderGroupedInstances,
} from "../pages/compare-report-page/compare-report";

export const osMapping = {
  Linux: [
    "Linux",
    "Linux with SQL Ent",
    "Linux with SQL Std",
    "Linux with SQL Web",
    "Ubuntu Pro",
    "CentOS",
    "Ubuntu",
    "Ubuntu Advantage",
    "CoreOS",
    "Debian",
  ],
  RHEL: [
    "RHEL",
    "RHEL with SQL Ent",
    "RHEL with SQL Std",
    "RHEL with SQL Web",
    "RHEL for SAP Business Applications",
    "Red Hat Enterprise Linux",
  ],
  "Red Hat Enterprise Linux with HA": [
    "Red Hat Enterprise Linux with HA",
    "Red Hat Enterprise Linux with HA with SQL Ent",
    "Red Hat Enterprise Linux with HA with SQL Std",
    "Red Hat Enterprise Linux for SAP with HA",
    "Red Hat Enterprise Linux for SAP with High Availability and Update Services",
  ],
  "SUSE Linux Enterprise Server": [
    "SUSE",
    "SUSE Linux Enterprise Server Standard",
    "SUSE for SAP Linux Enterprise Server",
    "SLES 12 for SAP",
    "SLES 15 for SAP",
    "SUSE Linux Enterprise Server",
  ],
  Windows: [
    "Windows",
    "Windows with SQL Ent",
    "Windows with SQL Std",
    "Windows with SQL Web",
    "Windows Server 2008",
    "Windows Server 2012",
    "Windows Server 2016",
    "Windows Server 2019",
    "Windows Server 2022",
  ],
};

export function mapOS(os: string) {
  for (const [key, values] of Object.entries(osMapping)) {
    if (values.includes(os)) {
      return key;
    }
  }
  throw new Error(
    `OS mapping failed: No mapping found for the provided OS "${os}"`
  );
}

export function updateGlobalIndex(data: ProvidersData) {
  let globalIndex = 0;

  function assignIndexes(obj: ProvidersData) {
    const updatedObj: ProvidersData = {};

    // Iterate through each key in the object
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const providerData = obj[key as keyof ProvidersData];
        if (typeof providerData === "object" && providerData !== null) {
          const updatedProviderData: ProviderGroupedInstances = {};

          // Iterate through each sub-key
          for (const subKey in providerData) {
            if (providerData.hasOwnProperty(subKey)) {
              updatedProviderData[subKey] = providerData[subKey].map(
                (item) => ({
                  ...item,
                  index: globalIndex++,
                })
              );
            }
          }

          updatedObj[key as keyof ProvidersData] = updatedProviderData;
        } else {
          updatedObj[key as keyof ProvidersData] = providerData;
        }
      }
    }

    return updatedObj;
  }

  return assignIndexes(data);
}

export function removeTypename<T extends Record<string, any>>(obj: T): T {
  if (obj !== null && typeof obj === "object") {
    if ("__typename" in obj) {
      delete obj["__typename"];
    }
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        obj[key] = removeTypename(obj[key]);
      }
    }
  }
  return obj;
}

export function deepClone<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}
