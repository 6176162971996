export const formatTimestamp = (timestamp: number, format = 'yyyy-MM-dd hh:mm:ss a'): string => {
    const date = new Date(timestamp);
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    let hours = date.getHours();
    const period = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const formatted = format
      .replace('yyyy', year)
      .replace('MM', month)
      .replace('dd', day)
      .replace('hh', hours.toString().padStart(2, '0'))
      .replace('mm', minutes)
      .replace('ss', seconds)
      .replace('a', period);
    return formatted;
  };
  